import { HttpService } from 'services/http';


export const getAccountIdDetails = (accountId: number) => HttpService.get(`/v3/accounts/${accountId}`);

export const createNewAccountApi = (requestPayload: object) => HttpService.post('/v3/accounts', requestPayload);

export const getAccountsAPI = (queryParams: object) => HttpService.get('/v3/accounts', queryParams);

export const deleteAccountId = (accountId: number) => HttpService.delete(`/v3/accounts/${accountId}`);

export const updateExistingAccountApi = ({ accountId, ...requestPayload }) => HttpService.put(`/v3/accounts/${accountId}`, requestPayload);
