import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';


import { fetchProductsListSuccess, fetchProductsListFailure } from './actions';
import { FETCH_PRODUCTS_LIST } from './actions.types';


export const onFetchProductsList: Epic = (action$, state$, { clients }) => action$.pipe(
    ofType(FETCH_PRODUCTS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(clients.getProductsList(queryParams))
        .pipe(
            switchMap((response) => of(fetchProductsListSuccess({
                items: response.data,
                totalCount: response?.data?.length,
                locationPathname,
            }))),
            catchError(() => of(fetchProductsListFailure({ locationPathname }))),
        )),
);


export default [
    onFetchProductsList,
];

