import { createAction } from '@reduxjs/toolkit';

import { ShowToastPayload } from 'models/app/toast';

import {
    APPLICATION_READY_AUTHORISED_USER, APPLICATION_READY_AUTHORISED_USER_ONBOARDING,
    APPLICATION_READY_UNAUTHORISED_USER,
    CLEAR_RAED_ONLY_MODE,
    GET_JWT_TOKEN_FORM_STORAGE,
    HIDE_AUTO_LOGOUT_BANNER,
    INIT_APPLICATION,
    REMOVE_JWT_TOKEN_FROM_STORAGE,
    SAVE_FILE,
    SET_CLIPBOARD_CONTENT,
    SET_QUERY_PARAMS,
    SET_RAED_ONLY_MODE,
    SET_SESSION_UUID,
    SHOW_AUTO_LOGOUT_BANNER,
    SHOW_TOAST,
} from './action.types';


export const initApplication = createAction(INIT_APPLICATION);

export const applicationReadyUnauthorisedUser = createAction(APPLICATION_READY_UNAUTHORISED_USER);
export const applicationReadyAuthorisedUserOnboarding = createAction(APPLICATION_READY_AUTHORISED_USER_ONBOARDING);
export const applicationReadyAuthorisedUser = createAction(APPLICATION_READY_AUTHORISED_USER);

export const setSessionUuid = createAction(SET_SESSION_UUID, (sessionUuid: string) => {
    return { payload: sessionUuid };
});
export const getJwtTokenFormStorage = createAction(GET_JWT_TOKEN_FORM_STORAGE, (originalSessionUuid?: string) => {
    return { payload: originalSessionUuid };
});
export const removeJwtTokenFromStorage = createAction(REMOVE_JWT_TOKEN_FROM_STORAGE);

export const showAutoLogoutBanner = createAction(SHOW_AUTO_LOGOUT_BANNER);
export const hideAutoLogoutBanner = createAction(HIDE_AUTO_LOGOUT_BANNER);

export const setClipboardContent = createAction(SET_CLIPBOARD_CONTENT, (text) => {
    return { payload: text };
});


export const setReadOnlyMode = createAction(SET_RAED_ONLY_MODE, (readOnlyInfo) => {
    return { payload: readOnlyInfo };
});

export const clearReadOnlyMode = createAction(CLEAR_RAED_ONLY_MODE);


export const showToast = createAction(SHOW_TOAST, ({ type, message, description, options, id }: ShowToastPayload) => {
    return {
        payload: {
            type,
            message,
            description,
            options,
            id,
        },
    };
});


export const setQueryParams = createAction(SET_QUERY_PARAMS, ({ reducerName, fieldName, queryParams, locationPathname }) => {
    return {
        payload: {
            reducerName,
            fieldName,
            queryParams,
            locationPathname,
        },
    };
});

export const saveFile = createAction(SAVE_FILE, ({ blob, fileName, mimeType }) => ({ payload: { blob, fileName, mimeType } }));

export default {
    initApplication,
    applicationReadyUnauthorisedUser,
    applicationReadyAuthorisedUser,
    setSessionUuid,
    getJwtTokenFormStorage,
    removeJwtTokenFromStorage,
    showAutoLogoutBanner,
    hideAutoLogoutBanner,
    setClipboardContent,
    setReadOnlyMode,
    clearReadOnlyMode,
    setQueryParams,
    showToast,
    saveFile,
};
