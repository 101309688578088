import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    ADD_BUSINESS_USER,
    ADD_BUSINESS_USER_FAILURE,
    ADD_BUSINESS_USER_SUCCESS, CLEAR_BUSINESS_USERS_LIST,
    FETCH_BUSINESS_USERS_LIST,
    FETCH_BUSINESS_USERS_LIST_FAILURE,
    FETCH_BUSINESS_USERS_LIST_SUCCESS,
} from './action.types';


export const businessUsersReducerName = 'businessUsers';
export const businessUsersListsPerLocationStoreKeyName = 'businessUsersListsPerLocation';


export const defaultBusinessUsersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'createdAt',
    sortOrder: SortDirections.ASCEND,
};

export const businessUsersInitialListState = {
    [businessUsersListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddBusinessUser: false,
};


const createBusinessUsersBuilderCases = (builder) =>
    builder
        .addCase(ADD_BUSINESS_USER, (state) => {
            state.isLoadingAddBusinessUser = true;
        })
        .addCase(ADD_BUSINESS_USER_SUCCESS, (state) => {
            state.isLoadingAddBusinessUser = false;
        })
        .addCase(ADD_BUSINESS_USER_FAILURE, (state) => {
            state.isLoadingAddBusinessUser = false;
        })
        .addCase(FETCH_BUSINESS_USERS_LIST, (state, action) => {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardLists(
                {
                    listsState: state[businessUsersListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_BUSINESS_USERS_LIST_SUCCESS, (state, action) => {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(
                {
                    listsState: state[businessUsersListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_BUSINESS_USERS_LIST_FAILURE, (state, action) => {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[businessUsersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_BUSINESS_USERS_LIST, (state, action) => {
            state[businessUsersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[businessUsersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === businessUsersReducerName
                  && action.payload?.fieldName === businessUsersListsPerLocationStoreKeyName
            ) {
                state[businessUsersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[businessUsersListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => businessUsersInitialListState);

export default createReducer(businessUsersInitialListState, createBusinessUsersBuilderCases);
