import { Amount, CurrencyIsoCode, ExchangeRate, Uuid, AccountListItem } from '@manigo/manigo-domain-typings';


export const UPDATE_SELL_AMOUNT = 'UPDATE_SELL_AMOUNT';
export const UPDATE_MAX_SELL_AMOUNT = 'UPDATE_MAX_SELL_AMOUNT';
export const UPDATE_BUY_AMOUNT = 'UPDATE_BUY_AMOUNT';

export const INIT_EXCHANGE = 'INIT_EXCHANGE';
export const INIT_EXCHANGE_SUCCESS = 'INIT_EXCHANGE_SUCCESS';
export const INIT_EXCHANGE_FAILURE = 'INIT_EXCHANGE_FAILURE';
export const CLEAR_INIT_EXCHANGE_INFO = 'CLEAR_INIT_EXCHANGE_INFO';

export const CONFIRM_EXCHANGE = 'CONFIRM_EXCHANGE';
export const CONFIRM_EXCHANGE_SUCCESS = 'CONFIRM_EXCHANGE_SUCCESS';
export const CONFIRM_EXCHANGE_FAILURE = 'CONFIRM_EXCHANGE_FAILURE';

export const REJECT_EXCHANGE = 'REJECT_EXCHANGE';
export const REJECT_EXCHANGE_SUCCESS = 'REJECT_EXCHANGE_SUCCESS';
export const REJECT_EXCHANGE_FAILURE = 'REJECT_EXCHANGE_FAILURE';

export const FETCH_EXCHANGE_RATE = 'FETCH_EXCHANGE_RATE';
export const FETCH_EXCHANGE_RATE_SUCCESS = 'FETCH_EXCHANGE_RATE_SUCCESS';
export const FETCH_EXCHANGE_RATE_FAILURE = 'FETCH_EXCHANGE_RATE_FAILURE';

export const CLEAR_EXCHANGE_DATA = 'CLEAR_EXCHANGE_DATA';

export const COUNTDOWN_TIME = 20000;
export const INIT_EXCHANGE_COUNTDOWN_TIME = 900000;

export interface UpdateSellAmountPayload {
    queryParams: {
        sellAmount?: Amount;
    }
}

export interface UpdateMaxSellAmountPayload {
    queryParams: {
        maxSellAmount?: Amount;
    }
}

export interface UpdateBuyAmountPayload {
    queryParams: {
        buyAmount: Amount;
    }
}

export interface InitExchangeRequestPayload {
    requestPayload: {
        sendAmount: Amount;
        fromAccount: AccountListItem['id'];
        toAccount: AccountListItem['id'];
    },
}

export interface InitExchangeResponsePayload {
    responsePayload: {
        extId: Uuid,
        fromCurrency: CurrencyIsoCode,
        fromAmount: Amount,
        toCurrency: CurrencyIsoCode,
        toAmount: Amount,
        exchangeRate: ExchangeRate,
        feeCurrency: CurrencyIsoCode,
        feeAmount: Amount,
        expiresAt: string,
    }
}

export interface ConfirmExchangeRequestPayload {
    extId: Uuid;
}

export interface RejectExchangeRequestPayload {
    extId: Uuid;
}

export interface FetchExchangeRateRequestPayload {
    queryParams: {
        sourceCurrency: CurrencyIsoCode;
        targetCurrency: CurrencyIsoCode;
    }
}

export interface FetchExchangeRateResponsePayload {
    payload: {
        exchangeRates: ExchangeRateObject[];
    }
}

export interface ExchangeRateObject {
    sendCurrency: CurrencyIsoCode;
    receiveCurrency: CurrencyIsoCode;
    rate: ExchangeRate;
}
