import { NumericId, Timestamp, ScaChallengeMethod, ScaChallengeResponse, ScaChallengeType } from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import { CLEAR_SCA_CHALLENGE, CREATE_SCA_CHALLENGE, CREATE_SCA_CHALLENGE_FAILURE, CREATE_SCA_CHALLENGE_SUCCESS, DELETE_SCA_CHALLENGE } from './actions.types';
import { parseEncryptedChallengeData } from './reducer.helpers';


export const scaChallengeReducerName = 'scaChallenge';

interface ScaChallengeReducerState {
    isLoadingCreateScaChallenge: boolean,
    sourceRequestData?: any,

    //  FE origin
    type?: ScaChallengeType,
    method?: ScaChallengeMethod,
    targetId?: NumericId,
    sourceId?: NumericId,

    // BE response
    challenge?: ScaChallengeResponse['challenge']
    signature?: ScaChallengeResponse['signature'],

    // Decoded BE data
    salt?: string,
    timestamp?: Timestamp,
}


const initialState: ScaChallengeReducerState = {
    isLoadingCreateScaChallenge: false,
    sourceRequestData: undefined,

    // FE origin
    type: undefined,
    method: undefined,
    targetId: undefined,
    sourceId: undefined,

    // BE response
    challenge: undefined,
    signature: undefined,

    // Decoded BE data
    salt: undefined,
    timestamp: undefined,
};

const createScaChallengeBuilderCases = (builder) => {
    builder
        .addCase(CREATE_SCA_CHALLENGE, (state) => {
            state.isLoadingCreateScaChallenge = true;
        })
        .addCase(CREATE_SCA_CHALLENGE_SUCCESS, (state, action) => {
            return {
                ...state,
                ...action.payload,
                ...parseEncryptedChallengeData(action.payload?.signature),
                isLoadingCreateScaChallenge: false,
            };
        })
        .addCase(CREATE_SCA_CHALLENGE_FAILURE, () => {
            return initialState;
        })
        .addCase(DELETE_SCA_CHALLENGE, () => {
            return initialState;
        })
        .addCase(CLEAR_SCA_CHALLENGE, () => {
            return initialState;
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        });
};


export default createReducer(initialState, createScaChallengeBuilderCases);
