import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

/**
 * @description Generate a reducer that will set a loading state to true when an action is dispatched and false when
 */
export const generateLoadingReducer = (actionTypes: string[], stateName: string) => {
    return actionTypes.reduce((decoratedActions, actionType) => {
        decoratedActions[actionType] = (state) => {
            if (actionType.includes('SUCCESS') || actionType.includes('FAILURE')) {
                state[stateName] = false;
            } else {
                state[stateName] = true;
            }

            return state;
        };

        return decoratedActions;
    }, {});
};

export const addLoadingState = (actionTypes: string[], stateName: string, builder: ActionReducerMapBuilder<any>) => {
    actionTypes.forEach((actionType) => {
        builder.addCase(actionType, (state) => {
            state[stateName] = !(actionType.includes('SUCCESS') || actionType.includes('FAILURE'));
        });
    });
};


export default { generateLoadingReducer, addLoadingState };
