import { createReducer } from '@reduxjs/toolkit';

import { addLoadingState } from 'utils/redux-tools';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'store/standardEntityDetails.helpers';

import {
    CLEAR_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS_FAILURE,
    FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    UPDATE_BUSINESS_USER_STATUS,
    UPDATE_BUSINESS_USER_STATUS_FAILURE,
    UPDATE_BUSINESS_USER_STATUS_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
} from './action.types';


export const businessUserReducerName = 'businessUser';
export const businessUserDetailsPerLocationStoreKeyName = ' businessUserDetailsPerLocation';


const initialDetailsState = {
    [businessUserDetailsPerLocationStoreKeyName]: {}, // X XX 'pathname': {...standard }
    isLoadingUpdateUser: false,
    isLoadingStatusUpdate: false,
};


const initialState = { ...initialDetailsState };

const createBuinessUserBuilderCases = (builder) => {
    builder
        .addCase(FETCH_BUSINESS_USER_DETAILS, (state, action) => {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_BUSINESS_USER_DETAILS_SUCCESS, (state, action) => {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_BUSINESS_USER_DETAILS_FAILURE, (state, action) => {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_BUSINESS_USER_DETAILS, (state, action) => {
            state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[businessUserDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === businessUserReducerName
                && action.payload?.fieldName === businessUserDetailsPerLocationStoreKeyName
            ) {
                state[businessUserDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[businessUserDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return { ...initialState };
        });

    addLoadingState([UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE], 'isLoadingUpdateUser', builder);
    addLoadingState([UPDATE_BUSINESS_USER_STATUS, UPDATE_BUSINESS_USER_STATUS_SUCCESS, UPDATE_BUSINESS_USER_STATUS_FAILURE], 'isLoadingStatusUpdate', builder);
};


export default createReducer(initialState, createBuinessUserBuilderCases);
