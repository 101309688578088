import { SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_CARD_SENSITIVE_DATA, FETCH_CARD_SENSITIVE_DATA_SUCCESS,
    RESET_DATA,
    FetchCardSensitiveDataPayload, FETCH_CARD_SENSITIVE_DATA_FAILURE,
} from './actions.types';


export const fetchCardSensitiveData = createAction(
    FETCH_CARD_SENSITIVE_DATA,
    (requestPayload: SubmitScaChallengeActionPayload<FetchCardSensitiveDataPayload>) => ({ payload: requestPayload }),
);
export const fetchCardSensitiveDataSuccess = createAction(FETCH_CARD_SENSITIVE_DATA_SUCCESS, (responsePayload) => ({ payload: responsePayload }));
export const fetchCardSensitiveDataFailure = createAction(FETCH_CARD_SENSITIVE_DATA_FAILURE);

export const resetData = createAction(RESET_DATA);
