import React, { useLayoutEffect, useState } from 'react';
import { Router } from 'react-router-dom';

/* XXX
in src/index.jsx it HistoryRouter should be used and connected to const history = createBrowserHistory();`
but ATM 'unstable_HistoryRouter'
*/

function CustomRouter({ history, ...props }) {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            {...props}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
}

export default CustomRouter;
