import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';

import { addCompanyIdToQueryParams } from 'store/common.helpers';

import { fetchTransactionsListFailure, fetchTransactionsListSuccess } from './actions';
import { FETCH_TRANSACTIONS_LIST } from './actions.types';


export const onFetchTransactionsList: Epic = (action$, state$, { transactions }) => action$.pipe(
    ofType(FETCH_TRANSACTIONS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(transactions.getAllTransactions(addCompanyIdToQueryParams({ state$, queryParams })))
        .pipe(
            switchMap((response) => of(fetchTransactionsListSuccess({
                items: response.data.rows,
                totalCount: response?.data.count,
                locationPathname,
            }))),
            catchError(() => of(fetchTransactionsListFailure(locationPathname))),
        )),
);


export default [
    onFetchTransactionsList,
];

