import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_PRODUCTS_LIST,
    FETCH_PRODUCTS_LIST, FETCH_PRODUCTS_LIST_FAILURE, FETCH_PRODUCTS_LIST_SUCCESS,
} from './actions.types';


export const fetchProductsList = createAction(FETCH_PRODUCTS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchProductsListSuccess = createAction(
    FETCH_PRODUCTS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchProductsListFailure = createAction(FETCH_PRODUCTS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearProductsListList = createAction(CLEAR_PRODUCTS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
