import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_SUPPORTED_CURRENCIES,
    FETCH_SUPPORTED_CURRENCIES,
    FETCH_SUPPORTED_CURRENCIES_FAILURE,
    FETCH_SUPPORTED_CURRENCIES_SUCCESS,
} from './actions.types';


export const fetchSupportedCurrencies = createAction(FETCH_SUPPORTED_CURRENCIES, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchSupportedCurrenciesSuccess = createAction(FETCH_SUPPORTED_CURRENCIES_SUCCESS, ({ items, totalCount, locationPathname }) => ({
    payload: {
        items,
        totalCount,
        locationPathname,
    },
}));
export const fetchSupportedCurrenciesFailure = createAction(FETCH_SUPPORTED_CURRENCIES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
export const clearSupportedCurrencies = createAction(CLEAR_SUPPORTED_CURRENCIES, (locationPathname) => ({ payload: { locationPathname } }));
