import styled from 'styled-components';


const MaintenancePlaceholder = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 50px;
  display: grid;
  grid-template-columns: 50% minmax(0, 1fr);
  grid-column-gap: 30px;
  align-items: center;
  justify-items: center;
`;

const MaintenanceGears = styled.img`
  height: auto;
  width: 70%;
`;

const MaintenanceCopyWrapper = styled.div`
  max-width: 500px;
  
  h1 {
    font-size: 1.2rem;  
  }

  h2 {
    font-size: 2rem;
  }
`;


export default {
    MaintenancePlaceholder,
    MaintenanceGears,
    MaintenanceCopyWrapper,
};
