export enum ToastType {
    error = 'error',
    success = 'success',
    warning = 'warning',
    info = 'info',
}

export enum ToastPosition {
    top = 'top',
    topRight = 'top-right',
    topLeft = 'top-left',
    bottom = 'bottom',
    bottomRight = 'bottom-right',
    bottomLeft = 'bottom-left',
}

export enum ToastVariant {
    solid = 'solid',
    subtle = 'subtle',
    leftAccent = 'left-accent',
    topAccent = 'top-accent',
}

export interface ShowToastPayload {
    type: ToastType,
    message: string,
    description?: string,
    options?: any,
    id?: string,
}
