import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_BENEFICIARY_DETAILS,
    DELETE_BENEFICIARY,
    DELETE_BENEFICIARY_FAILURE,
    DELETE_BENEFICIARY_SUCCESS,
    FETCH_BENEFICIARY_DETAILS,
    FETCH_BENEFICIARY_DETAILS_FAILURE,
    FETCH_BENEFICIARY_DETAILS_SUCCESS,
    UPDATE_BENEFICIARY,
    UPDATE_BENEFICIARY_FAILURE,
    UPDATE_BENEFICIARY_SUCCESS,
    DeleteBeneficiaryPayload,
} from './actions.types';


export const fetchBeneficiaryDetails = createAction(FETCH_BENEFICIARY_DETAILS,
    ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchBeneficiaryDetailsSuccess = createAction(FETCH_BENEFICIARY_DETAILS_SUCCESS,
    ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const fetchBeneficiaryDetailsFailure = createAction(FETCH_BENEFICIARY_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearBeneficiaryDetails = createAction(CLEAR_BENEFICIARY_DETAILS, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const deleteBeneficiary = createAction(DELETE_BENEFICIARY,
    ({ requestPayload }:DeleteBeneficiaryPayload) => ({ payload: { requestPayload } }));
export const deleteBeneficiarySuccess = createAction(DELETE_BENEFICIARY_SUCCESS);
export const deleteBeneficiaryFailure = createAction(DELETE_BENEFICIARY_FAILURE);


export const updateBeneficiary = createAction(UPDATE_BENEFICIARY, ({
    beneficiaryUuid,
    requestPayload,
    locationPathname,
}) => ({ payload: { beneficiaryUuid, requestPayload, locationPathname } }));
export const updateBeneficiarySuccess = createAction(UPDATE_BENEFICIARY_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const updateBeneficiaryFailure = createAction(UPDATE_BENEFICIARY_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));

