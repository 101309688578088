import { HttpService } from 'services/http';


export const initAPI = (requestPayload) => HttpService.post('/v3/exchange/init', requestPayload);

export const confirmAPI = (exchangeId, config) => HttpService.post(`/v3/exchange/${exchangeId}/confirm`, undefined, config);

export const rejectAPI = (exchangeId) => HttpService.delete(`/v3/exchange/${exchangeId}/reject`);

export const fetchRatesAPI = (queryParams) => HttpService.get('/v2/exchange-rates/', queryParams);

