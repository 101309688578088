import { LocationPathname } from '@manigo/manigo-commons';
import { BusinessUserDetails } from '@manigo/manigo-domain-typings';


export const FETCH_BUSINESS_USER_DETAILS = 'FETCH_BUSINESS_USER_DETAILS';
export const FETCH_BUSINESS_USER_DETAILS_SUCCESS = 'FETCH_BUSINESS_USER_DETAILS_SUCCESS';
export const FETCH_BUSINESS_USER_DETAILS_FAILURE = 'FETCH_BUSINESS_USER_DETAILS_FAILURE';
export const CLEAR_BUSINESS_USER_DETAILS = 'CLEAR_BUSINESS_USER_DETAILS';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_BUSINESS_USER_STATUS = 'UPDATE_BUSINESS_USER_STATUS';
export const UPDATE_BUSINESS_USER_STATUS_SUCCESS = 'UPDATE_BUSINESS_USER_STATUS_SUCCESS';
export const UPDATE_BUSINESS_USER_STATUS_FAILURE = 'UPDATE_BUSINESS_USER_STATUS_FAILURE';

export interface UpdateBusinessUserStatusPayload {
    businessUserId: BusinessUserDetails['businessUserId'];
    requestPayload: {
        businessUserStatus: BusinessUserDetails['businessUserStatus'];
        businessUserStatusComment: string;
    }
    locationPathname: LocationPathname;
}
