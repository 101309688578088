import { combineEpics } from 'redux-observable';
import { catchError } from 'rxjs/operators';

import { developmentMode } from 'config/environment';

import {
    onDeleteAccount,
    onFetchAccountDetails,
    onDownloadAccountStatementPdf,
    onSetAsPrimaryAccount,
    onUpdateAccountName,
} from 'store/account/epics';
import {
    onAddAccount,
    onFetchAccountsList,
} from 'store/accounts/epics';
import {
    onInitApplication,
    onInitApplicationUnauthorisedFlow,
    onInitApplicationAuthorisedFlow,
    onGetJwtTokenFormStorage,
    onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
    onApplicationReadyUnauthorisedUser,
    onSaveFile,
} from 'store/application/epics';
import {
    onSignUp,
    onActivateUser,
    onActivateUserSuccess,
    onInitialiseLogin,
    onConfirmLogin,
    onSetNewPassword,
    onRequestPasswordReset,
    onGetRememberedUserEmail,
    onSetNewPasswordSuccess,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onRefreshToken,
    onConfirmLoginFailure, onSendConfirmationCode,
} from 'store/authorisation/epics';
import { onAddBeneficiary, onFetchBeneficiariesList, onFetchBeneficiaryRequirements } from 'store/beneficiaries/epics';
import {
    onDeleteBeneficiary,
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
} from 'store/beneficiary/epics';
import { onFetchBusinessUserDetails, onUpdateUser, onUpdateBusinessUserStatus } from 'store/business-user/epics';
import { onAddBusinessUser, onFetchBusinessUsersList } from 'store/business-users/epics';
import {
    onDeleteCard,
    onFetchCardDetails,
    onUnblockCardPin,
    onUpdateCardStatus,
    onResetContactlessCardLimit,
    onActivateCard,
    onUpdateCardSettings,
    onUpdateCardLimits,
} from 'store/card/epics';
import {
    onFetchCardSensitiveData,
    onFetchCardSensitiveDataSuccess,
} from 'store/card-sensitive-data/epics';
import { onAddCard, onFetchCardsList } from 'store/cards/epics';
import {
    onFetchCompanyDetails,
    onUpdateCompany,
} from 'store/company/epics';
import { onfetchCountries } from 'store/countries/epics';
import { onFetchCurrencies } from 'store/currencies/epics';
import {
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onSetCurrentUser,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity, onDocumentVisibilityChange, onCheckTokens,
    onUpdateCurrentUserPreferences,
    onUpdateCurrentUserPassword,
} from 'store/current-user/epic';
import {
    onInitExchange,
    onConfirmExchange,
    onRejectExchange,
    onFetchExchangeRate,
    onFetchExchangeRateSuccess,
    onInitExchangeSuccess, onRequestBackMultiStepsFormExchange, onRejectExchangeSuccess,
} from 'store/exchange/epics';
import { onMultiStepsFormStepOrSubSepChange, onRequestSetMultiStepsFormStep } from 'store/multi-steps-form/epics';
import {
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested, onPreformNavigationBack,
    onBlockNavigation,
    onUnblockNavigation, onLocationChanged,
} from 'store/navigation/epic';
import { onFetchStakeholdersList } from 'store/stakeholders/epics';
import { onFetchSupportedCurrencies } from 'store/supported-currencies/epics';
import { onFetchTransactionDetails, onCreateP2PTransaction } from 'store/transaction/epics';
import { onFetchTransactionsList } from 'store/transactions/epics';

import { onFetchKybAccessToken, onFetchKycAccessToken } from './kyb-kyc/epics';
import { onChangeLanguage, onGetLocalesManifest, onInitI18next, onLoadLanguage, onLoadNamespaces } from './locales/epics';
import { onFetchProductsList } from './products/epics';
import scaChallengeEpics from './sca-challange/epics';


const accountEpics = [
    onFetchAccountDetails,
    onDeleteAccount,
    onDownloadAccountStatementPdf,
    onSetAsPrimaryAccount,
    onUpdateAccountName,
];

const accountsEpics = [
    onAddAccount,
    onFetchAccountsList,
];

const applicationEpics = [

    onInitApplication,
    onInitApplicationUnauthorisedFlow, onInitApplicationAuthorisedFlow,
    onApplicationReadyUnauthorisedUser,
    onGetJwtTokenFormStorage, onRemoveJwtTokenFromStorage,
    onSetClipboardContent,
    onShowToast,
    onSaveFile,
];

const authorisation = [
    onSignUp,
    onActivateUser,
    onActivateUserSuccess,
    onInitialiseLogin,
    onConfirmLogin,
    onConfirmLoginFailure,
    onSetNewPassword,
    onRequestPasswordReset,
    onSetNewPasswordSuccess,
    onGetRememberedUserEmail,
    onRequestPasswordResetSuccess,
    onGetTokensForDuplicatedTab,
    onRefreshToken,
    onSendConfirmationCode,
];

const businessUserEpics = [
    onFetchBusinessUserDetails,
    onUpdateUser,
    onUpdateBusinessUserStatus,
];

const beneficiariesEpics = [
    onFetchBeneficiariesList,
    onFetchBeneficiaryRequirements,
    onAddBeneficiary,
];

const beneficiaryEpics = [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];


const businessUsersEpics = [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];

const cardsEpics = [
    onFetchCardsList,
    onAddCard,
];

const exchangeEpics = [
    onInitExchange,
    onInitExchangeSuccess,
    onConfirmExchange,
    onRejectExchange,
    onRejectExchangeSuccess,
    onFetchExchangeRate,
    onFetchExchangeRateSuccess,
    onRequestBackMultiStepsFormExchange,
];

const cardEpics = [
    onFetchCardDetails,
    onDeleteCard,
    onUnblockCardPin,
    onUpdateCardStatus,
    onResetContactlessCardLimit,
    onActivateCard,
    onUpdateCardSettings,
    onUpdateCardLimits,
];

const cardSensitiveDataEpics = [
    onFetchCardSensitiveData,
    onFetchCardSensitiveDataSuccess,
];

const companyEpics = [
    onFetchCompanyDetails,
    onUpdateCompany,
];

const countriesEpics = [
    onfetchCountries,
];

const currenciesEpics = [
    onFetchCurrencies,
];

const currentUserEpics = [
    onSetCurrentUser,
    onSetCurrentUserSuccess,
    onApplicationReadyAuthorisedUser,
    onSetCurrentUserFailure,
    onClearCurrentUser,
    onExtendTokenValidity,
    onExtendTokenValiditySuccess,
    onExpireToken,
    onReceivingToken,
    onUserActivity,
    onCheckTokens,
    onDocumentVisibilityChange,
    onUpdateCurrentUserPreferences,
    onUpdateCurrentUserPassword,
];

const kybKycEpics = [
    onFetchKybAccessToken,
    onFetchKycAccessToken,
];

const localesEpics = [
    onGetLocalesManifest,
    onInitI18next,
    onLoadNamespaces,
    onLoadLanguage,
    onChangeLanguage,
];

const multiStepsFormsEpics = [
    onRequestSetMultiStepsFormStep,
    onMultiStepsFormStepOrSubSepChange,
];

const navigationEpics = [
    onNavigationRequested,
    onPreformNavigation,
    onNavigationBackRequested,
    onPreformNavigationBack,
    onLocationChanged,
    onBlockNavigation,
    onUnblockNavigation,
];


const transactionEpics = [
    onFetchTransactionDetails,
    onCreateP2PTransaction,
];
const transactionsEpics = [
    onFetchTransactionsList,
];

const supportedCurrenciesEpics = [
    onFetchSupportedCurrencies,
];

const productsEpics = [
    onFetchProductsList,
];

const stakeholdersEpics = [
    onFetchStakeholdersList,
];

const epics = [
    ...accountEpics,
    ...accountsEpics,
    ...applicationEpics,
    ...authorisation,
    ...beneficiariesEpics,
    ...beneficiaryEpics,
    ...businessUserEpics,
    ...businessUsersEpics,
    ...cardEpics,
    ...cardSensitiveDataEpics,
    ...cardsEpics,
    ...exchangeEpics,
    ...companyEpics,
    ...countriesEpics,
    ...currenciesEpics,
    ...currentUserEpics,
    ...kybKycEpics,
    ...scaChallengeEpics,
    ...localesEpics,
    ...navigationEpics,
    ...multiStepsFormsEpics,
    ...productsEpics,
    ...stakeholdersEpics,
    ...transactionEpics,
    ...transactionsEpics,
    ...supportedCurrenciesEpics,
];


export const areEpicsDoubled = Array.from(new Set(epics)).length !== epics.length;

if (areEpicsDoubled) {
    throw Error(`Doubled epics! ${Array.from(new Set(epics)).length} vs ${epics.length}`);
}

const onAnyEpic = combineEpics(...epics);

function errorHandler(error, source) {
    if (developmentMode) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return source;
}

const onEpic = (action$, store$, dependencies) => onAnyEpic(action$, store$, dependencies).pipe(catchError(errorHandler));


export default onEpic;
