import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { ModalName } from 'models/app/modal';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { showToast } from 'store/application/actions';
import { hideModal, showModal } from 'store/modal/actions';
import { clearScaChallenge } from 'store/sca-challange/actions';

import { fetchCardSensitiveDataFailure, fetchCardSensitiveDataSuccess, resetData } from './actions';
import { COUNTDOWN_TIME, FETCH_CARD_SENSITIVE_DATA, FETCH_CARD_SENSITIVE_DATA_SUCCESS } from './actions.types';
import { createFetchCardSensitiveDataErrorMessage } from './epics.helpers';


export const onFetchCardSensitiveData: Epic = (action$, state$, { i18n, cards }) => action$.pipe(
    ofType(FETCH_CARD_SENSITIVE_DATA),
    switchMap(({ payload }) => from(cards.getCardSensitiveData(payload))
        .pipe(
            switchMap((response) => {
                return of(
                    fetchCardSensitiveDataSuccess(response.data),
                    clearScaChallenge(),
                    showModal({
                        modalType: ModalName.CARD_SENSITIVE_DATA,
                        modalProps: { title: i18n.t('cards:modals.cardSensitiveData.title'), modalSize: 'lg' },
                    }),
                );
            }),
            catchError((error) => of(
                fetchCardSensitiveDataFailure(),
                showToast({ type: ToastType.error, message: createFetchCardSensitiveDataErrorMessage(i18n.t, error) }),
                clearScaChallenge(),
            )),
        )),
);

export const onFetchCardSensitiveDataSuccess: Epic = (action$) =>
    action$.pipe(
        ofType(FETCH_CARD_SENSITIVE_DATA_SUCCESS),
        debounceTime(COUNTDOWN_TIME),
        mergeMap(() => of(resetData(), hideModal())),
    );
export default [
    onFetchCardSensitiveData,
    onFetchCardSensitiveDataSuccess,
];
