import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { AccountListItem } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';

import { LocationAndQueryParams } from 'models/app/common';
import { StandardListData } from 'models/app/standardLists';
import { WithAccessControl } from 'models/domain/accessControl';


export enum AddBankTransferFormSteps {
    RECIPIENT_SELECTION = 'ADD_BANK_TRANSFER_RECIPIENT_SELECTION',
    TRANSFER_DETAILS = 'ADD_BANK_TRANSFER_TRANSFER_DETAILS',
    REVIEW = 'ADD_BANK_TRANSFER_REVIEW',
    SUCCESS = 'ADD_BANK_TRANSFER_SUCCESS',
}

export interface AddBankTransferPageStateProps {
    enhancedCurrentLocation: EnhancedLocation,
    isLoadingCreatePayment: boolean,
    isLoadingScaChallenge: boolean,
    context: object,
    newEntityLocation?: string
    accountsList: StandardListData<AccountListItem>,
}

export interface AddBankTransferPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }:LocationChangeRequest) => void,
    dispatchFetchAccountsList: ({ queryParams, locationPathname }: LocationAndQueryParams) => void,
    dispatchSetQueryParams: ({ queryParams, locationPathname }: LocationAndQueryParams) => void
}

export type AddBankTransferPageProps = AddBankTransferPageStateProps & AddBankTransferPageDispatchProps & WithTranslation & WithAccessControl;
