import {
    fetchBeneficiariesAPI,
    fetchBeneficiaryUuidDetails,
    fetchBeneficiaryRequirementsApi,
    createBeneficiaryApi,
    updateBeneficiaryUuid,
    deleteBeneficiaryUuid, fetchSupportedCurrenciesAPI,
} from './index';


class BeneficiariesService {
    public fetchBeneficiaries(rawQueryParams) {
        const { limit, offset, companyId } = rawQueryParams;

        const queryParams = {
            limit,
            offset,
            companyId,
        };

        return fetchBeneficiariesAPI({ params: queryParams });
    }

    public fetchBeneficiaryDetails(beneficiaryUuid) {
        return fetchBeneficiaryUuidDetails(beneficiaryUuid);
    }

    public fetchBeneficiaryRequirements(rawQueryParams) {
        const { beneficiaryCountry, beneficiaryCurrency, memberId, companyId, clientId } = rawQueryParams;

        return fetchBeneficiaryRequirementsApi({
            beneficiaryCountry,
            beneficiaryCurrency,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(clientId > 0 ? { clientId } : {}),
        });
    }

    public createBeneficiary(formData) {
        return createBeneficiaryApi(formData);
    }

    public updateBeneficiary(beneficiaryUuid, formData) {
        return updateBeneficiaryUuid(beneficiaryUuid, formData);
    }

    public deleteBeneficiary(requestPayload) {
        const { beneficiaryUuid } = requestPayload;
        return deleteBeneficiaryUuid(beneficiaryUuid);
    }

    public fetchSupportedCurrencies(rawQueryParams) {
        const { memberId, companyId, clientId, currencyMode = 'extended' } = rawQueryParams;

        const queryParams = {
            currencyMode,
            ...(memberId > 0 ? { memberId } : {}),
            ...(companyId > 0 ? { companyId } : {}),
            ...(clientId > 0 ? { clientId } : {}),
        };

        return fetchSupportedCurrenciesAPI({ params: queryParams });
    }
}

export default BeneficiariesService;
