import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { myProfileRootRoutePath } from 'config/routes';

import { showToast } from 'store/application/actions';
import {
    updateBusinessUserStatusFailure,
    updateBusinessUserStatusSuccess,
} from 'store/business-users/actions';
import { createCreateBusinessUserErrorMessage } from 'store/business-users/epics.helpers';
import { businessUsersReducerName } from 'store/business-users/reducer';
import { extendTokenValidity } from 'store/current-user/actions';
import { currentUserReducerName } from 'store/current-user/reducer';
import { updateList } from 'store/list/actions';
import { hideModal } from 'store/modal/actions';

import {
    FETCH_BUSINESS_USER_DETAILS,
    UPDATE_BUSINESS_USER_STATUS,
    UPDATE_USER,
    UpdateBusinessUserStatusPayload,
} from './action.types';
import {
    fetchBusinessUserDetails,
    fetchBusinessUserDetailsFailure,
    fetchBusinessUserDetailsSuccess,
    updateUserFailure,
    updateUserSuccess,
} from './actions';
import { businessUserDetailsPerLocationStoreKeyName, businessUserReducerName } from './reducer';


export const onFetchBusinessUserDetails: Epic = (action$, state$, { businessUsers }) => action$.pipe(
    ofType(FETCH_BUSINESS_USER_DETAILS),
    switchMap(({ payload }) => from(businessUsers.getBusinessUserDetails(payload.queryParams.businessUserId)).pipe(
        switchMap((response) => of(fetchBusinessUserDetailsSuccess({
            responsePayload: response.data,
            locationPathname: payload.locationPathname,
        }))),
        catchError(() => of(fetchBusinessUserDetailsFailure(payload.locationPathname))),
    )),
);

export const onUpdateUser: Epic = (action$, state$, {
    businessUsers,
    i18n,
}) => action$.pipe(
    ofType(UPDATE_USER),
    switchMap(({
        payload: {
            requestPayload,
            locationPathname,
        },
    }) => from(businessUsers.updateBusinessUser(requestPayload)).pipe(
        switchMap((response) => {
            const currentUserData = state$.value[currentUserReducerName]?.userData;
            const { businessUserId } = requestPayload;

            const commonActions = [
                showToast({
                    type: ToastType.success,
                    message: i18n.t('businessUsers:actionMessages.updateUserDataSuccess'),
                }),
                hideModal(),
                updateUserSuccess(response.data, locationPathname),
                updateList({
                    findByPropName: 'businessUserId',
                    findByPropValue: businessUserId,
                    listReducerName: businessUsersReducerName,
                }),
            ];

            if (locationPathname?.includes(myProfileRootRoutePath) || businessUserId === currentUserData?.identifiers?.businessUserId) {
                return of(
                    ...commonActions,
                    extendTokenValidity(),
                );
            }

            return of(...commonActions);
        }),
        catchError((error) => of(
            showToast({ type: ToastType.error, message: createCreateBusinessUserErrorMessage(i18n.t, error) }),
            updateUserFailure(),
        )),
    )),
);

export const onUpdateBusinessUserStatus = (action$, state$, { i18n, businessUsers }) => action$.pipe(
    ofType(UPDATE_BUSINESS_USER_STATUS),
    switchMap(({ payload }: {
        payload: UpdateBusinessUserStatusPayload
    }) => from(businessUsers.updateBusinessUserStatus(payload.businessUserId, payload.requestPayload))
        .pipe(
            switchMap(() => {
                const currentBusinessUserDetailsQueryParams = state$.value[businessUserReducerName][businessUserDetailsPerLocationStoreKeyName
                ]?.[payload.locationPathname]?.queryParams;

                return of(
                    fetchBusinessUserDetails({
                        queryParams: { ...currentBusinessUserDetailsQueryParams },
                        locationPathname: payload.locationPathname,
                    }),
                    updateBusinessUserStatusSuccess(),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('businessUsers:actionMessages.updateStatusSuccess'),
                    }),
                    hideModal(),
                    updateList({
                        findByPropName: 'businessUserId',
                        findByPropValue: payload.businessUserId,
                        listReducerName: businessUsersReducerName,
                    }),
                );
            }),
            catchError(() => of(
                updateBusinessUserStatusFailure(),
            )),
        )),
);

export default [
    onFetchBusinessUserDetails,
    onUpdateUser,
    onUpdateBusinessUserStatus,
];
