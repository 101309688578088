import { ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, debounceTime, mergeMap, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { showToast } from 'store/application/actions';
import { requestSetMultiStepsFormStep } from 'store/multi-steps-form/actions';
import { REQUEST_SET_MULTI_STEPS_FORM_STEP } from 'store/multi-steps-form/actions.types';
import { multiStepsFormReducerName } from 'store/multi-steps-form/reducer';
import { clearScaChallenge } from 'store/sca-challange/actions';

import { ExchangePageFormSteps } from 'components/pages/authorised/payments/ExchangePage/ExchangePage.types';


import {
    initExchangeSuccess, initExchangeFailure,
    confirmExchangeSuccess, confirmExchangeFailure,
    rejectExchange, rejectExchangeSuccess, rejectExchangeFailure,
    fetchExchangeRateSuccess, fetchExchangeRateFailure, clearExchangeData, clearInitExchangeInfo,
} from './actions';
import {
    CONFIRM_EXCHANGE,
    FETCH_EXCHANGE_RATE,
    FETCH_EXCHANGE_RATE_SUCCESS,
    INIT_EXCHANGE,
    REJECT_EXCHANGE,
    COUNTDOWN_TIME, INIT_EXCHANGE_COUNTDOWN_TIME, INIT_EXCHANGE_SUCCESS, REJECT_EXCHANGE_SUCCESS,
} from './actions.types';
import { createConfirmExchangeErrorMessage } from './epics.helpers';
import { exchangeReducerName } from './reducer';


export const onInitExchange: Epic = (action$, state$, { exchanges }) => action$.pipe(
    ofType(INIT_EXCHANGE),
    switchMap(({ payload }) => from(exchanges.init(payload.requestPayload))
        .pipe(
            switchMap((response) => of(
                initExchangeSuccess({ responsePayload: response.data }),
                requestSetMultiStepsFormStep(
                    ExchangePageFormSteps.TRANSFER_DETAILS,
                    MultiStepFormStepChangeDirections.FORWARD,
                ),
            )),
            catchError(() => of(initExchangeFailure())),
        )),
);

export const onInitExchangeSuccess: Epic = (action$, state$) => action$.pipe(
    ofType(INIT_EXCHANGE_SUCCESS),
    debounceTime(INIT_EXCHANGE_COUNTDOWN_TIME),
    switchMap(() => {
        const commonAction = [clearInitExchangeInfo()];
        if (state$.value[multiStepsFormReducerName].currentStep === ExchangePageFormSteps.REVIEW) {
            return of(
                ...commonAction,
                requestSetMultiStepsFormStep(ExchangePageFormSteps.REVIEW, MultiStepFormStepChangeDirections.BACK),
            );
        } else if (state$.value[multiStepsFormReducerName].currentStep === ExchangePageFormSteps.TRANSFER_DETAILS) {
            return of(...commonAction);
        }
        return EMPTY;
    }),
);

export const onConfirmExchange: Epic = (action$, state$, { exchanges, i18n }) => action$.pipe(
    ofType(CONFIRM_EXCHANGE),
    switchMap(({ payload }) => from(exchanges.confirm(payload)).pipe(
        switchMap(() => of(
            confirmExchangeSuccess(),
            clearExchangeData(),
            clearScaChallenge(),
            requestSetMultiStepsFormStep(ExchangePageFormSteps.REVIEW, MultiStepFormStepChangeDirections.FORWARD),
        )),
        catchError((error) => of(
            showToast({ type: ToastType.error, message: createConfirmExchangeErrorMessage(i18n.t, error) }),
            clearScaChallenge(),
            confirmExchangeFailure(),
            // rejectExchange(payload.sourceRequestData), // TODO not sure if needed
        )),
    )),
);

export const onRejectExchange: Epic = (action$, state$, { exchanges }) => action$.pipe(
    ofType(REJECT_EXCHANGE),
    switchMap(({ payload }) => from(exchanges.reject(payload)).pipe(
        switchMap(() =>
            of(rejectExchangeSuccess())),
        catchError(() => of(rejectExchangeFailure())),
    )),
);

export const onRejectExchangeSuccess: Epic = (action$) => action$.pipe(
    ofType(REJECT_EXCHANGE_SUCCESS),
    switchMap(() =>
        of(clearInitExchangeInfo())),
    catchError(() => of(rejectExchangeFailure())),
);

export const onFetchExchangeRate: Epic = (action$, state$, { exchanges }) => action$.pipe(
    ofType(FETCH_EXCHANGE_RATE),
    switchMap(({ payload }) => from(exchanges.fetchRate({ sourceCurrency: payload.queryParams.sourceCurrency })).pipe(
        switchMap((response) => of(fetchExchangeRateSuccess({ responsePayload: { exchangeRates: response.data?.[0]?.rates } }))),
        catchError(() => of(fetchExchangeRateFailure())),
    )),
);

export const onFetchExchangeRateSuccess: Epic = (action$, state$) => action$.pipe(
    ofType(FETCH_EXCHANGE_RATE_SUCCESS),
    debounceTime(COUNTDOWN_TIME),
    mergeMap(() => {
        if (state$.value[multiStepsFormReducerName].currentStep === ExchangePageFormSteps.TRANSFER_DETAILS) {
            return of(clearExchangeData());
        }
        return EMPTY;
    }),
);

export const onRequestBackMultiStepsFormExchange: Epic = (action$, state$) => action$.pipe(
    ofType(REQUEST_SET_MULTI_STEPS_FORM_STEP),
    mergeMap(({ payload }) => {
        const { currentStep, direction } = payload;
        if (currentStep === ExchangePageFormSteps.REVIEW && direction === MultiStepFormStepChangeDirections.BACK && state$.value[exchangeReducerName].initExchangeInfo) {
            return of(rejectExchange({ extId: state$.value[exchangeReducerName].initExchangeInfo?.extId }));
        }
        return EMPTY;
    }),
);


export default [
    onInitExchange,
    onInitExchangeSuccess,
    onConfirmExchange,
    onRejectExchange,
    onRejectExchangeSuccess,
    onFetchExchangeRate,
    onFetchExchangeRateSuccess,
    onRequestBackMultiStepsFormExchange,
];
