import { TFunction, ApiError } from '@manigo/manigo-commons';


export const createCreatePaymentErrorMessage = (t: TFunction, error: ApiError) => {
    const handlers = [
        {
            predicate: () => error.message === 'Invalid signature.',
            handler: () => (t('sca:actionMessages.invalidSignature')),
        },
        {
            predicate: () => error.message === 'Invalid challenge.',
            handler: () => (t('sca:actionMessages.invalidChallenge')),
        },
        {
            predicate: () => true,
            handler: () => (t('unauthorised:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
