import { EnhancedLocation } from '@manigo/manigo-commons';

import { tabKeySubTabKeySeparator } from 'models/app/applicationState';


export const extractBaseURLWithoutActiveTab = (locationPathname = '', baseURLSegmentsCount = 2) => locationPathname.split('/')?.slice(0, baseURLSegmentsCount + 1).join('/');


export const extractSegmentsFromPathname = (enhancedCurrentLocation: EnhancedLocation) => enhancedCurrentLocation?.pathname?.split('/')?.slice(1);

export const splitActiveTabKey = (tabKey) => {
    if (tabKey) {
        return tabKey.split(tabKeySubTabKeySeparator);
    }
    return undefined;
};


export default { splitActiveTabKey };

