export const FETCH_BENEFICIARIES_LIST = 'FETCH_BENEFICIARIES_LIST';
export const FETCH_BENEFICIARIES_LIST_SUCCESS = 'FETCH_BENEFICIARIES_LIST_SUCCESS';
export const FETCH_BENEFICIARIES_LIST_FAILURE = 'FETCH_BENEFICIARIES_LIST_FAILURE';
export const CLEAR_BENEFICIARIES_LIST = 'CLEAR_BENEFICIARIES_LIST';


export const ADD_BENEFICIARY = 'ADD_BENEFICIARY';
export const ADD_BENEFICIARY_SUCCESS = 'ADD_BENEFICIARY_SUCCESS';
export const ADD_BENEFICIARY_FAILURE = 'ADD_BENEFICIARY_FAILURE';


export const FETCH_BENEFICIARY_REQUIREMENTS = 'FETCH_BENEFICIARY_REQUIREMENTS';
export const FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS = 'FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS';
export const FETCH_BENEFICIARY_REQUIREMENTS_FAILURE = 'FETCH_BENEFICIARY_REQUIREMENTS_FAILURE';
export const CLEAR_BENEFICIARY_REQUIREMENTS = 'CLEAR_BENEFICIARY_REQUIREMENTS';
