import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_TRANSACTIONS_LIST,
    FETCH_TRANSACTIONS_LIST,
    FETCH_TRANSACTIONS_LIST_FAILURE,
    FETCH_TRANSACTIONS_LIST_SUCCESS,
} from './actions.types';


export const fetchTransactionsList = createAction(FETCH_TRANSACTIONS_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchTransactionsListSuccess = createAction(
    FETCH_TRANSACTIONS_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchTransactionsListFailure = createAction(FETCH_TRANSACTIONS_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearTransactionsList = createAction(CLEAR_TRANSACTIONS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));
