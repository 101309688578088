import { createReducer } from '@reduxjs/toolkit';

import { MARK_AS_UPDATED_LIST, UPDATE_LIST } from './actions.types';


export const listReducerName = 'list';

export interface ListWatcher {
    readonly entity: any;
    readonly findByPropName: string;
    readonly findByPropValue: any;
    readonly listReducerName: string;
    readonly entityReducerName: string;
    readonly createdAt: Date;
    readonly updates: {
        readonly locationPathname: string;
        readonly updatedAt: Date;
    }[]
}

export interface ListStateType {
    readonly listWatchers: ListWatcher[];
}

const initialState: ListStateType = { listWatchers: [] };

const createListBuilderCases = (builder) =>
    builder.addCase(UPDATE_LIST, (state, action) => {
        const { payload } = action;
        const watcherIndex = state.listWatchers.findIndex((watcher: any) =>
            watcher.listReducerName === payload.listReducerName);

        if (watcherIndex === -1) {
            state.listWatchers.push({
                ...payload,
                createdAt: new Date(),
                updates: [],
            });
        } else {
            state.listWatchers[watcherIndex] = {
                ...payload,
                createdAt: new Date(),
                updates: [],
            };
        }
    }).addCase(MARK_AS_UPDATED_LIST, (state, action) => {
        const { payload: { locationPathname, entityReducerName } } = action;
        const indexOfWatcher = state.listWatchers.findIndex((watcher: any) =>
            watcher.listReducerName === entityReducerName);

        const watcher = state.listWatchers[indexOfWatcher];
        if (watcher) {
            watcher.updates.push({
                locationPathname,
                updatedAt: new Date(),
            });
        }
    });

export default createReducer(initialState, createListBuilderCases);
