import { ScaChallengeRequest, NumericId } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';

import { SendConfirmationCodePayload } from 'store/authorisation/actions.types';


export const activateUserAPI = (requestPayload: object) => HttpService.post('/v3/bwp/auth/activate-user', requestPayload, { noErrorHandling: true });

export const loginInitAPI = (requestPayload: object) => HttpService.post('/v3/bwp/auth/login/init', requestPayload, { noErrorHandling: true });

export const loginConfirmAPI = (requestPayload: object) => HttpService.post('/v3/bwp/auth/login/confirm', requestPayload, { noErrorHandling: true });
export const setNewPasswordAPI = (requestPayload: object) => HttpService.post('/v3/bwp/auth/password/reset', requestPayload, { noErrorHandling: true });

export const requestPasswordResetAPI = (requestPayload: object) => HttpService.post('/v3/bwp/auth/password/forgot', requestPayload, { noErrorHandling: true });

export const extendTokenValidityAPI = (requestPayload: object) => HttpService.put('/v3/bwp/auth/session-refresh', requestPayload);

export const getTokensForDuplicatedSessionAPI = (requestPayload: object) => HttpService.put('/v3/bwp/auth/session-duplicate', requestPayload);

export const expireTokenAPI = () => HttpService.put('/v2/auth/session-logout', undefined, { noErrorHandling: true });
export const signUpCompanyAPI = (requestPayload: object) => HttpService.post('/v4/companies/signup', requestPayload, { noErrorHandling: true });

export const createScaChallengeAPI = (requestPayload: ScaChallengeRequest) => HttpService.post('/v2/sca/challenges', requestPayload, {
    noErrorHandling: true,
    returnResponseHeaders: true,
});

export const deleteScaChallengeAPI = (challengeId: NumericId) => HttpService.delete(`/v2/sca/challenges/${challengeId}`, undefined, { noErrorHandling: true });


export const sendConfirmationCodeAPI = (requestPayload: SendConfirmationCodePayload) => HttpService.post(
    '/v3/bwp/auth/send-confirmation-code',
    requestPayload,
    { noErrorHandling: true },
);
