import React, { useMemo, JSX, Suspense } from 'react';

import { ApplicationState } from 'models/app/applicationState';

import { MAINTANCE_MODE_ENABLED } from 'config/environment';


import { ApplicationContentProps } from './ApplicationContent.types';
import AppLoadingPlaceholder from '../AppLoadingPlaceholder';
import AppMaintenancePlaceholder from '../AppMaintenancePlaceholder';


const Public = React.lazy(
    () => import(/* webpackChunkName: "publicPart" */ /* webpackPreload: true */ './ApplicationParts/Public.layout'),
);
const AuthorisedOnboarding = React.lazy(
    () => import(/* webpackChunkName: "onboardingPart" */ /* webpackPreload: true */ './ApplicationParts/AuthorisedOnboarding.layout'),
);
const AuthorisedVerified = React.lazy(
    () => import(/* webpackChunkName: "authorisedPart" */ /* webpackPreload: true */ './ApplicationParts/AuthorisedVerified.layout'),
);

function renderProperApplicationPart({ showSpinner, isAuthorised, lockUserInKybKycOnboarding, accessControl, enhancedCurrentLocation }): JSX.Element {


    const handlers: { predicate: boolean, handler: () => JSX.Element }[] = [
        {
            predicate: MAINTANCE_MODE_ENABLED,
            handler: () => <AppMaintenancePlaceholder />,
        },
        {
            predicate: showSpinner,
            handler: () => <AppLoadingPlaceholder />,
        },
        {
            predicate: !isAuthorised,
            handler: () => (
                <Suspense fallback={<AppLoadingPlaceholder hideLoadingDetails />}>
                    <Public enhancedCurrentLocation={enhancedCurrentLocation} accessControl={accessControl} />
                </Suspense>
            ),
        },
        {
            predicate: isAuthorised && lockUserInKybKycOnboarding,
            handler: () => (
                <Suspense fallback={<AppLoadingPlaceholder />}>
                    <AuthorisedOnboarding accessControl={accessControl} enhancedCurrentLocation={enhancedCurrentLocation} />
                </Suspense>
            ),
        },
        {
            predicate: isAuthorised && !lockUserInKybKycOnboarding,
            handler: () => (
                <Suspense fallback={<AppLoadingPlaceholder hideLoadingDetails />}>
                    <AuthorisedVerified accessControl={accessControl} enhancedCurrentLocation={enhancedCurrentLocation} />
                </Suspense>
            ),
        },
        {
            predicate: true,
            handler: () => <div>A serious error!</div>,
        },
    ];

    return handlers.find(({ predicate }) => predicate)?.handler() as JSX.Element;
}

function ApplicationContent({
    lockUserInKybKycOnboarding,
    accessControl,
    applicationState,
    enhancedCurrentLocation,
    isRefreshingSession,
    isI18nReady,
}: ApplicationContentProps): JSX.Element {
    const isAuthorised = useMemo(
        () => accessControl?.isAuthorised,
        [accessControl],
    );


    const showSpinner = useMemo(
        () => applicationState === ApplicationState.APPLICATION_STARTED
            || (accessControl.isAuthorised && ![ApplicationState.AUTHORISED_READY, ApplicationState.AUTHORISED_ONBOARDING].includes(applicationState))
            || !isI18nReady,

        [isAuthorised, applicationState, isRefreshingSession, isI18nReady],
    );

    return renderProperApplicationPart({
        showSpinner,
        lockUserInKybKycOnboarding,
        accessControl,
        enhancedCurrentLocation,
        isAuthorised,
    });

}


export default ApplicationContent;

