import { ApiError, ApiResponse } from '@manigo/manigo-commons';
import { SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';

import { addScaHeaders } from 'services/http/http.helpers';

import { ConfirmExchangeRequestPayload } from 'store/exchange/actions.types';

import {
    initAPI,
    confirmAPI,
    rejectAPI,
    fetchRatesAPI,
} from './index';


class ExchangeService {
    public init(requestPayload) {
        return initAPI(requestPayload);
    }

    public confirm(requestPayload: SubmitScaChallengeActionPayload<ConfirmExchangeRequestPayload>): Promise<ApiResponse | ApiError> {
        const { extId } = requestPayload.sourceRequestData;
        const config = addScaHeaders(requestPayload, { noErrorHandling: true });
        return confirmAPI(extId, config);
    }

    public reject(queryParams) {
        const { extId } = queryParams;
        return rejectAPI(extId);
    }

    public fetchRate(requestPayload) {
        const queryParams = { params: requestPayload };
        return fetchRatesAPI(queryParams);
    }
}

export default ExchangeService;
