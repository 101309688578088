import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { WithTranslation } from 'react-i18next';

import { WithAccessControl } from 'models/domain/accessControl';


export enum AddBusinessUserFormSteps {
    BASIC_DATA = 'ADD_BUSINESS_USER_BASIC_DATA',
    PREFERENCES = 'ADD_BUSINESS_USER_PREFERENCES',
    REVIEW = 'ADD_BUSINESS_USER_REVIEW',
    SUCCESS = 'ADD_BUSINESS_USER_SUCCESS',
}


export interface AddBusinessUserPageStateProps {
    isLoadingAddBusinessUser: boolean,
    enhancedCurrentLocation: EnhancedLocation,
    context?: any,
    newEntityLocation?: string

}

export interface AddBusinessUserPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }:LocationChangeRequest) => void,
}

export type AddBusinessUserPageProps = AddBusinessUserPageStateProps & AddBusinessUserPageDispatchProps & WithTranslation & WithAccessControl;
