import { SessionUuid } from '@manigo/manigo-domain-typings';


export const ACTIVATE_USER = 'ACTIVATE_USER';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE';

export const INITIALISE_LOGIN = 'INITIALISE_LOGIN';
export const INITIALISE_LOGIN_SUCCESS = 'INITIALISE_LOGIN_SUCCESS';
export const INITIALISE_LOGIN_FAILURE = 'INITIALISE_LOGIN_FAILURE';

export const CONFIRM_LOGIN = 'CONFIRM_LOGIN';
export const CONFIRM_LOGIN_SUCCESS = 'CONFIRM_LOGIN_SUCCESS';
export const CONFIRM_LOGIN_FAILURE = 'CONFIRM_LOGIN_FAILURE';

export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';

export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';


export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

export const CLEAR_REQUEST_PASSWORD_RESET_STATE = 'CLEAR_REQUEST_PASSWORD_RESET_STATE';

export const GET_TOKENS_FOR_DUPLICATED_TAB = 'GET_TOKENS_FOR_DUPLICATED_TAB';

export const GET_REMEMBERED_LOGIN_USER_EMAIL = 'GET_REMEMBERED_LOGIN_USER_EMAIL';
export const GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS = 'GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS';
export const GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE = 'GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE';

export const LOGIN_COUNTDOWN_TIME = 180000; // 3 minutes
export const RESEND_CONFIRMATION_CODE_COUNTDOWN_TIME = 60000; // 1 minute


export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';


export const SEND_CONFIRMATION_CODE = 'SEND_CONFIRMATION_CODE';
export const SEND_CONFIRMATION_CODE_SUCCESS = 'SEND_CONFIRMATION_CODE_SUCCESS';
export const SEND_CONFIRMATION_CODE_FAILURE = 'SEND_CONFIRMATION_CODE_FAILURE';
export const CLEAR_BLOCK_SEND_CONFIRMATION_CODE = 'CLEAR_BLOCK_SEND_CONFIRMATION_CODE';


export interface SendConfirmationCodePayload {
    token: SessionUuid,
}
