import { convertCamelCaseToSnakeCase, cleanNilValues } from '@manigo/manigo-commons';

import { SortDirections } from 'models/app/applicationState';

import { withTotalCount } from 'services/http/http.helpers';

import {
    getCompanyIdDetails,
    updateCompanyId,
    getCompanyIdBusinessUsers,
    createBusinessUserForCompanyId,
    getStakeholdersAPI,
} from './index';


class CompaniesService {

    public getCompanyDetails(queryParams) {
        return getCompanyIdDetails(queryParams?.companyId);
    }

    public updateCompany({ companyId, formData }) {
        return updateCompanyId(companyId, formData);
    }

    public getCompanyBusinessUsers(rawQueryParams) {
        const {
            companyId,
            search,
            sortOrder,
            sortBy,
            ...restOfQueryParams
        } = rawQueryParams;

        const queryParams = cleanNilValues({
            ...restOfQueryParams,
            ...(sortBy ? { order: [`${sortOrder === SortDirections.DESCEND ? '-' : ''}${convertCamelCaseToSnakeCase(sortBy)}`] } : {}),
            ...withTotalCount,
        });

        return getCompanyIdBusinessUsers({ params: queryParams });
    }

    public createBusinessUsersForCompany(formData) {
        const { companyId, ...rest } = formData;

        return createBusinessUserForCompanyId(companyId, rest);
    }

    public getStakeholders(rawQuery) {
        const {
            companyId,
            ...restOfQueryParams
        } = rawQuery;


        return getStakeholdersAPI(companyId, { params: { ...restOfQueryParams } });
    }
}

export default CompaniesService;

