import { createAction } from '@reduxjs/toolkit';

import { ClearEntityDetailsPayload } from 'models/app/standardEntityDetails';

import {
    FETCH_ACCOUNT_DETAILS,
    FETCH_ACCOUNT_DETAILS_FAILURE,
    FETCH_ACCOUNT_DETAILS_SUCCESS,
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_SUCCESS,
    DELETE_ACCOUNT_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS,
    HIDE_DOWNLOAD_PDF_STATEMENT_FORM,
    SET_AS_PRIMARY_ACCOUNT,
    SET_AS_PRIMARY_ACCOUNT_FAILURE,
    SET_AS_PRIMARY_ACCOUNT_SUCCESS,
    SHOW_DOWNLOAD_PDF_STATEMENT_FORM,
    UPDATE_ACCOUNT_NAME,
    UPDATE_ACCOUNT_NAME_FAILURE,
    UPDATE_ACCOUNT_NAME_SUCCESS,

    FetchAccountDetailsPayload,
    FetchAccountDetailsSuccessPayload,
    FetchAccountDetailsFailurePayload,
    DeleteAccountPayload,
    DeleteAccountSuccessPayload,
    UpdateAccountNamePayload,
    SetAsPrimaryAccountPayload,
    ShowDownloadPdfStatementFormPayload,
    HideDownloadPdfStatementFormPayload,
    DownloadAccountStatementPdfFailurePayload, DownloadAccountStatementPdfPayload, DownloadAccountStatementPdfSuccessPayload,
} from './actions.types';


export const fetchAccountDetails = createAction(FETCH_ACCOUNT_DETAILS,
    ({ queryParams, locationPathname }:FetchAccountDetailsPayload) => ({ payload: { queryParams, locationPathname } }));
export const fetchAccountDetailsSuccess = createAction(FETCH_ACCOUNT_DETAILS_SUCCESS, ({ responsePayload, locationPathname }:FetchAccountDetailsSuccessPayload) => ({
    payload: {
        responsePayload,
        locationPathname,
    },
}));
export const fetchAccountDetailsFailure = createAction(FETCH_ACCOUNT_DETAILS_FAILURE,
    ({ locationPathname }:FetchAccountDetailsFailurePayload) => ({ payload: { locationPathname } }));
export const clearAccountDetails = createAction(CLEAR_ACCOUNT_DETAILS, ({ locationPathname }:ClearEntityDetailsPayload) => ({ payload: { locationPathname } }));

export const deleteAccount = createAction(DELETE_ACCOUNT, ({ queryParams, locationPathname }:DeleteAccountPayload) => ({ payload: { queryParams, locationPathname } }));
export const deleteAccountSuccess = createAction(DELETE_ACCOUNT_SUCCESS, ({ responsePayload }:DeleteAccountSuccessPayload) => ({ payload: responsePayload }));
export const deleteAccountFailure = createAction(DELETE_ACCOUNT_FAILURE);

export const setAsPrimaryAccount = createAction(SET_AS_PRIMARY_ACCOUNT,
    ({ queryParams, locationPathname }: SetAsPrimaryAccountPayload) => ({ payload: { queryParams, locationPathname } }));
export const setAsPrimaryAccountSuccess = createAction(SET_AS_PRIMARY_ACCOUNT_SUCCESS);
export const setAsPrimaryAccountFailure = createAction(SET_AS_PRIMARY_ACCOUNT_FAILURE);

export const updateAccountName = createAction(UPDATE_ACCOUNT_NAME,
    ({ queryParams, locationPathname }:UpdateAccountNamePayload) => ({ payload: { queryParams, locationPathname } }));
export const updateAccountNameSuccess = createAction(UPDATE_ACCOUNT_NAME_SUCCESS);
export const updateAccountNameFailure = createAction(UPDATE_ACCOUNT_NAME_FAILURE);

export const showDownloadPdfStatementForm = createAction(SHOW_DOWNLOAD_PDF_STATEMENT_FORM, (payload:ShowDownloadPdfStatementFormPayload) => ({ payload }));
export const hideDownloadPdfStatementForm = createAction(HIDE_DOWNLOAD_PDF_STATEMENT_FORM, (payload:HideDownloadPdfStatementFormPayload) => ({ payload }));


export const downloadAccountStatementPdf = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    ({ queryParams }:DownloadAccountStatementPdfPayload) => ({ payload: { queryParams } }));
export const downloadAccountStatementPdfSuccess = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS,
    (payload:DownloadAccountStatementPdfSuccessPayload) => ({ payload }));
export const downloadAccountStatementPdfFailure = createAction(DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    (payload:DownloadAccountStatementPdfFailurePayload) => ({ payload }));

