import { downloadTransactionStatementAPI } from './index';


class ReportsService {
    public downloadTransactionStatement(rawQueryParams) {
        const { accountId, dateFrom, dateTo } = rawQueryParams;

        return downloadTransactionStatementAPI({ params: { accountId, dateFrom, dateTo } });
    }
}

export default ReportsService;
