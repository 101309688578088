import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import { getChakraVarColorByChakraHookReturn, getDefaultLightDarkValues } from 'utils/theme-tools';

import Styled from './AnimatedCrossMark.styled';


function AnimatedCrossMark({ size = '200px' }) {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());
    return (
        <Styled.AnimatedCrossMarkWrapper $size={size} $colorScheme={getChakraVarColorByChakraHookReturn(colorScheme)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className="checkmark__circle" cx="26" cy="26" r="26" />
                <line id="path2" fill="none" x1="12" y1="40" x2="40" y2="12" />
                <line id="path3" fill="none" x1="40" y1="40" x2="12" y2="12" />
            </svg>
        </Styled.AnimatedCrossMarkWrapper>
    );
}


export default AnimatedCrossMark;
