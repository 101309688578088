import { Uuid } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';


export const fetchBeneficiariesAPI = (queryParams: object) => HttpService.get('/v3/beneficiaries', queryParams);

export const fetchBeneficiaryUuidDetails = (beneficiaryUuid: Uuid) => HttpService.get(`/v3/beneficiaries/${beneficiaryUuid}`);

export const fetchBeneficiaryRequirementsApi = (queryParams: object) => HttpService.get('/v3/beneficiaries/requirements', { params: queryParams, noErrorHandling: true });

export const createBeneficiaryApi = (formData: object) => HttpService.post('/v3/beneficiaries', formData);

export const updateBeneficiaryUuid = (beneficiaryUuid: Uuid, formData: object) => HttpService.put(`/v3/beneficiaries/${beneficiaryUuid}`, formData);

export const deleteBeneficiaryUuid = (beneficiaryUuid: Uuid) => HttpService.delete(`/v3/beneficiaries/${beneficiaryUuid}`);

export const fetchSupportedCurrenciesAPI = (queryParams: object) => HttpService.get('/v3/beneficiaries/supported-currencies', queryParams);

export default {
    fetchBeneficiariesAPI,
    fetchBeneficiaryUuidDetails,
    fetchBeneficiaryRequirementsApi,
    createBeneficiaryApi,
    updateBeneficiaryUuid,
    deleteBeneficiaryUuid,
    fetchSupportedCurrenciesAPI,
};
