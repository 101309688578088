import { createReducer } from '@reduxjs/toolkit';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    CLEAR_SUPPORTED_CURRENCIES,
    FETCH_SUPPORTED_CURRENCIES,
    FETCH_SUPPORTED_CURRENCIES_FAILURE,
    FETCH_SUPPORTED_CURRENCIES_SUCCESS,
} from './actions.types';


export const supportedCurrenciesReducerName = 'supportedCurrencies';
export const supportedCurrenciesListsPerLocationStoreKeyName = 'supportedCurrenciesPerLocation';


export const defaultSupportedCurrenciesListQueryParams = {
    clientId: undefined,
    memberId: undefined,
    companyId: undefined,
    currencyMode: 'extended', // 'extended' for non-standard currency object vs 'minimal' for array of currency  ISO codes (strings)
};

export const supportedCurrenciesInitialState = { [supportedCurrenciesListsPerLocationStoreKeyName]: {} };

const createSupportedCurrenciesBuilderCases = (builder) =>
    builder
        .addCase(FETCH_SUPPORTED_CURRENCIES, (state, action) => {
            state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[supportedCurrenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_SUPPORTED_CURRENCIES_SUCCESS, (state, action) => {
            state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[supportedCurrenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_SUPPORTED_CURRENCIES_FAILURE, (state, action) => {
            state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[supportedCurrenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === supportedCurrenciesReducerName
                && action.payload?.fieldName === supportedCurrenciesListsPerLocationStoreKeyName
            ) {
                state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: { ...state?.[supportedCurrenciesListsPerLocationStoreKeyName] },
                    action,
                });
            }
        })
        .addCase(CLEAR_SUPPORTED_CURRENCIES, (state, action) => {
            state[supportedCurrenciesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[supportedCurrenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CURRENT_USER, () => supportedCurrenciesInitialState);

export default createReducer(supportedCurrenciesInitialState, createSupportedCurrenciesBuilderCases);
