import { SubmitScaChallengeActionPayload, CurrencyIsoCode, NumericId, Uuid } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import { ActionPayload } from 'store/common.helpers';

import {
    FETCH_TRANSACTION_DETAILS, FETCH_TRANSACTION_DETAILS_FAILURE,
    FETCH_TRANSACTION_DETAILS_SUCCESS, CLEAR_TRANSACTION_DETAILS,
    CREATE_PAYMENT, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAILURE,
} from './action.types';


export const fetchTransactionDetails = createAction(FETCH_TRANSACTION_DETAILS, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchTransactionDetailsSuccess = createAction(FETCH_TRANSACTION_DETAILS_SUCCESS,
    ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const fetchTransactionDetailsFailure = createAction(FETCH_TRANSACTION_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearTransactionDetails = createAction(CLEAR_TRANSACTION_DETAILS, ({ locationPathname }) => ({ payload: { locationPathname } }));

export interface PaymentPayload {
    accountId: NumericId;
    amount: number;
    currency: CurrencyIsoCode;
    reason?: string;
    reference: string;
    receiver: {
        accountId?: NumericId;
        beneficiaryId?: Uuid;
    }
}

export interface OutgoingPaymentPayload extends Omit<PaymentPayload, 'currency'> {
    currency?: CurrencyIsoCode;
}

export const createPayment = createAction(CREATE_PAYMENT,
    (requestPayload: SubmitScaChallengeActionPayload<PaymentPayload>): ActionPayload<SubmitScaChallengeActionPayload<PaymentPayload>> => ({ payload: requestPayload }));
export const createPaymentSuccess = createAction(CREATE_PAYMENT_SUCCESS, (data) => ({ payload: data }));
export const createPaymentFailure = createAction(CREATE_PAYMENT_FAILURE);
