import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { withTotalCount } from 'services/http/http.helpers';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    ADD_ACCOUNT,
    ADD_ACCOUNT_FAILURE,
    ADD_ACCOUNT_SUCCESS,
    CLEAR_ACCOUNTS_LIST,
    FETCH_ACCOUNTS_LIST,
    FETCH_ACCOUNTS_LIST_FAILURE,
    FETCH_ACCOUNTS_LIST_SUCCESS,
} from './actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';


export const accountsReducerName = 'accounts';
export const accountsListsPerLocationStoreKeyName = 'accountsListsPerLocation';

export const accountListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    type: undefined,
    currency: undefined,
    companyId: undefined,
    businessUserId: undefined,
    sortBy: 'currency',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

export const accountsInitialState = {
    [accountsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddAccount: false,
    downloadPdfStatement: { },
};

const createAccountsBuilderCases = (builder) => builder
    .addCase(ADD_ACCOUNT, (state) => {
        state.isLoadingAddAccount = true;
    })
    .addCase(ADD_ACCOUNT_SUCCESS, (state) => {
        state.isLoadingAddAccount = false;
    })
    .addCase(ADD_ACCOUNT_FAILURE, (state) => {
        state.isLoadingAddAccount = false;
    })
    .addCase(FETCH_ACCOUNTS_LIST, (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardLists({
            listsState: state[accountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_ACCOUNTS_LIST_SUCCESS, (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
            listsState: state[accountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(FETCH_ACCOUNTS_LIST_FAILURE, (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
            listsState: state[accountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(CLEAR_ACCOUNTS_LIST, (state, action) => {
        state[accountsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
            listsState: state[accountsListsPerLocationStoreKeyName],
            action,
        });
    })
    .addCase(SET_QUERY_PARAMS, (state, action) => {
        if (action.payload?.reducerName === accountsReducerName
            && action.payload?.fieldName === accountsListsPerLocationStoreKeyName
        ) {
            state[accountsListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                listsState: state[accountsListsPerLocationStoreKeyName],
                action,
            });
        }
    })
    .addCase(CLEAR_CURRENT_USER, (state) => {
        state[accountsListsPerLocationStoreKeyName] = {};
    });


export default createReducer(accountsInitialState, createAccountsBuilderCases);
