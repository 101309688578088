import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { SENTRY_DSN } from './environment';
import packageJson from '../../package.json';


const initializeSentry = (history) => {
    if (SENTRY_DSN?.length > 0) {
        Sentry.init({
            dsn: `${SENTRY_DSN}`,
            release: `manigo-backoffice-frontend@${packageJson.version}`,
            tracesSampleRate: 0.6,
            ignoreErrors: ['ResizeObserver loop limit exceeded'],
            integrations: [
                new Integrations.BrowserTracing({ routingInstrumentation: Sentry.reactRouterV5Instrumentation(history) }),
            ],
        });
    }
};


export default initializeSentry;
