import { createReducer } from '@reduxjs/toolkit';

import { FETCH_COUNTRIES, FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_SUCCESS } from './actions.types';
import { CLEAR_CURRENT_USER } from '../current-user/actions.types';
import { updateStandardLists, updateStandardListsOnFailure, updateStandardListsOnSuccess } from '../standardLists.helpers';


export const countriesReducerName = 'countries';
export const countriesListsPerLocationStoreKeyName = 'countriesListsPerLocation';

export const countriesInitialState = {
    [countriesListsPerLocationStoreKeyName]: {},
    loading: false,
};

const createCountriesBuilderCases = (builder) =>
    builder
        .addCase(FETCH_COUNTRIES, (state, action) => {
            state[countriesListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[countriesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_COUNTRIES_SUCCESS, (state, action) => {
            state[countriesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[countriesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_COUNTRIES_FAILURE, (state, action) => {
            state[countriesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[countriesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return countriesInitialState;
        });

export default createReducer(countriesInitialState, createCountriesBuilderCases);
