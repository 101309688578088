import { PermissionName } from 'models/domain/permissions';


export enum RedirectionToDefaultPathTypes {
    ROUTER_REDIRECTION_TO_DEFAULT_PATH = 'ROUTER_REDIRECTION_TO_DEFAULT_PATH',
    STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_MAIN_TAB_REPLACEMENT',
    STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT = 'STANDARD_TABS_ACTIVE_SUB_TAB_REPLACEMENT',
}

export enum LocationChangeActionScopeTypes {
    TAB_CHANGE = 'TAB_CHANGE',
    PAGE_CHANGE = 'PAGE_CHANGE',
}

export enum AnimationType {
    slideLeft = 'slideLeft',
    slideRight = 'slideRight',
}


export interface RouteConfiguration {
    path: string
    // element: React.ReactElement
    element: any
    hasPermission?: PermissionName
    hasPermissions?: PermissionName[]
    hasOneOfPermissions?: PermissionName[]
    isIndex?: boolean
    childrenRoutes?: any[]
}


export default {
    AnimationType,
    RedirectionToDefaultPathTypes,
};
