import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { createBusinessUserDetailsPath } from 'config/routes.helpers';

import { showToast } from 'store/application/actions';

import { AddBusinessUserFormSteps } from 'components/pages/authorised/AddBusinessUserPage/AddBusinessUserPage.types';

import {
    ADD_BUSINESS_USER,
    FETCH_BUSINESS_USERS_LIST,
} from './action.types';
import {
    addBusinessUserFailure,
    addBusinessUserSuccess,
    fetchBusinessUsersList,
    fetchBusinessUsersListFailure,
    fetchBusinessUsersListSuccess,
} from './actions';
import { createCreateBusinessUserErrorMessage } from './epics.helpers';
import { businessUsersReducerName, businessUsersListsPerLocationStoreKeyName } from './reducer';
import { addCompanyIdToQueryParams } from '../common.helpers';
import { requestSetMultiStepsFormStep, setNewEntityLocation } from '../multi-steps-form/actions';


export const onFetchBusinessUsersList: Epic = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_BUSINESS_USERS_LIST),
    switchMap(({
        payload: {
            locationPathname,
            queryParams,
        },
    }) => from(companies.getCompanyBusinessUsers(addCompanyIdToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => of(fetchBusinessUsersListSuccess({
            items: response.data?.rows,
            totalCount: response?.data?.count,
            locationPathname,
        }))),
        catchError(() => of(fetchBusinessUsersListFailure({ locationPathname }))),
    )),
);

export const onAddBusinessUser: Epic = (action$, state$, { companies, i18n }) => action$.pipe(
    ofType(ADD_BUSINESS_USER),
    switchMap(({ payload }) => from(companies.createBusinessUsersForCompany(payload.formData))
        .pipe(
            switchMap((response) => {
                const responseData = response.data;
                const currentEmployeesListQueryParams = state$.value[businessUsersReducerName][businessUsersListsPerLocationStoreKeyName]?.[payload.locationPathname]?.queryParams;

                const commonActions = [
                    setNewEntityLocation(createBusinessUserDetailsPath(responseData.businessUserId)),
                    addBusinessUserSuccess({ responsePayload: responseData, locationPathname: payload.locationPathname }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('businessUsers:actionMessages.addEmployeeSuccess'),
                    }),
                    requestSetMultiStepsFormStep(
                        AddBusinessUserFormSteps.REVIEW,
                        MultiStepFormStepChangeDirections.FORWARD,
                    ),
                ];
                if (currentEmployeesListQueryParams) {
                    return of(
                        fetchBusinessUsersList({
                            queryParams: currentEmployeesListQueryParams,
                            locationPathname: payload.locationPathname,
                        }),
                        ...commonActions,
                    );
                }
                return of(
                    ...commonActions,
                );
            }),
            catchError((error) => of(
                showToast({
                    type: ToastType.error,
                    message: createCreateBusinessUserErrorMessage(i18n.t, error),
                    id: 'network',
                }),
                addBusinessUserFailure(),
            )),
        )),
);


export default [
    onAddBusinessUser,
    onFetchBusinessUsersList,
];
