import { globalLocation } from '@manigo/manigo-commons';
import { CountryListItem, CurrencyListItem } from '@manigo/manigo-domain-typings';
import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import withAccessControl from 'hoc/withAccessControl';

import { authorisationReducerName } from 'store/authorisation/reducer';
import { companyDetailsPerLocationStoreKeyName, companyReducerName } from 'store/company/reducer';
import { countriesListsPerLocationStoreKeyName, countriesReducerName } from 'store/countries/reducer';
import { currenciesListsPerLocationStoreKeyName, currenciesReducerName } from 'store/currencies/reducer';
import { localesReducerName } from 'store/locales/reducer';
import { RootState } from 'store/rootReducer';
import { getStandardEntityDetailsData } from 'store/standardEntityDetails.helpers';
import { getStandardListData } from 'store/standardLists.helpers';

import AppLoadingPlaceholder from './AppLoadingPlaceholder.layout';
import { AppLoadingPlaceholderOwnProps, AppLoadingPlaceholderStateProps } from './AppLoadingPlaceholder.types';


const mapStateToProps = (state: RootState): AppLoadingPlaceholderStateProps => ({
    currencies: getStandardListData<CurrencyListItem>({
        state,
        reducerName: currenciesReducerName,
        listsByLocationStoreKeyName: currenciesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    countries: getStandardListData<CountryListItem>({
        state,
        reducerName: countriesReducerName,
        listsByLocationStoreKeyName: countriesListsPerLocationStoreKeyName,
        useGlobalLocation: true,
    })?.items,
    companyDetails: getStandardEntityDetailsData({
        state,
        reducerName: companyReducerName,
        fieldName: companyDetailsPerLocationStoreKeyName,
        customLocation: globalLocation,
    }),
    isI18nReady: state[localesReducerName].isI18nReady,
    isRefreshingSession: state[authorisationReducerName].isRefreshingSession,
});


const decorate = compose<ComponentType<AppLoadingPlaceholderOwnProps>>(
    withTranslation('common'),
    withAccessControl,
    connect(mapStateToProps),
);


export default decorate(AppLoadingPlaceholder);
