import { NumericId, CreateScaChallengeAction, SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    CREATE_SCA_CHALLENGE, CREATE_SCA_CHALLENGE_SUCCESS, CREATE_SCA_CHALLENGE_FAILURE,
    SUBMIT_SCA_CHALLENGE,
    DELETE_SCA_CHALLENGE, DELETE_SCA_CHALLENGE_FAILURE, DELETE_SCA_CHALLENGE_SUCCESS, CLEAR_SCA_CHALLENGE,
} from './actions.types';


export const createScaChallenge = createAction(CREATE_SCA_CHALLENGE, (requestPayload: CreateScaChallengeAction) => {
    return { payload: requestPayload };
});
export const createScaChallengeSuccess = createAction(CREATE_SCA_CHALLENGE_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});

export const createScaChallengeFailure = createAction(CREATE_SCA_CHALLENGE_FAILURE);

export const submitScaChallenge = createAction(SUBMIT_SCA_CHALLENGE, (requestPayload: SubmitScaChallengeActionPayload) => {
    return { payload: requestPayload };
});

export const deleteScaChallenge = createAction(DELETE_SCA_CHALLENGE, (challengeId: NumericId) => {
    return { payload: challengeId };
});
export const deleteScaChallengeSuccess = createAction(DELETE_SCA_CHALLENGE_SUCCESS);
export const deleteScaChallengeFailure = createAction(DELETE_SCA_CHALLENGE_FAILURE);

export const clearScaChallenge = createAction(CLEAR_SCA_CHALLENGE);

