import { getPropObj } from '@manigo/manigo-commons';
import React from 'react';
import { connect } from 'react-redux';

import { AllEntityTypes, ReasonsForEntityIsReadOnly } from 'models/app/applicationState';
import { WithAccessControl } from 'models/domain/accessControl';
import { PermanentCardStatusesList } from 'models/domain/cards';

import { currentUserReducerName } from 'store/current-user/reducer';
import { RootState } from 'store/rootReducer';


const mapPermissionNameToObjectPath = (permissionName): string => permissionName.replace(/:/g, '.');


function createAccessControlObject(state: RootState): WithAccessControl {
    const permissions = state[currentUserReducerName].permissions || { };
    const { clientConfig } = state[currentUserReducerName];


    return {
        accessControl: {
            isAuthorised: !!state[currentUserReducerName].jwtToken,
            clientConfig,

            hasPermission: (permissionName): boolean => {
                const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                const permissionValue = getPropObj(permissions, permissionNameAsPath);
                return permissionValue === true;
            },
            hasPermissions: (permissionNames): boolean => {
                return permissionNames.every((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = getPropObj(permissions, permissionNameAsPath);
                    return permissionValue === true;
                });
            },

            hasOneOfPermissions: (permissionNames): boolean => {
                return permissionNames.some((permissionName) => {
                    const permissionNameAsPath = mapPermissionNameToObjectPath(permissionName);
                    const permissionValue = getPropObj(permissions, permissionNameAsPath);
                    return permissionValue === true;
                });
            },
            isEntityReadOnly: (entityType, entityStatus) => {
                const handlers = [
                    {
                        predicate: () => entityType === AllEntityTypes.CARD && PermanentCardStatusesList.includes(entityStatus),
                        handler: () => ReasonsForEntityIsReadOnly.CARD_PERMANENT_STATUS,
                    },
                    {
                        predicate: () => true, // default
                        handler: () => false, // isEntityReadOnly -> false
                    },
                ];

                return handlers.filter(({ predicate }) => predicate())[0].handler();
            },
        },

    };
}


export default function withAccessControl(WrappedComponent: React.ComponentType) {
    function AccessControlInjectorComponent(props) {
        return <WrappedComponent {...props} />;
    }

    const mapStateToProps = (state: RootState) => createAccessControlObject(state);

    return connect(mapStateToProps)(AccessControlInjectorComponent);
}
