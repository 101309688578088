import { ButtonProps } from '@chakra-ui/react';
import { LocationChangeRequest, LocationPathname } from '@manigo/manigo-commons';
import { NumericId, Uuid } from '@manigo/manigo-domain-typings';
import { JSX } from 'react';
import { IconType } from 'react-icons';

import { SortDirections } from 'models/app/applicationState';

import { StandardTooltipOwnProps } from 'components/common/atoms/StandardTooltip/StandardTooltip.types';


export enum CommonMimeTypes {
    PDF = 'application/pdf',
    JSON = 'application/json',
}

export interface ButtonConfiguration extends ButtonProps {
    icon?: IconType;
    label?: string | JSX.Element;
    dataTestId: string;
    id: string;
    tooltipTitle?: StandardTooltipOwnProps['title'];
    tooltipPlacement?: StandardTooltipOwnProps['placement'];
    isIconButton?: boolean;
}

export interface IdQueryParams {
    accountId?: NumericId,
    cardId?: NumericId,
    companyId?: NumericId,
    beneficiaryUuid?: Uuid,
    businessUserId?: NumericId,
    transactionUuid?: Uuid
}

export interface QueryParams extends IdQueryParams {
    limit: number,
    offset: number,
    search?: string,
    sortBy?: string,

    sortOrder?: SortDirections,
    filters?: any,
    withTotal?: boolean,
    skipImages?: boolean
}

export interface LocationAndQueryParams {
    locationPathname: LocationPathname
    queryParams: QueryParams | IdQueryParams
}

export interface QueryParamsMeta {
    timestamp: number,
    location: LocationChangeRequest,
}

export interface PredicateHandler {
    predicate: () => boolean,
    handler: () => any,
}

export const defaultMinLength = 3;
export const defaultMaxLength = 50;
export const cardTokenLength = 9;
export const cardTokenRegex = /^[0-9]{9}$/;

