import { ActionReducerMapBuilder, createReducer } from '@reduxjs/toolkit';

import { ClearEntityDetailsPayload } from 'models/app/standardEntityDetails';
import { Action } from 'models/meta/action';

import { addLoadingState } from 'utils/redux-tools';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_ACCOUNT_DETAILS,
    DELETE_ACCOUNT,
    DELETE_ACCOUNT_FAILURE,
    DELETE_ACCOUNT_SUCCESS,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, DownloadAccountStatementPdfFailurePayload, DownloadAccountStatementPdfPayload, DownloadAccountStatementPdfSuccessPayload,
    FETCH_ACCOUNT_DETAILS,
    FETCH_ACCOUNT_DETAILS_FAILURE,
    FETCH_ACCOUNT_DETAILS_SUCCESS, FetchAccountDetailsFailurePayload, FetchAccountDetailsPayload, FetchAccountDetailsSuccessPayload,
    HIDE_DOWNLOAD_PDF_STATEMENT_FORM, HideDownloadPdfStatementFormPayload,
    SET_AS_PRIMARY_ACCOUNT,
    SET_AS_PRIMARY_ACCOUNT_FAILURE,
    SET_AS_PRIMARY_ACCOUNT_SUCCESS, SetAccountDetailsQueryParamsPayload,
    SHOW_DOWNLOAD_PDF_STATEMENT_FORM, ShowDownloadPdfStatementFormPayload,
    UPDATE_ACCOUNT_NAME,
    UPDATE_ACCOUNT_NAME_FAILURE,
    UPDATE_ACCOUNT_NAME_SUCCESS,
} from './actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from '../standardEntityDetails.helpers';


export const accountReducerName = 'account';
export const accountDetailsPerLocationStoreKeyName = 'accountDetailsPerLocation';


const initialState = {
    [accountDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    downloadPdfStatement: {},
    isLoadingDeleteAccount: false,
    isLoadingSetAsPrimaryAccount: false,
    isLoadingUpdateAccountName: false,
};

export type AccountState = typeof initialState;


const createAccountBuilderCases = (builder:ActionReducerMapBuilder<AccountState>) => {
    builder
        .addCase(FETCH_ACCOUNT_DETAILS, (state: AccountState, action: Action<FetchAccountDetailsPayload>) => {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_ACCOUNT_DETAILS_SUCCESS, (state: AccountState, action: Action<FetchAccountDetailsSuccessPayload>) => {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_ACCOUNT_DETAILS_FAILURE, (state: AccountState, action: Action<FetchAccountDetailsFailurePayload>) => {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_ACCOUNT_DETAILS, (state: AccountState, action: Action<ClearEntityDetailsPayload>) => {
            state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[accountDetailsPerLocationStoreKeyName],
                action,
            });
        })

        .addCase(SET_QUERY_PARAMS, (state: AccountState, action: Action<SetAccountDetailsQueryParamsPayload>) => {
            if (action.payload?.reducerName === accountReducerName
                && action.payload?.fieldName === accountDetailsPerLocationStoreKeyName
            ) {
                state[accountDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[accountDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(SHOW_DOWNLOAD_PDF_STATEMENT_FORM, (state: AccountState, action: Action<ShowDownloadPdfStatementFormPayload>) => {
            const { accountId } = action.payload;
            state.downloadPdfStatement = {
                ...state.downloadPdfStatement,
                [accountId]: {
                    ...state.downloadPdfStatement[accountId],
                    isFormVisible: true,
                    isDownloading: false,
                },
            };
        })
        .addCase(HIDE_DOWNLOAD_PDF_STATEMENT_FORM, (state: AccountState, action: Action<HideDownloadPdfStatementFormPayload>) => {
            const { accountId } = action.payload;
            state.downloadPdfStatement = {
                ...state.downloadPdfStatement,
                [accountId]: {
                    ...state.downloadPdfStatement[accountId],
                    isFormVisible: false,
                },
            };
        })
        .addCase(DOWNLOAD_ACCOUNT_STATEMENT_PDF, (state: AccountState, action: Action<DownloadAccountStatementPdfPayload>) => {
            const { accountId } = action.payload.queryParams;
            state.downloadPdfStatement = {
                ...state.downloadPdfStatement,
                [accountId]: {
                    ...state.downloadPdfStatement[accountId],
                    isDownloading: true,
                },
            };
        })
        .addCase(DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS, (state: AccountState, action: Action<DownloadAccountStatementPdfSuccessPayload>) => {
            const { accountId } = action.payload;
            state.downloadPdfStatement = {
                ...state.downloadPdfStatement,
                [accountId]: {
                    ...state.downloadPdfStatement[accountId],
                    isDownloading: false,
                    isFormVisible: false,
                },
            };
        })
        .addCase(DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE, (state: AccountState, action: Action<DownloadAccountStatementPdfFailurePayload>) => {
            const { accountId } = action.payload;
            state.downloadPdfStatement = {
                ...state.downloadPdfStatement,
                [accountId]: {
                    ...state.downloadPdfStatement[accountId],
                    isDownloading: false,
                },
            };
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        });
    addLoadingState([DELETE_ACCOUNT, DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_FAILURE], 'isLoadingDeleteAccount', builder);
    addLoadingState([SET_AS_PRIMARY_ACCOUNT, SET_AS_PRIMARY_ACCOUNT_SUCCESS, SET_AS_PRIMARY_ACCOUNT_FAILURE], 'isLoadingSetAsPrimaryAccount', builder);
    addLoadingState([UPDATE_ACCOUNT_NAME, UPDATE_ACCOUNT_NAME_SUCCESS, UPDATE_ACCOUNT_NAME_FAILURE], 'isLoadingUpdateAccountName', builder);
};

export default createReducer(initialState, createAccountBuilderCases);
