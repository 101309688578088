import { SortDirectionsAPI } from '@manigo/manigo-commons';


export const defaultClipboardContent = '';
export const tabKeySubTabKeySeparator = '🖤';

export enum ApplicationState {
    APPLICATION_STARTED = 'APPLICATION_STARTED',
    UNAUTHORISED_READY = 'UNAUTHORISED_READY',
    AUTHORISED_ONBOARDING = 'AUTHORISED_ONBOARDING',
    AUTHORISED_READY = 'AUTHORISED_READY',
}
export enum HTMLButtonType {
    button = 'button',
    submit = 'submit',
    reset = 'reset',
}


export enum SortDirections {
    DESCEND = 'desc',
    ASCEND = 'asc',
}

export enum DetailsScreensNames {
    cardDetails = 'cardDetails',
    companyDetails = 'companyDetails',
    beneficiaryDetails = 'beneficiaryDetails',
    employeeDetails = 'employeeDetails',
    userDetails = 'userDetails',
    myProfile = 'myProfile',
}


export enum StandardFilterTypes {
    SEARCH = 'search',
    DROPDOWN = 'dropdown',
    CHECKBOX = 'checkbox',
}

export enum ReasonsForEntityIsReadOnly {
    CARD_PERMANENT_STATUS = 'CARD_PERMANENT_STATUS',
    ENTITY_IS_OFFBOARDING = 'ENTITY_IS_OFFBOARDING',
    ENTITY_IS_OFFBOARDED = 'ENTITY_IS_OFFBOARDED',
    PARENT_ENTITY_IS_READ_ONLY = 'PARENT_ENTITY_IS_READ_ONLY',
}

export enum ReasonsForBlockedNavigation {
    NOT_PRISTINE_ORDER_CARD_FORM = 'NOT_PRISTINE_ORDER_CARD_FORM',
    NOT_PRISTINE_ADD_BENEFICIARY_FORM = 'NOT_PRISTINE_ADD_BENEFICIARY_FORM',
    NOT_PRISTINE_EDIT_BENEFICIARY_FORM = 'NOT_PRISTINE_EDIT_BENEFICIARY_FORM',
    NOT_PRISTINE_INTERNAL_TRANSFER_FORM = 'NOT_PRISTINE_INTERNAL_TRANSFER_FORM',
    NOT_PRISTINE_ADD_BANK_TRANSFER_FORM = 'NOT_PRISTINE_ADD_BANK_TRANSFER_FORM',
    NOT_PRISTINE_EXCHANGE_FORM = 'NOT_PRISTINE_EXCHANGE_FORM',
    NOT_PRISTINE_SIGN_UP_FORM = 'NOT_PRISTINE_SIGN_UP_FORM',
}

export type BlockedNavigationReason = typeof ReasonsForBlockedNavigation;

export interface DefaultQueryParamsMeta {
    location?: Location,
    timestamp?: number,
}


export const defaultQueryParamsMeta: DefaultQueryParamsMeta = {
    location: undefined,
    timestamp: undefined,
};

export function updateQueryParamsMeta(
    clearTimestamp = false,
    clearLocation = false,
): DefaultQueryParamsMeta {
    return {
        location: clearLocation ? undefined : window.location,
        timestamp: clearTimestamp ? undefined : Date.now(),
    };
}

export function normaliseSortOrderValue(
    sortOrder = '',
) {
    return sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC;
}


export enum AllEntityTypes {
    ACCOUNT = 'ACCOUNT',
    BENEFICIARY = 'BENEFICIARY',
    BUSINESS_USER = 'BUSINESS_USER',
    BUSINESS_SHAREHOLDER = 'BUSINESS_SHAREHOLDER',
    CARD = 'CARD',
    CLIENT = 'CLIENT',
    COMPANY = 'COMPANY',
    FEE = 'FEE',
    GROUP = 'GROUP',
    TRANSACTION = 'TRANSACTION',
    PROMOTION = 'PROMOTION',
    ROLE = 'ROLE',
    PERMISSION = 'PERMISSION',
}


export default {
    ApplicationState,
    StandardFilterTypes,
    SortDirections,
    DetailsScreensNames,
    ReasonsForBlockedNavigation,
    defaultQueryParamsMeta,
    defaultClipboardContent,
    tabKeySubTabKeySeparator,
};
