import { Language, Namespace, enGbLocale } from '@manigo/manigo-commons';

import { SUPPORTED_LANGUAGES } from './environment';


export const availableLanguagesLanguageNativeNames = SUPPORTED_LANGUAGES?.reduce((acc, curr) => {
    acc[curr.key] = curr.label;
    return acc;
}, {});

export const availableLocales: Language[] = Object.keys(availableLanguagesLanguageNativeNames) ?? [enGbLocale];

export const onboardingNamespaces: Namespace[] = [
    'onboarding',
    'stakeholders',
    'common',
];

export const authorisedNamespaces: Namespace[] = [
    'accounts',
    'beneficiaries',
    'businessUsers',
    'cards',
    'company',
    'payments',
    'transactions',
    'currencies',
    'faq',
    'sca',
];

export const unauthorisedNamespaces: Namespace[] = [
    'unauthorised',
    'countries',
    'termsOfService',
    'privacyPolicy',
];
