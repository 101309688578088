import { HttpService } from 'services/http';


export const getBusinessUserIdDetails = (businessUserId) => HttpService.get(`/v3/business-users/${businessUserId}`);


export const updateBusinessUserId = (businessUserId, requestPayload) => HttpService.patch(`/v3/business-users/${businessUserId}`, requestPayload, { noErrorHandling: true });

export const updateBusinessUserIdPassword = (businessUserId, requestPayload) => HttpService.post(`/v3/business-users/${businessUserId}/password`,
    requestPayload,
    { noErrorHandling: true });
export const createBusinessUserIdPassword = (companyId, requestPayload) => HttpService.post(`/v3/companies/${companyId}/business-user`, requestPayload);
export const updateBusinessUserStatusAPI = (businessUserId, requestPayload) => HttpService.put(`/v3/business-users/${businessUserId}/status`, requestPayload);
