import { globalLocation } from '@manigo/manigo-commons';
import { createAction } from '@reduxjs/toolkit';


import { FETCH_CURRENCIES, FETCH_CURRENCIES_FAILURE, FETCH_CURRENCIES_SUCCESS } from './actions.types';


export const fetchCurrencies = createAction(FETCH_CURRENCIES, (queryParams = {}, locationPathname = globalLocation) => ({ payload: { queryParams, locationPathname } }));
export const fetchCurrenciesSuccess = createAction(FETCH_CURRENCIES_SUCCESS, (items, totalCount, locationPathname) => ({ payload: { items, totalCount, locationPathname } }));
export const fetchCurrenciesFailure = createAction(FETCH_CURRENCIES_FAILURE, (locationPathname) => ({ payload: { locationPathname } }));
