import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_ALL_FORM_DATA,
    CLEAR_FORM_CONTEXT, CLEAR_FORM_STEP_DATA,
    CLEAR_MULTI_STEPS_FORM_META_DATA,
    REQUEST_SET_MULTI_STEPS_FORM_STEP, SET_FORM_STEP_DATA,
    SET_MULTI_STEPS_FORM_META_DATA,
    SET_MULTI_STEPS_FORM_STEP, SET_NEW_ENTITY_LOCATION, UPDATE_FORM_CONTEXT,
} from './actions.types';


export const setMultiStepsFormMetaData = createAction(SET_MULTI_STEPS_FORM_META_DATA, (formMetaData) => ({ payload: formMetaData }));
export const clearMultiStepsFormMetaData = createAction(CLEAR_MULTI_STEPS_FORM_META_DATA);

export const requestSetMultiStepsFormStep = createAction(
    REQUEST_SET_MULTI_STEPS_FORM_STEP,
    (currentStep, direction) => ({ payload: { currentStep, direction } }),
);
export const setMultiStepsFormStep = createAction(SET_MULTI_STEPS_FORM_STEP, (newCurrentStep) => ({ payload: { newCurrentStep } }));


export const updateFormContext = createAction(UPDATE_FORM_CONTEXT, (newData) => ({ payload: newData }));
export const setNewEntityLocation = createAction(SET_NEW_ENTITY_LOCATION, (location: string) => ({ payload: location }));

export const clearFormContext = createAction(CLEAR_FORM_CONTEXT);
export const setFormStepData = createAction(SET_FORM_STEP_DATA, ({
    stepName,
    stepValues,
}: { stepName: string, stepValues: object }) => ({ payload: { stepName, stepValues } }));
export const clearFormStepData = createAction(CLEAR_FORM_STEP_DATA, (stepName: string) => ({ payload: stepName }));

export const clearAllFormData = createAction(CLEAR_ALL_FORM_DATA);
