import { FetchBeneficiaryRequirementsErrors } from 'models/domain/benefciaries';


const getFetchBeneficiaryRequirements = (errorMessage, t) => {
    const errorMessageHandlers = [{
        predicate: () => errorMessage === FetchBeneficiaryRequirementsErrors.WRONG_COMBINATION,
        handler: () => t('beneficiaries:actionMessages.fetchRequirementsWrongCombinationFailure'),
    },
    {
        predicate: () => true,
        handler: () => t('unauthorised:genericApiError'),
    },
    ];

    return errorMessageHandlers.find(({ predicate }) => predicate())?.handler() || '';
};
export default getFetchBeneficiaryRequirements;
