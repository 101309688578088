import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    FETCH_KYB_ACCESS_TOKEN,
    FETCH_KYB_ACCESS_TOKEN_FAILURE,
    FETCH_KYB_ACCESS_TOKEN_SUCCESS,
    FETCH_KYC_ACCESS_TOKEN,
    FETCH_KYC_ACCESS_TOKEN_FAILURE,
    FETCH_KYC_ACCESS_TOKEN_SUCCESS,
} from './actions.types';


export const kybKycReducerName = 'kybKyc';
export interface KybKycStateType {
    readonly isLoadingKybAccessToken: boolean;
    readonly isLoadingKycAccessToken: boolean;
    readonly kybAccessToken?: string,
    readonly kycAccessToken?: string,

}

const initialState: KybKycStateType = {
    isLoadingKybAccessToken: false,
    isLoadingKycAccessToken: false,
    kybAccessToken: undefined,
    kycAccessToken: undefined,
};

const createKybKycBuilderCases = (builder) =>
    builder.addCase(FETCH_KYB_ACCESS_TOKEN, (state) => {
        state.isLoadingKybAccessToken = true;
    })
        .addCase(FETCH_KYB_ACCESS_TOKEN_SUCCESS, (state, action) => {
            state.isLoadingKybAccessToken = false;
            state.kybAccessToken = action.payload;
        })
        .addCase(FETCH_KYB_ACCESS_TOKEN_FAILURE, (state) => {
            state.isLoadingKybAccessToken = false;
            state.kybAccessToken = undefined;
        })
        .addCase(FETCH_KYC_ACCESS_TOKEN, (state) => {
            state.isLoadingKycAccessToken = true;
        })
        .addCase(FETCH_KYC_ACCESS_TOKEN_SUCCESS, (state, action) => {
            state.isLoadingKycAccessToken = false;
            state.kycAccessToken = action.payload;
        })
        .addCase(FETCH_KYC_ACCESS_TOKEN_FAILURE, (state) => {
            state.isLoadingKycAccessToken = false;
            state.kycAccessToken = undefined;
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        });

export default createReducer(initialState, createKybKycBuilderCases);
