import { getCurrenciesAPI } from './index';


class CurrenciesService {
    public fetchCurrencies(rawQueryParams = { skipImages: true }) {
        const { skipImages } = rawQueryParams;

        return getCurrenciesAPI({ params: { skipImages } });
    }
}

export default CurrenciesService;
