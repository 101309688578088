import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { showToast } from 'store/application/actions';
import { hideModal } from 'store/modal/actions';

import {
    addAccountFailure,
    addAccountSuccess,
    fetchAccountsListSuccess,
    fetchAccountsListFailure,
    fetchAccountsList,
} from './actions';
import { ADD_ACCOUNT, FETCH_ACCOUNTS_LIST } from './actions.types';
import { accountsListsPerLocationStoreKeyName, accountsReducerName } from './reducer';
import { addCompanyIdToQueryParams } from '../common.helpers';


export const onAddAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(ADD_ACCOUNT),
    switchMap(({ payload: { requestPayload, locationPathname } }) => from(accounts.createNewAccount(requestPayload))
        .pipe(switchMap((response) => {
            const currentAccountListQueryParams = state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[locationPathname]?.queryParams;

            return of(
                addAccountSuccess(response.data),
                hideModal(),
                fetchAccountsList({ queryParams: currentAccountListQueryParams, locationPathname }),
                showToast({
                    type: ToastType.success,
                    message: i18n.t('accounts:actionMessages.addNewAccountSuccess'),
                    id: 'add-account',
                }),
            );
        }),
        catchError(() => of(addAccountFailure())))),
);


export const onFetchAccountsList: Epic = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNTS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(accounts.getAccountsList(addCompanyIdToQueryParams({ state$, queryParams })))
        .pipe(
            switchMap((response) => (
                queryParams?.withTotal
                && response?.data?.count >= 0
                    ? of(fetchAccountsListSuccess({
                        items: response.data.rows,
                        totalCount: response?.data.count,
                        locationPathname,
                    }))
                    : of(fetchAccountsListSuccess({
                        items: response.data,
                        totalCount: response?.data?.length,
                        locationPathname,
                    })))),
            catchError(() => of(fetchAccountsListFailure(locationPathname))),
        )),
);


export default [
    onFetchAccountsList,
    onAddAccount,
];
