export const CREATE_SCA_CHALLENGE = 'CREATE_SCA_CHALLENGE';
export const CREATE_SCA_CHALLENGE_SUCCESS = 'CREATE_SCA_CHALLENGE_SUCCESS';
export const CREATE_SCA_CHALLENGE_FAILURE = 'CREATE_SCA_CHALLENGE_FAILURE';

export const SUBMIT_SCA_CHALLENGE = 'SUBMIT_SCA_CHALLENGE';

export const DELETE_SCA_CHALLENGE = 'DELETE_SCA_CHALLENGE';
export const DELETE_SCA_CHALLENGE_SUCCESS = 'DELETE_SCA_CHALLENGE_SUCCESS';
export const DELETE_SCA_CHALLENGE_FAILURE = 'DELETE_SCA_CHALLENGE_FAILURE';


export const CLEAR_SCA_CHALLENGE = 'CLEAR_SCA_CHALLENGE';
