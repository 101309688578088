import { createAction } from '@reduxjs/toolkit';

import { MARK_AS_UPDATED_LIST, UPDATE_LIST } from './actions.types';


export const updateList = createAction(UPDATE_LIST, ({
    findByPropName,
    findByPropValue,
    listReducerName,
}) => ({
    payload: {
        findByPropName,
        findByPropValue,
        listReducerName,
    },
}));

export const markAsUpdatedList = createAction(MARK_AS_UPDATED_LIST,
    ({ locationPathname, entityReducerName }) => ({ payload: { locationPathname, entityReducerName } }));
