import { TFunction, ApiError } from '@manigo/manigo-commons';


export const createActivateCardErrorMessage = (t: TFunction, error: ApiError) => {
    const handlers = [
        {
            predicate: () => error.message === 'Invalid activation token',
            handler: () => (t('cards:actionMessages.invalidActivationToken')),
        },
        {
            predicate: () => true,
            handler: () => (t('unauthorised:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
