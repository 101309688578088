import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import React from 'react';
import rtl from 'stylis-plugin-rtl';


const options = {
    rtl: { key: 'css-ar', stylisPlugins: [rtl] },
    ltr: { key: 'css-en' },
};

export function RtlProvider({ dir, children }) {
    const cache = createCache(options[dir]);
    return <CacheProvider value={cache}>{children}</CacheProvider>;
}
