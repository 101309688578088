import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { addBeneficiaryRootRoutePath } from 'config/routes';
import { createBeneficiaryDetailsPath } from 'config/routes.helpers';

import { showToast } from 'store/application/actions';
import { addCompanyIdToQueryParams } from 'store/common.helpers';
import { currentUserReducerName } from 'store/current-user/reducer';
import { requestSetMultiStepsFormStep, setNewEntityLocation } from 'store/multi-steps-form/actions';

import { AddBeneficiaryFormSteps } from 'components/pages/authorised/AddEditBeneficiary/AddBeneficiaryPage/AddBeneficiaryPage.types';

import { ADD_BENEFICIARY, FETCH_BENEFICIARIES_LIST, FETCH_BENEFICIARY_REQUIREMENTS } from './action.types';
import {
    addBeneficiaryFailure,
    addBeneficiarySuccess,
    fetchBeneficiariesListFailure,
    fetchBeneficiariesListSuccess, fetchBeneficiaryRequirementsFailure, fetchBeneficiaryRequirementsSuccess,
} from './actions';
import getFetchBeneficiaryRequirements from './epics.helpers';


export const onFetchBeneficiariesList: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARIES_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(beneficiaries.fetchBeneficiaries(addCompanyIdToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => of(fetchBeneficiariesListSuccess({
            items: response.data.rows,
            totalCount: response?.data.count,
            locationPathname,
        }))),
        catchError(() => of(fetchBeneficiariesListFailure({ locationPathname }))),
    )),
);
export const onFetchBeneficiaryRequirements: Epic = (action$, state$, { beneficiaries, i18n: { t } }) => action$.pipe(
    ofType(FETCH_BENEFICIARY_REQUIREMENTS),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(beneficiaries.fetchBeneficiaryRequirements(queryParams)).pipe(
        switchMap((response) => {
            return of(
                fetchBeneficiaryRequirementsSuccess(`${queryParams?.beneficiaryCountry}_${queryParams?.beneficiaryCurrency}`, response?.data),
                // TODO edit condition when implementing edit beneficiary
                ...(locationPathname === addBeneficiaryRootRoutePath
                    ? [requestSetMultiStepsFormStep(AddBeneficiaryFormSteps.BASIC_DATA, MultiStepFormStepChangeDirections.FORWARD)]
                    : []),
            );
        }),
        catchError((response) => {
            return of(
                fetchBeneficiaryRequirementsFailure(),
                showToast({
                    message: getFetchBeneficiaryRequirements(response?.data?.message, t),
                    type: ToastType.error,
                }),
            );
        }),
    )),
);

export const onAddBeneficiary: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(ADD_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.createBeneficiary(
        {
            ...payload.requestPayload,
            companyId: state$.value[currentUserReducerName].userData?.identifiers?.companyId,
        },
    )).pipe(
        switchMap((response) => {
            const pathname = payload.locationPathname;
            // eslint-disable-next-line max-len
            // TODO: looks like leftover from list refresh, investigate ... remove or restore
            // const currentBeneficiariesListQueryParams = state$.value[beneficiariesReducerName][beneficiariesListsPerLocationStoreKeyName]?.[cardsListRootRoutePath]?.queryParams;

            return of(
                addBeneficiarySuccess({ locationPathname: pathname, requestPayload: payload }),
                setNewEntityLocation(createBeneficiaryDetailsPath(response?.data?.id)),
                requestSetMultiStepsFormStep(
                    AddBeneficiaryFormSteps.REVIEW,
                    MultiStepFormStepChangeDirections.FORWARD,
                ),
            );
        }),
        catchError(({ locationPathname }) => of(addBeneficiaryFailure({ locationPathname }))),
    )),
);


export default [
    onFetchBeneficiariesList,
    onFetchBeneficiaryRequirements,
    onAddBeneficiary,
];
