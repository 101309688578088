import { formatDate, Action } from '@manigo/manigo-commons';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CommonMimeTypes } from 'models/app/common';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { accountsListRootRoutePath } from 'config/routes';

import { accountsListsPerLocationStoreKeyName, accountsReducerName } from 'store/accounts/reducer';
import { saveFile, showToast } from 'store/application/actions';
import { currentUserReducerName } from 'store/current-user/reducer';
import { updateList } from 'store/list/actions';
import { hideModal } from 'store/modal/actions';
import { requestNavigation } from 'store/navigation/actions';


import {
    deleteAccountSuccess,
    deleteAccountFailure,
    fetchAccountDetailsSuccess,
    fetchAccountDetailsFailure,
    hideDownloadPdfStatementForm,
    downloadAccountStatementPdfSuccess,
    downloadAccountStatementPdfFailure,
    setAsPrimaryAccountFailure,
    setAsPrimaryAccountSuccess,
    fetchAccountDetails,
    updateAccountNameSuccess,
    updateAccountNameFailure,
} from './actions';
import {
    DELETE_ACCOUNT, DeleteAccountPayload,
    DOWNLOAD_ACCOUNT_STATEMENT_PDF, DownloadAccountStatementPdfPayload,
    FETCH_ACCOUNT_DETAILS, FetchAccountDetailsPayload,
    SET_AS_PRIMARY_ACCOUNT, SetAsPrimaryAccountPayload,
    UPDATE_ACCOUNT_NAME, UpdateAccountNamePayload,
} from './actions.types';
import { accountDetailsPerLocationStoreKeyName, accountReducerName } from './reducer';


export const onFetchAccountDetails: Epic = (action$, state$, { accounts }) => action$.pipe(
    ofType(FETCH_ACCOUNT_DETAILS),
    switchMap(({ payload }: Action<FetchAccountDetailsPayload>) => from(accounts.getAccountDetails(payload.queryParams.accountId))
        .pipe(
            switchMap((response) => of(fetchAccountDetailsSuccess({
                responsePayload: response.data,
                locationPathname: payload.locationPathname,
            }))),
            catchError(() => of(fetchAccountDetailsFailure({ locationPathname: payload.locationPathname }))),
        )),
);

export const onDeleteAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(DELETE_ACCOUNT),
    switchMap(({ payload }: Action<DeleteAccountPayload>) => from(accounts.deleteAccount(payload.queryParams))
        .pipe(
            switchMap((response) => {
                return of(
                    requestNavigation({ locationPathname: accountsListRootRoutePath, meta: { replace: true } }),
                    deleteAccountSuccess({ responsePayload: response }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('accounts:actionMessages.deleteAccountSuccess'),
                    }),
                );
            }),
            catchError(() => of(deleteAccountFailure())),
        )),
);

export const onSetAsPrimaryAccount: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(SET_AS_PRIMARY_ACCOUNT),
    switchMap(({ payload }: Action<SetAsPrimaryAccountPayload>) => from(accounts.updateExistingAccount({ ...payload.queryParams, is_primary: true }))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentQueryParams = pathname === accountsListRootRoutePath
                    ? state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams
                    : state$.value[accountReducerName][accountDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                const commonActions = [
                    setAsPrimaryAccountSuccess(),
                    updateList({
                        findByPropName: 'id',
                        findByPropValue: payload.queryParams.accountId,
                        listReducerName: accountsReducerName,
                    }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('accounts:actionMessages.setAsPrimarySuccess'),
                    }),
                ];

                return of(
                    ...commonActions,
                    ...(
                        pathname !== accountsListRootRoutePath
                            ? [fetchAccountDetails({
                                queryParams: currentQueryParams,
                                locationPathname: pathname,
                            })]
                            : []
                    ),
                );
            }),
            catchError(() => of(
                setAsPrimaryAccountFailure(),
            )),
        )),
);

export const onUpdateAccountName: Epic = (action$, state$, { accounts, i18n }) => action$.pipe(
    ofType(UPDATE_ACCOUNT_NAME),
    switchMap(({ payload }: Action<UpdateAccountNamePayload>) => from(accounts.updateExistingAccount(payload.queryParams))
        .pipe(
            switchMap(() => {
                const pathname = payload.locationPathname;
                const currentQueryParams = pathname === accountsListRootRoutePath
                    ? state$.value[accountsReducerName][accountsListsPerLocationStoreKeyName]?.[pathname]?.queryParams
                    : state$.value[accountReducerName][accountDetailsPerLocationStoreKeyName]?.[pathname]?.queryParams;

                const commonActions = [
                    updateAccountNameSuccess(),
                    updateList({
                        findByPropName: 'id',
                        findByPropValue: payload.queryParams.accountId,
                        listReducerName: accountsReducerName,
                    }),
                    hideModal(),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('common:actionMsg.genericSuccessMessage.text'),
                    }),
                ];
                return of(
                    ...commonActions,
                    ...(
                        pathname !== accountsListRootRoutePath
                            ? [fetchAccountDetails({
                                queryParams: currentQueryParams,
                                locationPathname: pathname,
                            })]
                            : []
                    ),
                );
            }),
            catchError(() => of(
                updateAccountNameFailure(),
            )),
        )),
);


export const onDownloadAccountStatementPdf: Epic = (action$, state$, {
    reports,
    i18n,
}) => action$.pipe(
    ofType(DOWNLOAD_ACCOUNT_STATEMENT_PDF),
    switchMap(({ payload: { queryParams } }: Action<DownloadAccountStatementPdfPayload>) => from(reports.downloadTransactionStatement(queryParams))
        .pipe(
            switchMap((response) => {
                const { userPreferences } = state$.value[currentUserReducerName];
                const dateFormat = userPreferences?.dateFormat ?? 'DD-MM-YYYY';

                const formattedDateFrom = formatDate({
                    date: queryParams.dateFrom,
                    dateFormat,
                    convertFromUTC: false,
                })?.replace(/\D/g, '_');

                const formattedDateTo = formatDate({
                    date: queryParams.dateTo,
                    dateFormat,
                    convertFromUTC: false,
                })?.replace(/\D/g, '_');

                return of(
                    saveFile({
                        blob: response,
                        fileName: `Statement_${queryParams.currency}_account_#${queryParams.accountId}_${formattedDateFrom}-${formattedDateTo}`,
                        mimeType: CommonMimeTypes.PDF,
                    }),
                    showToast({
                        type: ToastType.success,
                        message: i18n.t('common:actionMsg.genericSuccessMessage.text'),
                    }),
                    downloadAccountStatementPdfSuccess({ accountId: queryParams.accountId }),
                    hideDownloadPdfStatementForm({ accountId: queryParams.accountId }),
                );
            }),
            catchError(() => {
                return of(downloadAccountStatementPdfFailure({ accountId: queryParams.accountId }));
            }),
        )),
);

export default [
    onFetchAccountDetails,
    onDeleteAccount,
    onSetAsPrimaryAccount,
    onUpdateAccountName,
    onDownloadAccountStatementPdf,
];

