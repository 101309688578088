import { ScaChallengeType, SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';

import { fetchCardSensitiveData } from 'store/card-sensitive-data/actions';
import { cardSensitiveDataReducerName } from 'store/card-sensitive-data/reducer';
import { confirmExchange } from 'store/exchange/actions';
import { exchangeReducerName } from 'store/exchange/reducer';
import { createPayment } from 'store/transaction/actions';
import { transactionReducerName } from 'store/transaction/reducer';

import { RootState } from '../rootReducer';


export const createScaIsLoadingStateSelector = (scaType) => {
    const handlers = [
        {
            predicate: () => [ScaChallengeType.TRX_OBT, ScaChallengeType.TRX_P2P].includes(scaType),
            handler: () => (state: RootState) => state[transactionReducerName].isLoadingCreatePayment,
        },
        {
            predicate: () => scaType === ScaChallengeType.TRX_EXC,
            handler: () => (state: RootState) => state[exchangeReducerName].isLoadingExchange,
        },
        {
            predicate: () => scaType === ScaChallengeType.CARD_SENS,
            handler: () => (state: RootState) => state[cardSensitiveDataReducerName].isLoadingFetchCardSensitiveData,
        },
        {
            predicate: () => true,
            handler: () => () => false,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createSubmitScaChallengeAction = (payload: SubmitScaChallengeActionPayload) => {
    const handlers = [
        {
            predicate: () => [ScaChallengeType.TRX_OBT, ScaChallengeType.TRX_P2P].includes(payload.type),
            handler: () => [createPayment(payload)],
        },
        {
            predicate: () => payload.type === ScaChallengeType.TRX_EXC,
            handler: () => [confirmExchange(payload)],
        },
        {
            predicate: () => payload.type === ScaChallengeType.CARD_SENS,
            handler: () => [fetchCardSensitiveData(payload)],
        },

        {
            predicate: () => true,
            handler: () => [],
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};

