import { Uuid } from '@manigo/manigo-domain-typings';

import { getKybAccessTokenAPI, getKycAccessTokenAPI } from './index';


class KybKycService {
    public getKybAccessToken(companyExtUuid: Uuid) {
        return getKybAccessTokenAPI(companyExtUuid);
    }

    public getKycAccessToken(externalUserId: Uuid) {
        return getKycAccessTokenAPI({ params: { externalUserId } });
    }

}
export default KybKycService;
