import { createAction } from '@reduxjs/toolkit';

import {
    CONFIRM_LOGIN,
    CONFIRM_LOGIN_FAILURE,
    CONFIRM_LOGIN_SUCCESS,
    GET_REMEMBERED_LOGIN_USER_EMAIL,
    GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE,
    GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS,
    GET_TOKENS_FOR_DUPLICATED_TAB,
    INITIALISE_LOGIN,
    INITIALISE_LOGIN_FAILURE,
    INITIALISE_LOGIN_SUCCESS,
    REQUEST_PASSWORD_RESET,
    REQUEST_PASSWORD_RESET_FAILURE,
    REQUEST_PASSWORD_RESET_SUCCESS,
    SET_NEW_PASSWORD,
    SET_NEW_PASSWORD_FAILURE,
    SET_NEW_PASSWORD_SUCCESS,
    SIGN_UP,
    SIGN_UP_SUCCESS,
    SIGN_UP_FAILURE,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    ACTIVATE_USER,
    ACTIVATE_USER_SUCCESS,
    ACTIVATE_USER_FAILURE,
    CLEAR_LOGIN_STATE,
    CLEAR_REQUEST_PASSWORD_RESET_STATE,
    SEND_CONFIRMATION_CODE,
    SEND_CONFIRMATION_CODE_SUCCESS,
    SEND_CONFIRMATION_CODE_FAILURE,
    SendConfirmationCodePayload,
    CLEAR_BLOCK_SEND_CONFIRMATION_CODE,
} from './actions.types';


export const activateUser = createAction(ACTIVATE_USER, (requestPayload) => {
    return { payload: requestPayload };
});
export const activateUserSuccess = createAction(ACTIVATE_USER_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const activateUserFailure = createAction(ACTIVATE_USER_FAILURE);

export const initialiseLogin = createAction(INITIALISE_LOGIN, (requestPayload) => {
    return { payload: requestPayload };
});
export const initialiseLoginSuccess = createAction(INITIALISE_LOGIN_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const initialiseLoginFailure = createAction(INITIALISE_LOGIN_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});

export const clearLoginState = createAction(CLEAR_LOGIN_STATE);

export const confirmLogin = createAction(CONFIRM_LOGIN, (requestPayload) => {
    return { payload: requestPayload };
});
export const confirmLoginSuccess = createAction(CONFIRM_LOGIN_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const confirmLoginFailure = createAction(CONFIRM_LOGIN_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});


export const setNewPassword = createAction(SET_NEW_PASSWORD, (requestPayload) => {
    return { payload: requestPayload };
});
export const setNewPasswordSuccess = createAction(SET_NEW_PASSWORD_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const setNewPasswordFailure = createAction(SET_NEW_PASSWORD_FAILURE);


export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET, (requestPayload) => {
    return { payload: requestPayload };
});
export const requestPasswordResetSuccess = createAction(REQUEST_PASSWORD_RESET_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const requestPasswordResetFailure = createAction(REQUEST_PASSWORD_RESET_FAILURE);
export const clearRequestPasswordResetState = createAction(CLEAR_REQUEST_PASSWORD_RESET_STATE);

export const getTokensForDuplicatedTab = createAction(GET_TOKENS_FOR_DUPLICATED_TAB, (requestPayload: object, sessionStorageData?: any) => {
    return { payload: { requestPayload, sessionStorageData } };
});

export const getRememberedUserEmail = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL);
export const getRememberedUserEmailSuccess = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL_SUCCESS, (userEmail: string) => ({ payload: userEmail }));
export const getRememberedUserEmailFailure = createAction(GET_REMEMBERED_LOGIN_USER_EMAIL_FAILURE);

export const signUp = createAction(SIGN_UP, (requestPayload) => {
    return { payload: requestPayload };
});
export const signUpSuccess = createAction(SIGN_UP_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const signUpFailure = createAction(SIGN_UP_FAILURE, (responsePayload) => {
    return { payload: responsePayload };
});

export const refreshToken = createAction(REFRESH_TOKEN);
export const refreshTokenSuccess = createAction(REFRESH_TOKEN_SUCCESS);
export const refreshTokenFailure = createAction(REFRESH_TOKEN_FAILURE);


export const sendConfirmationCode = createAction(SEND_CONFIRMATION_CODE, (responsePayload: SendConfirmationCodePayload) => {
    return { payload: responsePayload };
});
export const sendConfirmationCodeSuccess = createAction(SEND_CONFIRMATION_CODE_SUCCESS, (requestPayload: SendConfirmationCodePayload) => {
    return { payload: requestPayload };
});
export const sendConfirmationCodeFailure = createAction(SEND_CONFIRMATION_CODE_FAILURE);
export const clearBlockSendConfirmationCode = createAction(CLEAR_BLOCK_SEND_CONFIRMATION_CODE);
