import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    ADD_BENEFICIARY,
    ADD_BENEFICIARY_FAILURE,
    ADD_BENEFICIARY_SUCCESS,
    CLEAR_BENEFICIARIES_LIST,
    FETCH_BENEFICIARIES_LIST,
    FETCH_BENEFICIARIES_LIST_FAILURE,
    FETCH_BENEFICIARIES_LIST_SUCCESS,
    FETCH_BENEFICIARY_REQUIREMENTS,
    FETCH_BENEFICIARY_REQUIREMENTS_FAILURE,
    FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS,
} from './action.types';
import { parseRawBeneficiariesRequirements } from './helpers';


export const beneficiariesReducerName = 'beneficiaries';
export const beneficiariesListsPerLocationStoreKeyName = 'beneficiariesListsPerLocation';


export const defaultBeneficiariesListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
};

export const beneficiariesInitialListState = {
    [beneficiariesListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }

    isLoadingBeneficiaryRequirements: false,
    isLoadingAddBeneficiary: false,
    isLoadingUpdateBeneficiary: false,
    beneficiaryRequirements: {},
};


const createBeneficiariesBuilderCases = (builder) => {
    builder
        .addCase(FETCH_BENEFICIARIES_LIST, (state, action) => {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardLists(
                {
                    listsState: state[beneficiariesListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_BENEFICIARIES_LIST_SUCCESS, (state, action) => {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess(
                {
                    listsState: state[beneficiariesListsPerLocationStoreKeyName],
                    action,
                },
            );
        })
        .addCase(FETCH_BENEFICIARIES_LIST_FAILURE, (state, action) => {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[beneficiariesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_BENEFICIARIES_LIST, (state, action) => {
            state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[beneficiariesListsPerLocationStoreKeyName],
                action,
            });
        })

        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === beneficiariesReducerName
                && action.payload?.fieldName === beneficiariesListsPerLocationStoreKeyName
            ) {
                state[beneficiariesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[beneficiariesListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(FETCH_BENEFICIARY_REQUIREMENTS, (state) => {
            state.isLoadingBeneficiaryRequirements = true;
        })
        .addCase(FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS, (state, action) => {
            state.isLoadingBeneficiaryRequirements = false;
            state.beneficiaryRequirements = {
                ...state.beneficiaryRequirements,
                ...parseRawBeneficiariesRequirements(action.payload),
            };
        })
        .addCase(FETCH_BENEFICIARY_REQUIREMENTS_FAILURE, (state) => {
            state.isLoadingBeneficiaryRequirements = false;
        })

        .addCase(ADD_BENEFICIARY, (state) => {
            state.isLoadingAddBeneficiary = true;
        })
        .addCase(ADD_BENEFICIARY_SUCCESS, (state) => {
            state.isLoadingAddBeneficiary = false;
        })
        .addCase(ADD_BENEFICIARY_FAILURE, (state) => {
            state.isLoadingAddBeneficiary = false;
        })
        .addCase(CLEAR_CURRENT_USER, () => beneficiariesInitialListState);
};

export default createReducer(beneficiariesInitialListState, createBeneficiariesBuilderCases);
