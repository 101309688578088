import { ofType } from 'redux-observable';
import { from, of, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';

import { addCompanyIdToQueryParams } from 'store/common.helpers';

import { fetchStakeholdersListFailure, fetchStakeholdersListSuccess } from './actions';
import { FETCH_STAKEHOLDERS_LIST } from './actions.types';


export const onFetchStakeholdersList: Epic = (action$, state$, { companies }) => action$.pipe(
    ofType(FETCH_STAKEHOLDERS_LIST),
    switchMap(({ payload: { locationPathname, queryParams } }) => from(companies.getStakeholders(addCompanyIdToQueryParams({ state$, queryParams }))).pipe(
        switchMap((response) => of(fetchStakeholdersListSuccess({
            items: response.data.rows,
            totalCount: response?.data.count ?? 0,
            locationPathname,
        }))),
        catchError(() => of(fetchStakeholdersListFailure({ locationPathname }))),
    )),
);

export default [
    onFetchStakeholdersList,
];
