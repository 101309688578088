import { AesEncryptedText } from '@manigo/manigo-domain-typings';

import { decrypt } from 'utils/encryption-tools';


export const parseEncryptedChallengeData = (signature: AesEncryptedText) => {
    try {
        const decryptedData = decrypt(signature);
        const pattern = /^(\d{10})(.*)/;
        const match = decryptedData.match(pattern);
        const saltPrefix = '$2b$10$';

        if (match) {
            return {
                timestamp: match[1],
                salt: `${saltPrefix}${match[2]}`,
            };
        } else {
            console.log('No match found.');
        }

    } catch (e) {
        console.warn(e);
    }

    return {};
};
