export const GET_LOCALES_MANIFEST = 'GET_LOCALES_MANIFEST';
export const GET_LOCALES_MANIFEST_SUCCESS = 'GET_LOCALES_MANIFEST_SUCCESS';


export const INIT_I18NEXT = 'INIT_I18NEXT';
export const INIT_I18NEXT_SUCCESS = 'INIT_I18NEXT_SUCCESS';
export const INIT_I18NEXT_FAILURE = 'INIT_I18NEXT_FAILURE';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAILURE = 'CHANGE_LANGUAGE_FAILURE';

export const LOAD_LANGUAGE = 'LOAD_LANGUAGE';
export const LOAD_LANGUAGE_SUCCESS = 'LOAD_LANGUAGE_SUCCESS';
export const LOAD_LANGUAGE_FAILURE = 'LOAD_LANGUAGE_FAILURE';


export const LOAD_NAMESPACES = 'LOAD_NAMESPACES';
export const LOAD_NAMESPACES_SUCCESS = 'LOAD_NAMESPACES_SUCCESS';
export const LOAD_NAMESPACES_FAILURE = 'LOAD_NAMESPACES_FAILURE';
