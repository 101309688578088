import { createAction } from '@reduxjs/toolkit';

import {
    DELETE_CARD,
    DELETE_CARD_FAILURE,
    DELETE_CARD_SUCCESS,
    FETCH_CARD_DETAILS,
    FETCH_CARD_DETAILS_FAILURE,
    FETCH_CARD_DETAILS_SUCCESS,
    UPDATE_CARD_STATUS,
    UPDATE_CARD_STATUS_FAILURE,
    UPDATE_CARD_STATUS_SUCCESS,
    UNBLOCK_CARD_PIN,
    UNBLOCK_CARD_PIN_FAILURE,
    UNBLOCK_CARD_PIN_SUCCESS,
    RESET_CONTACTLESS_CARD_LIMIT,
    RESET_CONTACTLESS_CARD_LIMIT_SUCCESS,
    RESET_CONTACTLESS_CARD_LIMIT_FAILURE,
    ACTIVATE_CARD,
    ACTIVATE_CARD_SUCCESS,
    ACTIVATE_CARD_FAILURE,
    UPDATE_CARD_SETTINGS,
    UPDATE_CARD_SETTINGS_SUCCESS,
    UPDATE_CARD_SETTINGS_FAILURE,
    UPDATE_CARD_LIMITS,
    UPDATE_CARD_LIMITS_SUCCESS,
    UPDATE_CARD_LIMITS_FAILURE,
    DeleteCardPayload,
    UpdateCardStatusPayload,
    ActivateCardPayload,
    UpdateCardSettingsPayload,
    UpdateCardLimitsPayload,

} from './actions.types';
import { CLEAR_CARDS_LIST } from '../cards/actions.types';


export const fetchCardDetails = createAction(FETCH_CARD_DETAILS, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchCardDetailsSuccess = createAction(FETCH_CARD_DETAILS_SUCCESS, ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const fetchCardDetailsFailure = createAction(FETCH_CARD_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearCardDetails = createAction(CLEAR_CARDS_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));

export const deleteCard = createAction(DELETE_CARD, ({ queryParams, locationPathname }:DeleteCardPayload) => ({ payload: { queryParams, locationPathname } }));
export const deleteCardSuccess = createAction(DELETE_CARD_SUCCESS, ({ responsePayload }) => ({ payload: responsePayload }));
export const deleteCardFailure = createAction(DELETE_CARD_FAILURE);


export const updateCardStatus = createAction(UPDATE_CARD_STATUS, ({ queryParams, locationPathname }:UpdateCardStatusPayload) => ({ payload: { queryParams, locationPathname } }));
export const updateCardStatusSuccess = createAction(UPDATE_CARD_STATUS_SUCCESS, ({ responsePayload }) => ({ payload: responsePayload }));
export const updateCardStatusFailure = createAction(UPDATE_CARD_STATUS_FAILURE, ({ responsePayload }) => ({ payload: responsePayload }));


export const unblockCardPin = createAction(UNBLOCK_CARD_PIN, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const unblockCardPinSuccess = createAction(UNBLOCK_CARD_PIN_SUCCESS);
export const unblockCardPinFailure = createAction(UNBLOCK_CARD_PIN_FAILURE);

export const resetContactlessCardLimit = createAction(RESET_CONTACTLESS_CARD_LIMIT, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const resetContactlessCardLimitSuccess = createAction(RESET_CONTACTLESS_CARD_LIMIT_SUCCESS);
export const resetContactlessCardLimitFailure = createAction(RESET_CONTACTLESS_CARD_LIMIT_FAILURE);


export const activateCard = createAction(ACTIVATE_CARD, ({ queryParams, locationPathname }:ActivateCardPayload) => ({ payload: { queryParams, locationPathname } }));
export const activateCardSuccess = createAction(ACTIVATE_CARD_SUCCESS);
export const activateCardFailure = createAction(ACTIVATE_CARD_FAILURE);

export const updateCardSettings = createAction(UPDATE_CARD_SETTINGS,
    ({ queryParams, locationPathname }:UpdateCardSettingsPayload) => ({ payload: { queryParams, locationPathname } }));
export const updateCardSettingsSuccess = createAction(UPDATE_CARD_SETTINGS_SUCCESS);
export const updateCardSettingsFailure = createAction(UPDATE_CARD_SETTINGS_FAILURE);

export const updateCardLimits = createAction(UPDATE_CARD_LIMITS,
    ({ queryParams, locationPathname }:UpdateCardLimitsPayload) => ({ payload: { queryParams, locationPathname } }));
export const updateCardLimitsSuccess = createAction(UPDATE_CARD_LIMITS_SUCCESS);
export const updateCardLimitsFailure = createAction(UPDATE_CARD_LIMITS_FAILURE);
