import { AccountType } from '@manigo/manigo-domain-typings';

import { getProductsListByClientId } from './index';


class ClientsService {

    public getProductsList(rawQueryParams: { clientId: number }) {
        const { clientId } = rawQueryParams;
        const queryParams = { params: { type: AccountType.Business } };

        return getProductsListByClientId(clientId, queryParams);
    }

}

export default ClientsService;

