import { compose } from '@reduxjs/toolkit';
import { ComponentType } from 'react';
import { withTranslation } from 'react-i18next';

import TheSpinner from './TheSpinner.layout';
import { TheSpinnerOwnProps } from './TheSpinner.types';


const decorate = compose<ComponentType<TheSpinnerOwnProps>>(
    withTranslation('common'),
);


export default decorate(TheSpinner);
