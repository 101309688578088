import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_ALL_PASSCODE_DIGITS,
    SET_PASSCODE_DIGIT,
} from './actions.types';
import { OtpStateType } from './reducer.types';


export const otpReducerName = 'otp';

const initialState: OtpStateType = {
    digit1Value: '',
    digit2Value: '',
    digit3Value: '',
    digit4Value: '',
    digit5Value: '',
    digit6Value: '',
};

const createOtpReducerBuilderCases = (builder) => {
    builder
        .addCase(SET_PASSCODE_DIGIT, (state, action) => {
            state[action.payload?.key] = action.payload?.value;
        })
        .addCase(CLEAR_ALL_PASSCODE_DIGITS, () => ({ ...initialState }))
        .addCase(CLEAR_CURRENT_USER, () => ({ ...initialState }));

};

export default createReducer(initialState, createOtpReducerBuilderCases);
