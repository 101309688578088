import { ReactChildrenProp } from './children';


export enum MultiStepFormResetScrollElements {
  mainStepsClassName = 'reset-scroll-step-without-sub-steps',
  subStepsClassName = 'reset-scroll-step-with-sub-steps',
}

export enum MultiStepFormStepChangeDirections {
    FORWARD = 'FORWARD',
    BACK = 'BACK',
}

export enum MultiStepFormCommonStepsDefinitions {
    SUCCESS = 'SUCCESS',
    ADDRESSES = 'ADDRESSES',
}

export enum MultiStepFormStepSectionTypes {
    EVEN_COLUMNS = 'EVEN_COLUMNS', // default, default colCount = 1
    AUTO_ROWS = 'AUTO_ROWS', // auto height rows in single col
    GRID = 'GRID', // (auto height) rows & cols (default=2)
}

export enum MultiStepFormStepSectionFieldsLayout {
    VERTICAL = 'VERTICAL', // default, field one under another, full width
    AUTO_HORIZONTAL = 'AUTO_HORIZONTAL', // fields render in row with warping to next rows, element width is fit-content
    EVEN_HORIZONTAL = 'EVEN_HORIZONTAL', // fields render in row with warping to next rows, element width is equal, default 50%
}

// export const CommonFormFieldTypes {
//     BOOLEAN: 'BOOLEAN', // standard input tag with text type
//     TEXT: 'TEXT', // standard input tag with text type
//     TEXT_MULTI_LINE: 'TEXT_MULTI_LINE', // text-area-type
//     TEXT_MULTI_VALUES: 'TEXT_MULTI_VALUES', // select multi with input & tags as entered values
//
//     PASSWORD: 'PASSWORD', // type
//     URL: 'URL', // type && validation
//     EMAIL: 'EMAIL', // type && validation
//     PHONE_NUMBER: 'PHONE_NUMBER', // type && validation
//
//     NUMBER: 'NUMBER', // standard input tag with type number, validation allows all numbers
//     POSITIVE_INTEGER: 'POSITIVE_INTEGER', // 1,2,3..
//     AMOUNT: 'AMOUNT', // positive with 2 decimal palaces
//
//     SELECT: 'SELECT',
//     SELECT_COUNTRY: 'SELECT_COUNTRY', // standard options with flags
//     SELECT_CURRENCY: 'SELECT_CURRENCY',
//     SELECT_CLIENT: 'SELECT_CLIENT',
//
//     MULTI_SELECT: 'MULTI_SELECT',
//     MULTI_SELECT_EEA_COUNTRIES: 'MULTI_SELECT_EEA_COUNTRIES',
//     MULTI_SELECT_INDUSTRIES: 'MULTI_SELECT_INDUSTRIES',
//
//     JSON: 'JSON', // tag: textarea & validation
//
//     COLOR: 'COLOR', // standard input tag with color type
//
//     DATE: 'DATE', // renders single date(only) picker
//     DATETIME: 'DATETIME', // renders single date & time picker
//     DATETIME_RANGE: 'DATETIME_RANGE', // renders two pickers (dateFrom & dateTo)
//
//     HIDDEN: 'HIDDEN',
// });

// export const OpenFormFieldTypes = [
//     CommonFormFieldTypes.TEXT,
//     CommonFormFieldTypes.TEXT_MULTI_VALUES,
//     CommonFormFieldTypes.PASSWORD,
//     CommonFormFieldTypes.PHONE_NUMBER,
//     CommonFormFieldTypes.URL,
//     CommonFormFieldTypes.EMAIL,
//     CommonFormFieldTypes.DATE,
//     CommonFormFieldTypes.DATETIME,
//     CommonFormFieldTypes.DATETIME_RANGE,
//     CommonFormFieldTypes.COLOR,
//     CommonFormFieldTypes.JSON,
//     CommonFormFieldTypes.NUMBER,
//     CommonFormFieldTypes.POSITIVE_INTEGER,
//     CommonFormFieldTypes.AMOUNT,
// ];

export interface MultiStepsFormStepSectionConfig {
    title: string,
    children: ReactChildrenProp,
}

export interface MultiStepsFormStepConfig {
    stepName: string,
    title: string,
    icon?: ReactChildrenProp,
    children: ReactChildrenProp,
}


export default {
    MultiStepFormResetScrollElements,
    MultiStepFormStepChangeDirections,
    MultiStepFormCommonStepsDefinitions,
};

