import { divideBig, multiplyBig, createFutureDateMS } from '@manigo/manigo-commons';
import { CurrencyIsoCode } from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    UPDATE_BUY_AMOUNT,
    UPDATE_MAX_SELL_AMOUNT,
    UPDATE_SELL_AMOUNT,

    CLEAR_EXCHANGE_DATA,

    CONFIRM_EXCHANGE,
    CONFIRM_EXCHANGE_FAILURE,
    CONFIRM_EXCHANGE_SUCCESS,

    FETCH_EXCHANGE_RATE,
    FETCH_EXCHANGE_RATE_FAILURE,
    FETCH_EXCHANGE_RATE_SUCCESS,

    INIT_EXCHANGE,
    INIT_EXCHANGE_FAILURE,
    INIT_EXCHANGE_SUCCESS,

    REJECT_EXCHANGE,
    REJECT_EXCHANGE_FAILURE,
    REJECT_EXCHANGE_SUCCESS,
    ExchangeRateObject,
    COUNTDOWN_TIME,
    CLEAR_INIT_EXCHANGE_INFO,
    INIT_EXCHANGE_COUNTDOWN_TIME, InitExchangeResponsePayload,
} from './actions.types';


export const exchangeReducerName = 'exchange';

interface ExchangeState {
    sourceCurrency?: CurrencyIsoCode;
    targetCurrency?: CurrencyIsoCode;
    countdownTime?: Date;
    initExchangeCountdownTime?: Date;
    sellAmount?: number;
    maxSellAmount?: number;
    maxBuyAmount?: number;
    buyAmount?: number;
    exchangeRate?: number;
    initExchangeInfo?: InitExchangeResponsePayload['responsePayload']
    isLoadingExchange: boolean;
    isLoadingExchangeRate: boolean;
    isLoadingInitExchangeInfo: boolean;
}

const initialState: ExchangeState = {
    sourceCurrency: undefined,
    targetCurrency: undefined,
    countdownTime: undefined,
    initExchangeCountdownTime: undefined,

    sellAmount: undefined,
    maxSellAmount: undefined,
    maxBuyAmount: undefined,
    buyAmount: undefined,

    exchangeRate: undefined,
    initExchangeInfo: undefined,

    isLoadingExchange: false,
    isLoadingExchangeRate: false,
    isLoadingInitExchangeInfo: false,
};

const createExchangeBuilderCases = (builder) =>
    builder
        .addCase(UPDATE_SELL_AMOUNT, (state, action) => {
            const value = action.payload.queryParams.sellAmount;
            const { exchangeRate } = state;
            const buyAmount = multiplyBig({ x: value ?? 0, y: exchangeRate, precision: 2, roundingMode: 0 });
            state.buyAmount = buyAmount;
        })
        .addCase(UPDATE_MAX_SELL_AMOUNT, (state, action) => {
            state.maxSellAmount = action.payload.queryParams.maxSellAmount;
        })
        .addCase(UPDATE_BUY_AMOUNT, (state, action) => {
            const value = action.payload.queryParams.buyAmount;
            const inverseExchangeRate = divideBig({ x: 1, y: state.exchangeRate, precision: 6 }) as number;
            const sellAmount = multiplyBig({ x: value ?? 0, y: inverseExchangeRate, precision: 2, roundingMode: 1 });

            state.sellAmount = sellAmount ? Math.min(Number(sellAmount), state.maxSellAmount) : sellAmount;
        })
        .addCase(CLEAR_EXCHANGE_DATA, (state) => {
            return {
                ...initialState,
                initExchangeCountdownTime: state.initExchangeCountdownTime,
                initExchangeInfo: state.initExchangeInfo,
                maxSellAmount: state.maxSellAmount,
                maxBuyAmount: state.maxBuyAmount,
            };
        })
        .addCase(INIT_EXCHANGE, (state) => {
            state.isLoadingInitExchangeInfo = true;
        })
        .addCase(INIT_EXCHANGE_SUCCESS, (state, action) => {
            state.isLoadingInitExchangeInfo = false;
            state.initExchangeInfo = action.payload.responsePayload;
            state.initExchangeCountdownTime = createFutureDateMS(INIT_EXCHANGE_COUNTDOWN_TIME);
        })
        .addCase(INIT_EXCHANGE_FAILURE, (state) => {
            state.isLoadingInitExchangeInfo = false;
        })
        .addCase(CLEAR_INIT_EXCHANGE_INFO, (state) => {
            state.initExchangeInfo = undefined;
        })
        .addCase(CONFIRM_EXCHANGE, (state) => {
            state.isLoadingExchange = true;
        })
        .addCase(CONFIRM_EXCHANGE_SUCCESS, (state) => {
            state.isLoadingExchange = false;
            state.exchangeUuid = undefined;
            state.exchangeRate = undefined;
        })
        .addCase(CONFIRM_EXCHANGE_FAILURE, (state) => {
            state.isLoadingExchange = false;
        })
        .addCase(REJECT_EXCHANGE, (state) => {
            state.isLoadingExchange = true;
        })
        .addCase(REJECT_EXCHANGE_SUCCESS, (state) => {
            state.isLoadingExchange = false;
        })
        .addCase(REJECT_EXCHANGE_FAILURE, (state) => {
            state.isLoadingExchange = false;
        })
        .addCase(FETCH_EXCHANGE_RATE, (state, action) => {
            const { sourceCurrency, targetCurrency } = action.payload.queryParams;
            state.isLoadingExchangeRate = true;
            state.targetCurrency = targetCurrency;
            state.sourceCurrency = sourceCurrency;
        })
        .addCase(FETCH_EXCHANGE_RATE_SUCCESS, (state, action) => {
            const { maxSellAmount, targetCurrency } = state;
            const exchangeRates = action.payload?.exchangeRates as ExchangeRateObject[];
            const rate = exchangeRates.find((exchangeRate) => exchangeRate.receiveCurrency === targetCurrency)?.rate;

            state.maxBuyAmount = multiplyBig({ x: maxSellAmount, y: rate, precision: 2, roundingMode: 0 }) as number;
            state.exchangeRate = rate;
            state.countdownTime = createFutureDateMS(COUNTDOWN_TIME);
            state.isLoadingExchangeRate = false;
        })
        .addCase(FETCH_EXCHANGE_RATE_FAILURE, (state) => {
            state.isLoadingExchangeRate = false;
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);

export default createReducer(initialState, createExchangeBuilderCases);
