export const REQUEST_SET_MULTI_STEPS_FORM_STEP = 'REQUEST_SET_MULTI_STEPS_FORM_STEP';
export const SET_MULTI_STEPS_FORM_STEP = 'SET_MULTI_STEPS_FORM_STEP';


export const SET_MULTI_STEPS_FORM_META_DATA = 'SET_MULTI_STEPS_FORM_META_DATA';
export const CLEAR_MULTI_STEPS_FORM_META_DATA = 'CLEAR_MULTI_STEPS_FORM_META_DATA';


export const SET_NEW_ENTITY_LOCATION = 'SET_NEW_ENTITY_LOCATION';

export const UPDATE_FORM_CONTEXT = 'UPDATE_FORM_CONTEXT';
export const CLEAR_FORM_CONTEXT = 'CLEAR_FORM_CONTEXT';
export const SET_FORM_STEP_DATA = 'SET_FORM_STEP_DATA';
export const CLEAR_FORM_STEP_DATA = 'CLEAR_FORM_STEP_DATA';
export const CLEAR_ALL_FORM_DATA = 'CLEAR_ALL_FORM_DATA';
