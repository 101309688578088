import { LocationPathname } from '@manigo/manigo-commons';
import { BusinessUserDetails } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';


import {
    CLEAR_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS,
    FETCH_BUSINESS_USER_DETAILS_FAILURE,
    FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    UPDATE_USER, UPDATE_USER_FAILURE, UPDATE_USER_SUCCESS,
} from './action.types';


export const fetchBusinessUserDetails = createAction(FETCH_BUSINESS_USER_DETAILS, ({
    queryParams,
    locationPathname,
}) => ({
    payload: {
        queryParams,
        locationPathname,
    },
}));
export const fetchBusinessUserDetailsSuccess = createAction(FETCH_BUSINESS_USER_DETAILS_SUCCESS,
    ({ responsePayload, locationPathname }) => ({ payload: { responsePayload, locationPathname } }));
export const fetchBusinessUserDetailsFailure = createAction(FETCH_BUSINESS_USER_DETAILS_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearBusinessUserDetails = createAction(CLEAR_BUSINESS_USER_DETAILS, ({ locationPathname }) => ({ payload: { locationPathname } }));

export interface UpdateUserPayload {
    requestPayload: {
        businessUserId: BusinessUserDetails['businessUserId'];
        businessUserEmail: BusinessUserDetails['businessUserEmail'];
        businessUserPhoneNumber: BusinessUserDetails['businessUserPhoneNumber'];
    };
    locationPathname: LocationPathname
}

export const updateUser = createAction(UPDATE_USER,
    ({ requestPayload, locationPathname }: UpdateUserPayload): {
        payload: UpdateUserPayload
    } => ({ payload: { requestPayload, locationPathname } }));
export const updateUserSuccess = createAction(UPDATE_USER_SUCCESS,
    (responsePayload, locationPathname) => ({ payload: { responsePayload, locationPathname } }));
export const updateUserFailure = createAction(UPDATE_USER_FAILURE);
