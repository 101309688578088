import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Epic } from 'models/meta/epic';


import { fetchKybAccessTokenFailure, fetchKybAccessTokenSuccess, fetchKycAccessTokenFailure, fetchKycAccessTokenSuccess } from './actions';
import { FETCH_KYB_ACCESS_TOKEN, FETCH_KYC_ACCESS_TOKEN } from './actions.types';


export const onFetchKybAccessToken: Epic = (action$, state$, { kybKyc }) => action$.pipe(
    ofType(FETCH_KYB_ACCESS_TOKEN),
    switchMap(({ payload }) => from(kybKyc.getKybAccessToken(payload))
        .pipe(
            switchMap((response) => of(fetchKybAccessTokenSuccess(response?.data?.token))),
            catchError(() => of(fetchKybAccessTokenFailure())),
        )),
);

export const onFetchKycAccessToken: Epic = (action$, state$, { kybKyc }) => action$.pipe(
    ofType(FETCH_KYC_ACCESS_TOKEN),
    switchMap(({ payload }) => from(kybKyc.getKycAccessToken(payload))
        .pipe(
            switchMap((response) => {
                return of(fetchKycAccessTokenSuccess(response?.data?.token));
            }),
            catchError(() => {
                return of(fetchKycAccessTokenFailure());
            }),
        )),
);

export default [
    onFetchKybAccessToken,
    onFetchKycAccessToken,
];

