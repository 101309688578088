import {
    TransactionDetails,
    AccountDetails,
    TransactionScope, TransactionType,
    Beneficiary,
    CurrencyIsoCode,
} from '@manigo/manigo-domain-typings';


export const EXCHANGE_RATE_PRECISION = 6;

export const optionalTransactionsScopeFilters = ['TOPUP_CARD_VERIFICATION', 'TOPUP_CARD_VERIFICATION_REFUND'];

export interface TransactionFilter {
    label: string,
    name: string,
    transactionScope: TransactionScope,
    transactionType: TransactionType,
}

export interface TransactionScopeFilter {
    label: string,
    value: TransactionFilter['name'],
}

export const transactionFilters: TransactionFilter[] = [
    {
        transactionType: TransactionType.EXT,
        transactionScope: TransactionScope.IBT,
        label: 'INCOMING BANK TRANSFER',
        name: 'INCOMING_BANK_TRANSFER',
    },
    {
        transactionType: TransactionType.EXT,
        transactionScope: TransactionScope.OBT,
        label: 'OUTGOING BANK TRANSFER',
        name: 'OUTGOING_BANK_TRANSFER',
    },

    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.IP2P,
        label: 'INCOMING P2P',
        name: 'INCOMING_P2P',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.OP2P,
        label: 'OUTGOING P2P',
        name: 'OUTGOING_P2P',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.AC,
        label: 'ADMIN CREDIT',
        name: 'ADMIN_CREDIT',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.AD,
        label: 'ADMIN DEBIT',
        name: 'ADMIN_DEBIT',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.EXF,
        label: 'EXCHANGE FROM',
        name: 'EXCHANGE_FROM',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.EXT,
        label: 'EXCHANGE TO',
        name: 'EXCHANGE_TO',
    },
    {
        transactionType: TransactionType.CT,
        transactionScope: TransactionScope.ATM,
        label: 'ATM WITHDRAWAL',
        name: 'ATM_WITHDRAWAL',
    },
    {
        transactionType: TransactionType.CT,
        transactionScope: TransactionScope.POS,
        label: 'POS/ONLINE',
        name: 'POS_ONLINE',
    },
    {
        transactionType: TransactionType.CT,
        transactionScope: TransactionScope.CTR,
        label: 'CARD TRANSACTION REFUND',
        name: 'CARD_TRANSACTION_REFUND',
    },
    {
        transactionType: TransactionType.FEE,
        transactionScope: TransactionScope.C,
        label: 'FEE CREDIT',
        name: 'FEE_CREDIT',
    },
    {
        transactionType: TransactionType.FEE,
        transactionScope: TransactionScope.D,
        label: 'FEE DEBIT',
        name: 'FEE_DEBIT',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.BWC,
        label: 'BALANCE WITHDRAWAL CREDIT',
        name: 'BALANCE_WITHDRAWAL_CREDIT',
    },
    {
        transactionType: TransactionType.INT,
        transactionScope: TransactionScope.BWD,
        label: 'BALANCE WITHDRAWAL DEBIT',
        name: 'BALANCE_WITHDRAWAL_DEBIT',
    },

    {
        transactionType: TransactionType.EXT,
        transactionScope: TransactionScope.DD,
        label: 'DIRECT DEBIT',
        name: 'DIRECT_DEBIT',
    },
    {
        transactionType: TransactionType.EXT,
        transactionScope: TransactionScope.DC,
        label: 'DIRECT CREDIT',
        name: 'DIRECT_CREDIT',
    },
];

export interface InitialTransferConfig {
    fromAccount: {
        value: AccountDetails['id'];
        currency: CurrencyIsoCode;
    };
    toAccount: {
        value: AccountDetails['id'];
    };
    sellAmount: TransactionDetails['processedAmount'];
    reference: TransactionDetails['systemDescription']
    beneficiary: {
        value: Beneficiary['id'];
    }
}
