import { CardStatus } from '@manigo/manigo-domain-typings';


export const VirtualCardsStatusesList = Object.freeze([
    CardStatus.ACTIVE,
    CardStatus.SUSPENDED,

    CardStatus.VOIDED,
    CardStatus.FRAUD,
    CardStatus.EXPIRED,
]);


export const PhysicalCardsStatusesList = Object.freeze([
    CardStatus.NOT_ACTIVATED,
    CardStatus.ACTIVE,
    CardStatus.SUSPENDED,

    CardStatus.NOT_DELIVERED,
    CardStatus.LOST,
    CardStatus.STOLEN,
    CardStatus.FRAUD,
    CardStatus.EXPIRED,
]);
export const PermanentCardStatusesList = Object.freeze([
    CardStatus.NOT_DELIVERED,
    CardStatus.LOST,
    CardStatus.STOLEN,
    CardStatus.FRAUD,
    CardStatus.EXPIRED,
    CardStatus.VOIDED,
]);
