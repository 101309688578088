import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { CountryListItem } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';


export const signUpFormName = 'sign-up-form';
export enum SignUpFormSteps {
    INTRODUCTION = 'SIGN_UP_INTRODUCTION',
    COMPANY_DATA = 'SIGN_UP_COMPANY_DATA',
    COMPANY_ADDRESS = 'SIGN_UP_COMPANY_ADDRESS',
    BUSINESS_USER_DATA = 'SIGN_UP_BUSINESS_USER_DATA',
    REVIEW = 'SIGN_UP_REVIEW',
    SUCCESS = 'SIGN_UP_SUCCESS',
}

export interface SignUpPageStateProps {
    currentStep?: string,
    enhancedCurrentLocation: EnhancedLocation,
    signUpFormErrorMessage?: string,
    isLoadingSignUpForm: boolean,
    countries?: CountryListItem[],
}

export interface SignUpPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) => void,
    dispatchFetchCountries: () => void,
}

export type SignUpPageProps = SignUpPageStateProps & SignUpPageDispatchProps & WithTranslation;

