import { createReducer } from '@reduxjs/toolkit';

import { ModalState } from 'models/app/modal';

import { HIDE_MODAL, SET_MODAL_PROPS, SHOW_MODAL } from './actions.types';
import { CLEAR_CURRENT_USER } from '../current-user/actions.types';


export const modalReducerName = 'modal';

const initialState:ModalState = {
    open: false,
    modalType: null,
    modalProps: {
        title: '',
        modalSize: 'md',
    },
};

const createModalBuilderCases = (builder) =>
    builder
        .addCase(SHOW_MODAL, (state, action) => {
            state.open = true;
            state.modalType = action.payload.modalType;
            state.modalProps = action.payload.modalProps;
        })
        .addCase(HIDE_MODAL, (state) => {
            return {
                ...state,
                ...initialState,
            };
        })
        .addCase(SET_MODAL_PROPS, (state, action) => {
            state.modalProps = {
                ...state.modalProps,
                ...action.payload,
            };
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);

export default createReducer(initialState, createModalBuilderCases);
