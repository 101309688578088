import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { CountryIsoAlpha3Code, CurrencyIsoCode, NumericId, BeneficiaryType } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';

import { WithAccessControl } from 'models/domain/accessControl';

import { AddBeneficiaryDetailsDataStepFields } from '../common-parts/AddEditBeneficiary.types';


export const addBeneficiaryFormName = 'add-beneficiary';

export enum AddBeneficiaryFormSteps {
    BASIC_DATA = 'ADD_BENEFICIARY_BASIC_DATA',
    DETAILS = 'ADD_BENEFICIARY_DETAILS',
    ACCOUNT_IDENTIFIERS = 'ADD_BENEFICIARY_ACCOUNT_IDENTIFIERS',
    REVIEW = 'ADD_BENEFICIARY_REVIEW',
    SUCCESS = 'ADD_BENEFICIARY_SUCCESS',
}

export interface AddBeneficiaryBasicDataStepFields {
    type: BeneficiaryType,
    currency: CurrencyIsoCode,
    country: CountryIsoAlpha3Code
}


export type AddBeneficiaryAllFields = AddBeneficiaryBasicDataStepFields & AddBeneficiaryDetailsDataStepFields;


export interface AddBeneficiaryPageStateProps {
    companyId?: NumericId,
    enhancedCurrentLocation: EnhancedLocation,
    context: {

        queryParams?: {
            companyId?: number,
            businessUserId?: number
        }
    },
    newEntityLocation?: string,
    isLoadingAddBeneficiary: boolean
    isLoadingBeneficiaryRequirements: boolean,

}

export interface AddBeneficiaryPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) => void,
    dispatchFetchSupportedCurrencies: ({ queryParams, locationPathname }) => void,
    dispatchClearSupportedCurrencies: (locationPathname) => void,
    dispatchUpdateFormContext: (newData) => void,
}

export type AddBeneficiaryPageProps = AddBeneficiaryPageStateProps & AddBeneficiaryPageDispatchProps & WithTranslation & WithAccessControl;
