import { ApiResponse } from '@manigo/manigo-commons';
import { ScaChallengeRequest, ScaChallengeResponse, NumericId } from '@manigo/manigo-domain-typings';

import { SendConfirmationCodePayload } from 'store/authorisation/actions.types';

import {
    loginInitAPI,
    loginConfirmAPI,
    setNewPasswordAPI,
    requestPasswordResetAPI,
    extendTokenValidityAPI,
    expireTokenAPI, getTokensForDuplicatedSessionAPI, signUpCompanyAPI, activateUserAPI, createScaChallengeAPI, deleteScaChallengeAPI, sendConfirmationCodeAPI,
} from './index';


class AuthorisationService {

    public activateUser(requestPayload) {
        const { acceptToS, ...rest } = requestPayload;
        return activateUserAPI(rest);
    }

    public loginInit(rawRequestPayload) {
        const { email, password } = rawRequestPayload;
        return loginInitAPI({ email, password });
    }

    public loginConfirm(payload): Promise<ApiResponse> {
        const requestPayload = { ...payload, groupPermissions: true };
        return loginConfirmAPI(requestPayload);
    }

    public setNewPassword(requestPayload) {
        return setNewPasswordAPI(requestPayload);
    }

    public requestPasswordReset(requestPayload) {
        return requestPasswordResetAPI(requestPayload);
    }

    public extendTokenValidity(refreshToken): Promise<any> {
        return extendTokenValidityAPI({ refreshToken });
    }

    public getTokensForDuplicatedSession(requestPayload) {
        return getTokensForDuplicatedSessionAPI(requestPayload);
    }

    public signUpCompany(requestPayload) {
        return signUpCompanyAPI(requestPayload);
    }

    public expireToken() {
        return expireTokenAPI();
    }

    public createScaChallenge(requestPayload: ScaChallengeRequest): Promise<ApiResponse<ScaChallengeResponse>> {
        return createScaChallengeAPI(requestPayload);
    }

    public deleteScaChallenge(challengeId: NumericId): Promise<ApiResponse> {
        return deleteScaChallengeAPI(challengeId);
    }

    public sendConfirmationCode(requestPayload: SendConfirmationCodePayload): Promise<ApiResponse> {
        return sendConfirmationCodeAPI(requestPayload);
    }

}

export default AuthorisationService;

