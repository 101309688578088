import styled from 'styled-components';


const AnimatedCrossMarkWrapper = styled.div<{ $size?: string, $colorScheme: string }>`
    svg {
        width: ${({ $size }) => ($size)};
        height: ${({ $size }) => ($size)};
        border-radius: 50%;
        display: block;
        stroke-miterlimit: 10;
        animation: scale .3s ease-in-out .9s both;
        position: relative;
        margin: 0 auto;
        stroke: ${({ $colorScheme }) => ($colorScheme)};
    }
    
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 3px;
        stroke-miterlimit: 10;
        fill: none;
        animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }

    #path {
        stroke-width: 3px;
        stroke-dasharray: 200;
        stroke-dashoffset: 400;
        animation: checker 2.8s linear;
        animation-fill-mode: forwards;
    }

    @keyframes checker {
        from {
            stroke-dashoffset: 320;
        }
        to {
            stroke-dashoffset: 400;
        }
    }


    #path2 {
        stroke-width: 2px;
        stroke-dasharray: 430;
        stroke-dashoffset: 800;
        animation: x 0.6s linear ;
        animation-fill-mode: forwards;
    }

    #path3 {
        stroke-width: 2px;
        stroke-dasharray: 430;
        stroke-dashoffset: 800;
        animation: x 0.6s linear ;
        animation-fill-mode: forwards;
        animation-delay: 0.3s;
    }

    @keyframes x {
        from {
            stroke-dasharray: 430;
        }
        to {
            stroke-dasharray: 400;
        }
    }
    @keyframes stroke {
        100% {
            stroke-dashoffset: 0;
        }
    }

    @keyframes scale {
        0%, 100% {
            transform: none;
        }

        50% {
            transform: scale3d(1.1, 1.1, 1);
        }
    }
`;

export default { AnimatedCrossMarkWrapper };
