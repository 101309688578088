import { createAction } from '@reduxjs/toolkit';

import {
    ADD_BENEFICIARY, ADD_BENEFICIARY_FAILURE,
    ADD_BENEFICIARY_SUCCESS,
    CLEAR_BENEFICIARIES_LIST, CLEAR_BENEFICIARY_REQUIREMENTS,
    FETCH_BENEFICIARIES_LIST,
    FETCH_BENEFICIARIES_LIST_FAILURE,
    FETCH_BENEFICIARIES_LIST_SUCCESS, FETCH_BENEFICIARY_REQUIREMENTS, FETCH_BENEFICIARY_REQUIREMENTS_FAILURE, FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS,
} from './action.types';


export const fetchBeneficiariesList = createAction(FETCH_BENEFICIARIES_LIST, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchBeneficiariesListSuccess = createAction(
    FETCH_BENEFICIARIES_LIST_SUCCESS,
    ({ items, totalCount, locationPathname }) => ({ payload: { items, totalCount, locationPathname } }),
);
export const fetchBeneficiariesListFailure = createAction(FETCH_BENEFICIARIES_LIST_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
export const clearBeneficiariesList = createAction(CLEAR_BENEFICIARIES_LIST, ({ locationPathname }) => ({ payload: { locationPathname } }));


export const fetchBeneficiaryRequirements = createAction(FETCH_BENEFICIARY_REQUIREMENTS, ({ queryParams, locationPathname }) => ({ payload: { queryParams, locationPathname } }));
export const fetchBeneficiaryRequirementsSuccess = createAction(FETCH_BENEFICIARY_REQUIREMENTS_SUCCESS, (key, responseData) => ({ payload: { key, responseData } }));
export const fetchBeneficiaryRequirementsFailure = createAction(FETCH_BENEFICIARY_REQUIREMENTS_FAILURE);

export const clearBeneficiaryRequirements = createAction(CLEAR_BENEFICIARY_REQUIREMENTS);


export const addBeneficiary = createAction(ADD_BENEFICIARY, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const addBeneficiarySuccess = createAction(ADD_BENEFICIARY_SUCCESS, ({ requestPayload, locationPathname }) => ({ payload: { requestPayload, locationPathname } }));
export const addBeneficiaryFailure = createAction(ADD_BENEFICIARY_FAILURE, ({ locationPathname }) => ({ payload: { locationPathname } }));
