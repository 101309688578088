import { Uuid } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';
import { HttpRequestConfig } from 'services/http/httpService.types';

import { OutgoingPaymentPayload, PaymentPayload } from 'store/transaction/actions';


export const getAllTransactionsAPI = (queryParams) => HttpService.get('/v4/transactions', queryParams);

export const getTransactionIdDetails = (transactionUuid: Uuid) => HttpService.get(`/v4/transactions/${transactionUuid}`);

export const createNewPaymentAPI = (
    requestPayload: PaymentPayload | OutgoingPaymentPayload,
    config: HttpRequestConfig,
) => HttpService.post('/v4/payments/create', requestPayload, config);
