import { CardDetails } from '@manigo/manigo-domain-typings';


export const FETCH_CARD_SENSITIVE_DATA = 'FETCH_CARD_SENSITIVE_DATA';
export const FETCH_CARD_SENSITIVE_DATA_SUCCESS = 'FETCH_CARD_SENSITIVE_DATA_SUCCESS';
export const FETCH_CARD_SENSITIVE_DATA_FAILURE = 'FETCH_CARD_SENSITIVE_DATA_FAILURE';

export const RESET_DATA = 'RESET_DATA';

export const COUNTDOWN_TIME = 60000; // ms = 1 minute
export interface FetchCardSensitiveDataPayload {
    queryParams: {
        cardId: CardDetails['id'];
    }
}
