import { isDeepEqual, calculateTimeToSilentTokenRefresh, isDateInTheFuture } from '@manigo/manigo-commons';
import { ScaChallengeMethod, ScaNotificationProtocol } from '@manigo/manigo-domain-typings';
import { ofType } from 'redux-observable';
import { from, map, of, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

import { ModalName } from 'models/app/modal';
import { Epic } from 'models/meta/epic';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import { hideModal, showModal } from 'store/modal/actions';
import { clearAllPasscodeDigits } from 'store/otp/actions';

import { deleteScaChallengeSuccess, createScaChallengeFailure, createScaChallengeSuccess, deleteScaChallengeFailure, deleteScaChallenge } from './actions';
import { CREATE_SCA_CHALLENGE, SUBMIT_SCA_CHALLENGE, DELETE_SCA_CHALLENGE, CREATE_SCA_CHALLENGE_SUCCESS } from './actions.types';
import { createScaIsLoadingStateSelector, createSubmitScaChallengeAction } from './epics.helpers';


export const onCreateScaChallenge: Epic = (action$, state$, { i18n, authorisation }) => {
    return action$.pipe(
        ofType(CREATE_SCA_CHALLENGE),
        switchMap(({ payload: { sourceRequestData, ...rest } }) => {
            const commonActions = [
                clearAllPasscodeDigits(),
                showModal({
                    modalType: ModalName.SCA_CHALLENGE,
                    modalProps: {
                        title: i18n.t('sca:scaModalTitle'),
                        data: {
                            isLoadingStateSelector: createScaIsLoadingStateSelector(rest.type),
                            sourceRequestData,
                        },
                    },
                }),
            ];

            // challenge for give type & payload exists
            // and it is still valid
            if (state$.value.scaChallenge.challenge?.id
                && isDeepEqual(state$.value.scaChallenge.sourceRequestData, sourceRequestData)
                && state$.value.scaChallenge.type === rest.type
                && state$.value.scaChallenge.targetId === rest.targetId
                && isDateInTheFuture(state$.value.scaChallenge.challenge?.expiresAt)
            ) {
                return of(
                    createScaChallengeSuccess({ ...state$.value.scaChallenge }),
                    ...commonActions,
                );
            } else {
                return from(authorisation.createScaChallenge({
                    ...rest,
                    method: ScaChallengeMethod.MFA,
                    notificationProtocol: ScaNotificationProtocol.EMAIL,
                    sourceId: state$.value.currentUser.userData?.identifiers.businessUserId,
                }))
                    .pipe(
                        switchMap((response) => {
                            return of(
                            // Delete old challenge
                                ...(state$.value.scaChallenge.challenge?.id ? [deleteScaChallenge(state$.value.scaChallenge.challenge.id)] : []),
                                createScaChallengeSuccess({
                                    ...rest,
                                    method: ScaChallengeMethod.MFA,
                                    notificationProtocol: ScaNotificationProtocol.EMAIL,
                                    sourceId: state$.value.currentUser.userData?.identifiers.businessUserId,
                                    sourceRequestData,
                                    ...response.data,
                                }),
                                ...commonActions,
                            );
                        }),
                        catchError(() => of(createScaChallengeFailure())),
                    );
            }
        }),
    );
};


export const onReceivingScaChallenge: Epic = (action$) => {
    return action$.pipe(
        ofType(CREATE_SCA_CHALLENGE_SUCCESS),
        switchMap(({ payload }) => {
            const delay = calculateTimeToSilentTokenRefresh(payload.challenge.expiresAt, 0);
            return timer(delay).pipe(
                takeUntil(action$.pipe(ofType(CLEAR_CURRENT_USER, DELETE_SCA_CHALLENGE))),
                map(() => deleteScaChallenge(payload.challenge?.id)),
            );
        }),
    );
};

export const onSubmitScaChallenge: Epic = (action$) => {
    return action$.pipe(
        ofType(SUBMIT_SCA_CHALLENGE),
        switchMap(({ payload }) => of(
            hideModal(),
            ...createSubmitScaChallengeAction(payload),
        )),
    );
};


export const onClearScaChallenge: Epic = (action$, state$, { authorisation }) => {
    return action$.pipe(
        ofType(DELETE_SCA_CHALLENGE),
        switchMap(({ payload }) => from(authorisation.deleteScaChallenge(payload))
            .pipe(
                switchMap(() => of(deleteScaChallengeSuccess())),
                catchError(() => of(deleteScaChallengeFailure())),
            )),
    );
};

export default [
    onCreateScaChallenge,
    onReceivingScaChallenge,
    onSubmitScaChallenge,
    onClearScaChallenge,
];
