import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { standardPageSize } from 'config/config';

import { withTotalCount } from 'services/http/http.helpers';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import { CLEAR_PRODUCTS_LIST, FETCH_PRODUCTS_LIST, FETCH_PRODUCTS_LIST_FAILURE, FETCH_PRODUCTS_LIST_SUCCESS } from './actions.types';
import {
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from '../standardLists.helpers';


export const productsReducerName = 'products';
export const productsListsPerLocationStoreKeyName = 'productsListsPerLocation';

export const accountListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    type: undefined,
    currency: undefined,
    companyId: undefined,
    businessUserId: undefined,
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withTotalCount,
};

const initialState = {
    [productsListsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...accountListDefaultState }
    isLoadingAddAccount: false,
    downloadPdfStatement: { },
};

const createProductsBuilderCases = (builder) =>
    builder
        .addCase(FETCH_PRODUCTS_LIST, (state, action) => {
            state[productsListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[productsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_PRODUCTS_LIST_SUCCESS, (state, action) => {
            state[productsListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[productsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_PRODUCTS_LIST_FAILURE, (state, action) => {
            state[productsListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[productsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_PRODUCTS_LIST, (state, action) => {
            state[productsListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[productsListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);

export default createReducer(initialState, createProductsBuilderCases);
