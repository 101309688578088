import { AccountIdentificationCategory } from '@manigo/manigo-domain-typings';


export const extractDataForEditForm = (rawBeneficiaryDetails) => {
    const {
        firstName,
        middleName,
        lastName,
        companyName,
        currency,
        country,
        address,
        accountIdentifications,
        beneficiaryType,
    } = rawBeneficiaryDetails;


    return {
        firstName,
        middleName,
        lastName,
        companyName,
        currency,
        country,
        address,
        accountIdentifications,
        beneficiaryType,
    };
};

export const parseRawBeneficiariesRequirements = (payload) => {
    const { key, responseData } = payload;

    const beneficiariesRequirements = (responseData || []).reduce((acc, requirement) => {
        if (requirement.identificationCategory === AccountIdentificationCategory.LOCAL) {
            acc[AccountIdentificationCategory.LOCAL] = [
                ...(acc[AccountIdentificationCategory.LOCAL] || []),
                { ...requirement, identificationValue: '' },

            ];
        }
        if (requirement.identificationCategory === AccountIdentificationCategory.GLOBAL) {
            acc[AccountIdentificationCategory.GLOBAL] = [
                ...(acc[AccountIdentificationCategory.GLOBAL] || []),
                { ...requirement, identificationValue: '' },
            ];
        }
        return acc;
    }, {});

    return { [key]: beneficiariesRequirements };
};


export default {
    extractDataForEditForm,
    parseRawBeneficiariesRequirements,
};
