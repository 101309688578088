import { TFunction, ApiError } from '@manigo/manigo-commons';


export const createSignUpErrorMessage = (t: TFunction, error: ApiError) => {
    const handlers = [
        {
            predicate: () => error.messageCode === 'USER_EMAIL_NOT_UNIQUE',
            handler: () => (t('unauthorised:validationMsg.userEmailNotUnique')),
        },
        {
            predicate: () => error.message.startsWith('Company with registration number'),
            handler: () => (t('unauthorised:validationMsg.registrationNumberNotUnique')),
        },
        {
            predicate: () => true,
            handler: () => (t('unauthorised:genericApiError')),
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};
