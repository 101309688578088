import { Spinner, useColorModeValue } from '@chakra-ui/react';
import React, { JSX } from 'react';

import { getDefaultLightDarkValues } from 'utils/theme-tools';

import Styled from './TheSpinner.styled';
import { TheSpinnerProps } from './TheSpinner.types';


function TheSpinner({ thickness }: TheSpinnerProps): JSX.Element {
    const colorScheme = useColorModeValue(...getDefaultLightDarkValues());

    return (
        <Styled.TheSpinnerWrapper className="app-height" data-testid="spinner-overlay">
            <Spinner
                thickness={thickness || '50px'}
                color={colorScheme}
                speed="0.9s"
            />
        </Styled.TheSpinnerWrapper>
    );
}

export default TheSpinner;

