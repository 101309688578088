import { createReducer } from '@reduxjs/toolkit';

import { standardPageSize } from 'config/config';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardListDataQueryParams,
    updateStandardLists,
    updateStandardListsOnCleanup,
    updateStandardListsOnFailure,
    updateStandardListsOnSuccess,
} from 'store/standardLists.helpers';

import {
    CLEAR_STAKEHOLDERS_LIST,
    FETCH_STAKEHOLDERS_LIST,
    FETCH_STAKEHOLDERS_LIST_FAILURE,
    FETCH_STAKEHOLDERS_LIST_SUCCESS,
} from './actions.types';


export const stakeholdersReducerName = 'stakeholders';
export const stakeholdersListsPerLocationStoreKeyName = 'stakeholdersListsPerLocation';


export const defaultStakeholdersListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    withTotal: true,
    // no sorting
};

export const initialStakeholdersListState = { [stakeholdersListsPerLocationStoreKeyName]: {} };

const createStakeholdersBuilderCases = (builder) =>
    builder
        .addCase(FETCH_STAKEHOLDERS_LIST, (state, action) => {
            state[stakeholdersListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[stakeholdersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_STAKEHOLDERS_LIST_SUCCESS, (state, action) => {
            state[stakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[stakeholdersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_STAKEHOLDERS_LIST_FAILURE, (state, action) => {
            state[stakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[stakeholdersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_STAKEHOLDERS_LIST, (state, action) => {
            state[stakeholdersListsPerLocationStoreKeyName] = updateStandardListsOnCleanup({
                listsState: state[stakeholdersListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === stakeholdersReducerName
                        && action.payload?.fieldName === stakeholdersListsPerLocationStoreKeyName
            ) {
                state[stakeholdersListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[stakeholdersListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => initialStakeholdersListState);

export default createReducer(initialStakeholdersListState, createStakeholdersBuilderCases);
