import { enGbLocale } from '@manigo/manigo-commons';

import { BASE_API_URL, SUPPORTED_LANGUAGES, TIME_TO_AUTO_LOGOUT_COUNTDOWN } from './environment';


export const baseApiUrl = BASE_API_URL;

export const localesManifestFileName = 'locales-manifest.json';
export const sessionUuidKey = 'sessionUuid';
export const permissionsKey = 'permissions';
export const companyDetailsKey = 'companyDetails';
export const primaryColorSchemeKey = 'BWP_colorSchemeKey';
export const secondaryColorSchemeKey = 'BWP_secondaryColorSchemeKey';
export const clientConfigKey = 'clientConfig';
export const localesManifestKey = 'localesManifest';
export const userPreferencesKey = 'preferences';
export const appHistoryTrace = 'appHistoryTrace';
export const rememberMeLoginForm = 'rememberMe';
export const selectedLanguageStorageKay = 'BWP_selectedLanguage';
export const timeToAutoLogoutCountDownBanner = (Number(TIME_TO_AUTO_LOGOUT_COUNTDOWN) || 4) * 60 * 1000; // in milliseconds


export const standardPaginationOptions = [5, 10, 20, 50];

export const standardPageSize = 6;


export const defaultLocale = SUPPORTED_LANGUAGES.find((locale) => locale.default)?.key || enGbLocale;
export const SUM_SUB_IFRAME_ID = 'sumsub-iframe';
