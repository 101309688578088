import { cleanNilValues, ApiError, ApiResponse, convertCamelCaseToSnakeCase, SortDirectionsAPI } from '@manigo/manigo-commons';
import { TransactionDetails, SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';

import { SortDirections } from 'models/app/applicationState';

import { mapTransactionScopeFilterValueToQuery } from 'utils/transactions-tools';

import { addScaHeaders } from 'services/http/http.helpers';
import {
    getTransactionIdDetails,
    getAllTransactionsAPI,
    createNewPaymentAPI,
} from 'services/transactions/index';


class TransactionsService {
    public getTransactionDetails(transactionUuid) {
        return getTransactionIdDetails(transactionUuid);
    }

    public createNewPayment(rawRequestPayload: SubmitScaChallengeActionPayload): Promise<ApiResponse<TransactionDetails> | ApiError> {
        const config = addScaHeaders(rawRequestPayload, { noErrorHandling: true });
        return createNewPaymentAPI(rawRequestPayload.sourceRequestData, config);
    }


    public getAllTransactions(rawQueryParams) {
        const {
            limit = 10,
            offset = 0,
            status,
            cardId,
            accountId,
            beneficiaryId,
            businessUserId,
            companyId,
            currency,
            sortBy,
            sortOrder,
            showCardVerification,
            transactionScope,
        } = rawQueryParams || {};

        const transactionScopeQueryParams = mapTransactionScopeFilterValueToQuery(transactionScope);
        const clearedQueryParams = cleanNilValues({
            limit,
            offset,
            sortBy: sortBy ? convertCamelCaseToSnakeCase(sortBy) : undefined,
            sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}`,
            accountId,
            beneficiaryId,
            cardId,
            businessUserId,
            companyId,
            status,
            currency,
            showCardVerification,
            transactionType: transactionScopeQueryParams.transactionType,
            transactionScope: transactionScopeQueryParams.transactionScope,
        });

        const queryParams = { params: { ...clearedQueryParams } };
        return getAllTransactionsAPI(queryParams);
    }
}

export default TransactionsService;
