import { createReducer } from '@reduxjs/toolkit';

import { addLoadingState } from 'utils/redux-tools';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'store/standardEntityDetails.helpers';


import {
    CLEAR_TRANSACTION_DETAILS,
    FETCH_TRANSACTION_DETAILS,
    FETCH_TRANSACTION_DETAILS_FAILURE,
    FETCH_TRANSACTION_DETAILS_SUCCESS,
    CREATE_PAYMENT,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_FAILURE,
} from './action.types';


export const transactionReducerName = 'transaction';
export const transactionDetailsPerLocationStoreKeyName = 'transactionDetailsPerLocation';


const initialDetailsState = {
    [transactionDetailsPerLocationStoreKeyName]: {}, // X XX 'pathname': {...standard }

    isLoadingUpdateBusinessUser: false,
    isLoadingCreatePayment: false,
};

const initialState = { ...initialDetailsState };

const createTransactionBuilderCases = (builder) => {
    builder
        .addCase(FETCH_TRANSACTION_DETAILS, (state, action) => {
            state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[transactionDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_TRANSACTION_DETAILS_SUCCESS, (state, action) => {
            state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[transactionDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_TRANSACTION_DETAILS_FAILURE, (state, action) => {
            state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[transactionDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_TRANSACTION_DETAILS, (state, action) => {
            state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[transactionDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === transactionReducerName
                && action.payload?.fieldName === transactionDetailsPerLocationStoreKeyName
            ) {
                state[transactionDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[transactionDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);
    addLoadingState([CREATE_PAYMENT, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAILURE], 'isLoadingCreatePayment', builder);
};


export default createReducer(initialState, createTransactionBuilderCases);
