import { createAction } from '@reduxjs/toolkit';

import { ModalProps, ShowModalPayload } from 'models/app/modal';

import { HIDE_MODAL, SET_MODAL_PROPS, SHOW_MODAL } from './actions.types';

/**
 *
 * @param {*} payload { modalType: 'Client-Edit-ModalRoot', modalProps: {} }
 * Action which shows modal
 */
export const showModal = createAction(SHOW_MODAL, ({ modalType, modalProps }: ShowModalPayload) => ({
    payload: {
        modalType,
        modalProps,
    },
}));

export const hideModal = createAction(HIDE_MODAL);

/**
 * Set/Update modal props - use this method to se title, and buttons props
 */
export const setModalProps = createAction(SET_MODAL_PROPS, (modalProps:ModalProps) => ({ payload: modalProps }));

