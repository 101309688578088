import { EnhancedLocation, LocationChangeRequest } from '@manigo/manigo-commons';
import { NumericId } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';

import { WithAccessControl } from 'models/domain/accessControl';

import { AddBeneficiaryDetailsDataStepFields } from '../common-parts/AddEditBeneficiary.types';


export enum EditBeneficiaryFormSteps {
    BASIC_DATA = 'EDIT_BENEFICIARY_BASIC_DATA',
    DETAILS = 'EDIT_BENEFICIARY_DETAILS',
    ACCOUNT_IDENTIFIERS = 'EDIT_BENEFICIARY_ACCOUNT_IDENTIFIERS',
    REVIEW = 'EDIT_BENEFICIARY_REVIEW',
    SUCCESS = 'EDIT_BENEFICIARY_SUCCESS',
}
export const editBeneficiaryFormName = 'edit-beneficiary';


export type EditBeneficiaryAllFields = AddBeneficiaryDetailsDataStepFields;


export interface EditBeneficiaryPageStateProps {
    formValuesPerStep: object,
    companyId?: NumericId,
    enhancedCurrentLocation: EnhancedLocation,
    context: {

        queryParams?: {
            companyId?: number,
            businessUserId?: number
        }
    },
    newEntityLocation?: string,
    isLoadingUpdateBeneficiary: boolean
    isLoadingBeneficiaryRequirements: boolean,
    beneficiaryRequirements: object,

}

export interface EditBeneficiaryPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) => void,
    dispatchFetchSupportedCurrencies: ({ queryParams, locationPathname }) => void,
    dispatchClearSupportedCurrencies: (locationPathname) => void,
    dispatchUpdateFormContext: (newData) => void,
    dispatchFetchBeneficiaryRequirements: ({ queryParams, locationPathname }) => void,
    dispatchSetFormStepData: ({ stepName, stepValues }: { stepName: string, stepValues: object }) => void,
}

export type EditBeneficiaryPageProps = EditBeneficiaryPageStateProps & EditBeneficiaryPageDispatchProps & WithTranslation & WithAccessControl;
