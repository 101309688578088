import { createReducer } from '@reduxjs/toolkit';

import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    CLEAR_ALL_FORM_DATA,
    CLEAR_FORM_CONTEXT, CLEAR_FORM_STEP_DATA,
    CLEAR_MULTI_STEPS_FORM_META_DATA, SET_FORM_STEP_DATA,
    SET_MULTI_STEPS_FORM_META_DATA,
    SET_MULTI_STEPS_FORM_STEP, UPDATE_FORM_CONTEXT,
    SET_NEW_ENTITY_LOCATION,
} from './actions.types';


export const multiStepsFormReducerName = 'multiStepsForm';


// eslint-disable-next-line object-curly-newline
const initialState = {
    availableSteps: [],
    currentStep: undefined,
    formValuesPerStep: {},
    context: {},
    newEntityLocation: undefined,
};

const parseStepsConfiguration = (state, formMetaData) => {
    const stepsConfiguration = formMetaData?.stepsConfiguration;

    // reduce stepsConfiguration to available steps & sub-steps lists
    const parsedData = stepsConfiguration.reduce((acc, stepConfig) => ({ availableSteps: [...acc.availableSteps, stepConfig.stepName] }),
        { ...initialState });

    return {
        ...state,
        ...parsedData,
        ...(!state.currentStep ? { currentStep: parsedData.availableSteps?.[0] } : {}),

    };
};

const createMultiStepsFormBuilderCases = (builder) => {
    builder
        .addCase(SET_MULTI_STEPS_FORM_META_DATA, (state, action) => parseStepsConfiguration(state, action.payload))
        .addCase(CLEAR_MULTI_STEPS_FORM_META_DATA, () => initialState)
        .addCase(SET_MULTI_STEPS_FORM_STEP, (state, action) => {
            const { newCurrentStep } = action.payload;
            return {
                ...state,
                currentStep: newCurrentStep,

            };
        })
        .addCase(UPDATE_FORM_CONTEXT, (state, action) => {
            return {
                ...state,
                context: {
                    ...state.context,
                    ...action.payload,
                },

            };
        })
        .addCase(CLEAR_FORM_CONTEXT, (state) => {
            return {
                ...state,
                context: {},
            };
        })
        .addCase(SET_NEW_ENTITY_LOCATION, (state, action) => {
            return {
                ...state,
                newEntityLocation: action.payload,
            };
        })
        .addCase(SET_FORM_STEP_DATA, (state, action) => {
            const { stepName, stepValues } = action.payload;
            return {
                ...state,
                formValuesPerStep: {
                    ...state.formValuesPerStep,
                    [stepName]: stepValues,
                },

            };
        })
        .addCase(CLEAR_FORM_STEP_DATA, (state, action) => {
            return {
                ...state,
                formValuesPerStep: {
                    ...state.formValuesPerStep,
                    [action.payload]: {},
                },

            };
        })
        .addCase(CLEAR_ALL_FORM_DATA, () => initialState)
        .addCase(CLEAR_CURRENT_USER, () => initialState);
};

export default createReducer(initialState, createMultiStepsFormBuilderCases);
