const envs:any = process.env;

export const BASE_API_URL = envs.BASE_API_URL ?? '';
export const OBAWA_URL = envs.OBAWA_URL ?? undefined;
export const instanceOwnerClientId = envs.CLIENT_ID ?? '';
export const TIME_TO_AUTO_LOGOUT_COUNTDOWN = Number(envs.TIME_TO_AUTO_LOGOUT_COUNTDOWN) ?? '';
export const developmentMode = envs.NODE_ENV === 'development';
export const debugMode = developmentMode;
export const MAINTANCE_MODE_ENABLED = envs.MAINTANCE_MODE_ENABLED === 'true' ?? false;
export const ENABLE_REDUX_LOGGER = envs.ENABLE_REDUX_LOGGER === 'true' ?? false;
export const SENTRY_DSN = envs.SENTRY_DSN ?? '';
export const ENABLE_DEV_TOOLS = envs.ENABLE_DEV_TOOLS === 'true' ?? false;
export const APPLICATION_NAME = envs.APPLICATION_NAME ?? 'Business Web Portal';
export const PERSONAL_APP_AES_KEY = envs.BWP_APP_AES_KEY ?? '';
export const SUPPORTED_LANGUAGES = envs.LOCALES_JSON?.supportedLanguages ?? [];
export const APP_VERSION = envs.APP_VERSION ?? '';
export const APP_BUILD_DATE = envs.APP_BUILD_DATE ?? '';


const wlNames = envs.WL_NAMES;
const wlAssetsURLs = envs.WL_ASSETS_URLS;


export const WL_CONFIG = {
    brandShortName: wlNames?.brandShortName,
    brandFullName: wlNames?.brandFullName,
    applicationName: wlNames?.applicationName,
    brandLandingPageUrl: wlNames?.brandLandingPageUrl,
    brandBaseDomain: wlNames?.brandBaseDomain,
    obawaUrl: OBAWA_URL,

    ...(wlAssetsURLs?.brandFullLogoUrl?.length > 0 ? { brandFullLogoUrl: wlAssetsURLs.brandFullLogoUrl } : {}),
    ...(wlAssetsURLs?.brandFullDarkLogoUrl?.length > 0 ? { brandFullDarkLogoUrl: wlAssetsURLs.brandFullDarkLogoUrl } : {}),

    ...(wlAssetsURLs?.brandSignetLogoUrl?.length > 0 ? { brandSignetLogoUrl: wlAssetsURLs.brandSignetLogoUrl } : {}),
    ...(wlAssetsURLs?.brandSignetDarkLogoUrl?.length > 0 ? { brandSignetDarkLogoUrl: wlAssetsURLs.brandSignetDarkLogoUrl } : {}),

    ...(wlAssetsURLs?.brandAnimatedLoadingPlaceholderUrl?.length > 0 ? { brandAnimatedLoadingPlaceholderUrl: wlAssetsURLs.brandAnimatedLoadingPlaceholderUrl } : {}),
    ...(wlAssetsURLs?.brandFaviconUrl?.length > 0 ? { brandFaviconUrl: wlAssetsURLs.brandFaviconUrl } : {}),

    externalLinks: envs.EXTERNAL_LINKS,
    themeVariables: envs.THEME_VARIABLES,
};

export const getAppVersion = () => ({
    APP_VERSION: envs.APP_VERSION,
    APP_BUILD_DATE: new Date(JSON.parse(envs.APP_BUILD_DATE)).toISOString(),
    GIT_COMMIT_HASH: JSON.parse(envs.GIT_COMMIT_HASH),
    GIT_LAST_COMMIT_DATETIME: JSON.parse(envs.GIT_LAST_COMMIT_DATETIME),
});
