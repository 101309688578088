import { fetchCountriesAPI } from './index';


class CountriesService {
    public fetchCountries() {
        return fetchCountriesAPI();
    }
}

export default CountriesService;
