import { SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';
import { createAction } from '@reduxjs/toolkit';

import {
    CONFIRM_EXCHANGE, CONFIRM_EXCHANGE_FAILURE, CONFIRM_EXCHANGE_SUCCESS,

    FETCH_EXCHANGE_RATE, FETCH_EXCHANGE_RATE_FAILURE, FETCH_EXCHANGE_RATE_SUCCESS,

    INIT_EXCHANGE, INIT_EXCHANGE_FAILURE, INIT_EXCHANGE_SUCCESS,

    REJECT_EXCHANGE, REJECT_EXCHANGE_FAILURE, REJECT_EXCHANGE_SUCCESS,

    UPDATE_BUY_AMOUNT, UPDATE_MAX_SELL_AMOUNT, UPDATE_SELL_AMOUNT, CLEAR_EXCHANGE_DATA,

    ConfirmExchangeRequestPayload,

    FetchExchangeRateRequestPayload, FetchExchangeRateResponsePayload,

    InitExchangeRequestPayload, InitExchangeResponsePayload,

    RejectExchangeRequestPayload,

    UpdateBuyAmountPayload, UpdateMaxSellAmountPayload, UpdateSellAmountPayload, CLEAR_INIT_EXCHANGE_INFO,
} from './actions.types';


export const updateSellAmount = createAction(UPDATE_SELL_AMOUNT, ({ queryParams }: UpdateSellAmountPayload) => ({ payload: { queryParams } }));
export const updateMaxSellAmount = createAction(UPDATE_MAX_SELL_AMOUNT,
    ({ queryParams }: UpdateMaxSellAmountPayload) => ({ payload: { queryParams } }));
export const updateBuyAmount = createAction(UPDATE_BUY_AMOUNT, ({ queryParams }: UpdateBuyAmountPayload) => ({ payload: { queryParams } }));
export const clearExchangeData = createAction(CLEAR_EXCHANGE_DATA);

export const initExchange = createAction(INIT_EXCHANGE, ({ requestPayload }: InitExchangeRequestPayload) => ({ payload: { requestPayload } }));
export const initExchangeSuccess = createAction(INIT_EXCHANGE_SUCCESS, ({ responsePayload }: InitExchangeResponsePayload) => ({ payload: { responsePayload } }));
export const initExchangeFailure = createAction(INIT_EXCHANGE_FAILURE);
export const clearInitExchangeInfo = createAction(CLEAR_INIT_EXCHANGE_INFO);

export const confirmExchange = createAction(CONFIRM_EXCHANGE, (requestPayload: SubmitScaChallengeActionPayload<ConfirmExchangeRequestPayload>) => ({ payload: requestPayload }));
export const confirmExchangeSuccess = createAction(CONFIRM_EXCHANGE_SUCCESS);
export const confirmExchangeFailure = createAction(CONFIRM_EXCHANGE_FAILURE);


export const rejectExchange = createAction(REJECT_EXCHANGE, (requestPayload: RejectExchangeRequestPayload) => ({ payload: requestPayload }));
export const rejectExchangeSuccess = createAction(REJECT_EXCHANGE_SUCCESS);
export const rejectExchangeFailure = createAction(REJECT_EXCHANGE_FAILURE);


export const fetchExchangeRate = createAction(FETCH_EXCHANGE_RATE, ({ queryParams }: FetchExchangeRateRequestPayload) => ({ payload: { queryParams } }));
export const fetchExchangeRateSuccess = createAction(FETCH_EXCHANGE_RATE_SUCCESS,
    ({ responsePayload }): FetchExchangeRateResponsePayload => ({ payload: { exchangeRates: responsePayload.exchangeRates } }));
export const fetchExchangeRateFailure = createAction(FETCH_EXCHANGE_RATE_FAILURE);
