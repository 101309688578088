import { ApiResponse } from '@manigo/manigo-commons';
import { AccountDetails } from '@manigo/manigo-domain-typings';

import { SetQueryParamsPayload } from 'models/app/queryParams';
import { FetchEntityDetailsFailurePayload, FetchEntityDetailsPayload, FetchEntityDetailsSuccessPayload } from 'models/app/standardEntityDetails';


export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS';
export const FETCH_ACCOUNT_DETAILS_SUCCESS = 'FETCH_ACCOUNT_DETAILS_SUCCESS';
export const FETCH_ACCOUNT_DETAILS_FAILURE = 'FETCH_ACCOUNT_DETAILS_FAILURE';
export const CLEAR_ACCOUNT_DETAILS = 'CLEAR_ACCOUNT_DETAILS';

export interface FetchAccountDetailsQueryParams {
    accountId: AccountDetails['id'],
}

export type FetchAccountDetailsPayload = FetchEntityDetailsPayload<FetchAccountDetailsQueryParams>;
export type FetchAccountDetailsSuccessPayload = FetchEntityDetailsSuccessPayload<AccountDetails>;
export type FetchAccountDetailsFailurePayload = FetchEntityDetailsFailurePayload;

export type SetAccountDetailsQueryParamsPayload = SetQueryParamsPayload<FetchAccountDetailsQueryParams>;


export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export interface DeleteAccountPayload {
    queryParams: {
        accountId: AccountDetails['id'],
    },
    locationPathname: string,
}

export interface DeleteAccountSuccessPayload {
    responsePayload: ApiResponse<null>
}

export const SET_AS_PRIMARY_ACCOUNT = 'SET_AS_PRIMARY_ACCOUNT';
export const SET_AS_PRIMARY_ACCOUNT_SUCCESS = 'SET_AS_PRIMARY_ACCOUNT_SUCCESS';
export const SET_AS_PRIMARY_ACCOUNT_FAILURE = 'SET_AS_PRIMARY_ACCOUNT_FAILURE';

export interface SetAsPrimaryAccountPayload {
    queryParams: {
        accountId: AccountDetails['id'],
    },
    locationPathname: string,
}

export const UPDATE_ACCOUNT_NAME = 'UPDATE_ACCOUNT_NAME';
export const UPDATE_ACCOUNT_NAME_SUCCESS = 'UPDATE_ACCOUNT_NAME_SUCCESS';
export const UPDATE_ACCOUNT_NAME_FAILURE = 'UPDATE_ACCOUNT_NAME_FAILURE';

export interface UpdateAccountNamePayload {
    queryParams: {
        accountId: AccountDetails['id'],
        name: AccountDetails['name'],
    },
    locationPathname: string,
}

export const SHOW_DOWNLOAD_PDF_STATEMENT_FORM = 'SHOW_DOWNLOAD_PDF_STATEMENT_FORM';
export interface ShowDownloadPdfStatementFormPayload {
    accountId: AccountDetails['id'],
}
export const HIDE_DOWNLOAD_PDF_STATEMENT_FORM = 'HIDE_DOWNLOAD_PDF_STATEMENT_FORM';
export interface HideDownloadPdfStatementFormPayload {
    accountId: AccountDetails['id'],
}

export const DOWNLOAD_ACCOUNT_STATEMENT_PDF = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_SUCCESS';
export const DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE = 'DOWNLOAD_ACCOUNT_STATEMENT_PDF_FAILURE';

export interface DownloadAccountStatementPdfPayload {
    queryParams: {
        accountId: AccountDetails['id'],
        currency: AccountDetails['currency'],
        dateFrom: string,
        dateTo: string,
    }
}

export interface DownloadAccountStatementPdfSuccessPayload {
    accountId: AccountDetails['id'],
}

export interface DownloadAccountStatementPdfFailurePayload {
    accountId: AccountDetails['id'],
}
