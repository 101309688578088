import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { beneficiariesListRootRoutePath } from 'config/routes';

import { showToast } from 'store/application/actions';
import { beneficiariesReducerName } from 'store/beneficiaries/reducer';
import {
    deleteBeneficiaryFailure, deleteBeneficiarySuccess,
    fetchBeneficiaryDetailsFailure, fetchBeneficiaryDetailsSuccess,
    updateBeneficiaryFailure, updateBeneficiarySuccess,
} from 'store/beneficiary/actions';
import { updateList } from 'store/list/actions';
import { requestSetMultiStepsFormStep } from 'store/multi-steps-form/actions';
import { requestNavigation } from 'store/navigation/actions';

import { EditBeneficiaryFormSteps } from 'components/pages/authorised/AddEditBeneficiary/EditBeneficiaryPage/EditBeneficiaryPage.types';

import { DELETE_BENEFICIARY, FETCH_BENEFICIARY_DETAILS, UPDATE_BENEFICIARY } from './actions.types';


export const onFetchBeneficiaryDetails: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(FETCH_BENEFICIARY_DETAILS),
    switchMap(({ payload }) => from(beneficiaries.fetchBeneficiaryDetails(payload?.queryParams?.beneficiaryUuid)).pipe(
        switchMap((response) => of(fetchBeneficiaryDetailsSuccess({
            responsePayload: response.data,
            locationPathname: payload?.locationPathname,
        }))),
        catchError(() => of(fetchBeneficiaryDetailsFailure({ locationPathname: payload?.locationPathname }))),
    )),
);


export const onUpdateBeneficiary: Epic = (action$, state$, { beneficiaries }) => action$.pipe(
    ofType(UPDATE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.updateBeneficiary(payload.beneficiaryUuid, payload.requestPayload)).pipe(
        switchMap((response) => {
            return of(
                updateBeneficiarySuccess({
                    responsePayload: response.data,
                    locationPathname: payload?.locationPathname,
                }),
                requestSetMultiStepsFormStep(
                    EditBeneficiaryFormSteps.REVIEW,
                    MultiStepFormStepChangeDirections.FORWARD,
                ),
                updateList({
                    findByPropName: 'id',
                    findByPropValue: payload.beneficiaryUuid,
                    listReducerName: beneficiariesReducerName,
                }),
            );
        }),
        catchError(() => of(updateBeneficiaryFailure({ locationPathname: payload?.locationPathname }))),
    )),
);

export const onDeleteBeneficiary: Epic = (action$, state$, { beneficiaries, i18n }) => action$.pipe(
    ofType(DELETE_BENEFICIARY),
    switchMap(({ payload }) => from(beneficiaries.deleteBeneficiary(payload.requestPayload)).pipe(
        switchMap(() => {
            return of(
                showToast({
                    type: ToastType.success,
                    message: i18n.t('beneficiaries:actionMessages.deleteBeneficiarySuccess'),
                }),
                deleteBeneficiarySuccess(),
                requestNavigation({ locationPathname: beneficiariesListRootRoutePath, meta: { replace: true } }),
            );
        }),
        catchError(() => of(deleteBeneficiaryFailure())),
    )),
);


export default [
    onFetchBeneficiaryDetails,
    onUpdateBeneficiary,
    onDeleteBeneficiary,
];

