import { getSessionStorageObjectItem, setSessionStorageObjectItem, generateSessionUuid } from '@manigo/manigo-commons';
import React from 'react';
import { of } from 'rxjs';

import { Dependencies } from 'models/app/store';
import { ToastType } from 'models/app/toast';

import { sessionUuidKey } from 'config/config';
import { activateUserRootRoutePath, setPasswordRootRoutePath } from 'config/routes';


import { getJwtTokenFormStorage, setSessionUuid } from 'store/application/actions';
import { getLocalesManifest } from 'store/locales/actions';
import { setSearchParam } from 'store/navigation/actions';

import AnimatedCheckMark from 'components/common/atoms/AnimatedCheckMark';
import AnimatedCrossMark from 'components/common/atoms/AnimatedCrossMark';


export function getIcon(type: string) {
    const handlers = [
        {
            predicate: () => type === ToastType.success,
            handler: () => <AnimatedCheckMark size={'24px'} />,
        },
        {
            predicate: () => type === ToastType.error,
            handler: () => <AnimatedCrossMark size={'24px'} />,
        },
    ];
    return handlers.find(({ predicate }) => predicate())?.handler() || null;
}


export function createAppInitActionsAndSideEffects(httpService: Dependencies['http'], history: Dependencies['history']) {
    const existingSessionUuid = getSessionStorageObjectItem(sessionUuidKey);
    const newSessionUuid = generateSessionUuid();

    // @ts-expect-error type is valid!
    const pageDuplicated = window.performance.getEntriesByType('navigation').map((nav) => nav.type.includes('back_forward'));


    const setUuid = (uuid) => {
        httpService.setSessionUuid(uuid); // axios instance
        setSessionStorageObjectItem(sessionUuidKey, uuid); // session storage
    };

    const handlers = [
        { /* sessionUuid from tab that invoked reset (for safety that source tab's uuid was changed on success )  */
            predicate: () => [setPasswordRootRoutePath, activateUserRootRoutePath].includes(history.location.pathname),
            handler: () => {
                const params = new URLSearchParams(history.location.search);
                const sessionUuidFromLink = params.get('session-uuid');
                const searchParam = history.location.search;
                setUuid(sessionUuidFromLink);
                history.replace(history.location.pathname, history.location.state);

                return of(
                    setSessionUuid(sessionUuidFromLink as string),
                    setSearchParam(searchParam),
                    getLocalesManifest(),
                    // XXX we ignore JWT tokens in session storage in this case
                );
            },
        },
        { /* duplicated tab (except Safari & FF - new tab will be unauthorised) */
            predicate: () => existingSessionUuid && pageDuplicated && pageDuplicated[0],
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    getLocalesManifest(),
                    setSessionUuid(newSessionUuid),
                    getJwtTokenFormStorage(existingSessionUuid), // XXX with param!
                );
            },
        },
        { /*  tab reloaded or standard navigation caused by URL change */
            predicate: () => existingSessionUuid,
            handler: () => {
                setUuid(existingSessionUuid);
                return of(
                    getLocalesManifest(),
                    setSessionUuid(existingSessionUuid),
                    getJwtTokenFormStorage(),
                );
            },
        },

        { /* standard new tab */
            predicate: () => true,
            handler: () => {
                setUuid(newSessionUuid);
                return of(
                    setSessionUuid(newSessionUuid),
                    getLocalesManifest(),
                    getJwtTokenFormStorage(),
                );
            },
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
}


export default { createAppInitActionsAndSideEffects };

