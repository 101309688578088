import { NumericId } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';


export const createBusinessUserForCompanyId = (companyId: NumericId, requestPayload: object) => HttpService.post(`/v3/companies/${companyId}/business-user`,
    requestPayload,
    { noErrorHandling: true });

export const getCompanyIdBusinessUsers = (queryParams: object) => HttpService.get('/v3/business-users', queryParams);


export const getCompanyIdDetails = (companyId: NumericId) => HttpService.get(`/v3/companies/${companyId}`);

export const updateCompanyId = (companyId: NumericId, requestPayload: object) => HttpService.patch(`/v4/companies/${companyId}`, requestPayload);

export const getStakeholdersAPI = (companyId, queryParams) => HttpService.get(`/v2/companies/${companyId}/stakeholders`, queryParams);
