import { createReducer } from '@reduxjs/toolkit';

import { ShowConfirmationPayload } from './actions';
import { HIDE_CONFIRMATION_ALERT, SHOW_CONFIRMATION_ALERT } from './actions.types';


export const alertReducerName = 'alert';
export interface AlertStateType {
        readonly showConfirmation: boolean;
        readonly confirmationConfig: ShowConfirmationPayload;

}

const initialState: AlertStateType = {
    confirmationConfig: {},
    showConfirmation: false,
};

const alertBuilderCases = (builder) => builder
    .addCase(SHOW_CONFIRMATION_ALERT, (state, { payload }) => {
        return {
            confirmationConfig: payload,
            showConfirmation: true,
        };
    })
    .addCase(HIDE_CONFIRMATION_ALERT, () => {
        return { ...initialState };
    });

export default createReducer(initialState, alertBuilderCases);
