import { combineReducers } from '@reduxjs/toolkit';

import accountReducer, { accountReducerName } from 'store/account/reducer';
import accountsReducer, { accountsReducerName } from 'store/accounts/reducer';
import alertReducer, { alertReducerName } from 'store/alert/reducer';
import applicationReducer, { applicationReducerName } from 'store/application/reducer';
import authorisationReducer, { authorisationReducerName } from 'store/authorisation/reducer';
import beneficiariesReducer, { beneficiariesReducerName } from 'store/beneficiaries/reducer';
import beneficiaryReducer, { beneficiaryReducerName } from 'store/beneficiary/reducer';
import businessUserReducer, { businessUserReducerName } from 'store/business-user/reducer';
import businessUsersReducer, { businessUsersReducerName } from 'store/business-users/reducer';
import cardReducer, { cardReducerName } from 'store/card/reducer';
import cardSensitiveDataReducer, { cardSensitiveDataReducerName } from 'store/card-sensitive-data/reducer';
import cardsReducer, { cardsReducerName } from 'store/cards/reducer';
import companyReducer, { companyReducerName } from 'store/company/reducer';
import countriesReducer, { countriesReducerName } from 'store/countries/reducer';
import currencyReducer, { currenciesReducerName } from 'store/currencies/reducer';
import currentUserReducer, { currentUserReducerName } from 'store/current-user/reducer';
import exchangeReducer, { exchangeReducerName } from 'store/exchange/reducer';
import kybKycReducer, { kybKycReducerName } from 'store/kyb-kyc/reducer';
import listReducer, { listReducerName } from 'store/list/reducer';
import localesReducer, { localesReducerName } from 'store/locales/reducer';
import modalReducer, { modalReducerName } from 'store/modal/reducer';
import multiStepsFormReducer, { multiStepsFormReducerName } from 'store/multi-steps-form/reducer';
import navigationReducer, { navigationReducerName } from 'store/navigation/reducer';
import otpReducer, { otpReducerName } from 'store/otp/reducer';
import productsReducer, { productsReducerName } from 'store/products/reducer';
import scaChallengeReducer, { scaChallengeReducerName } from 'store/sca-challange/reducer';
import stakeholdersReducer, { stakeholdersReducerName } from 'store/stakeholders/reducer';
import supportedCurrenciesReducer, { supportedCurrenciesReducerName } from 'store/supported-currencies/reducer';
import transactionReducer, { transactionReducerName } from 'store/transaction/reducer';
import transactionsReducer, { transactionsReducerName } from 'store/transactions/reducer';


const rootReducer = combineReducers({
    // XXX very, very important reducers
    [applicationReducerName]: applicationReducer,
    [currentUserReducerName]: currentUserReducer,
    [navigationReducerName]: navigationReducer,
    [authorisationReducerName]: authorisationReducer,

    // screen/entity/controller specific reducers
    [accountReducerName]: accountReducer,
    [accountsReducerName]: accountsReducer,
    [alertReducerName]: alertReducer,

    [businessUserReducerName]: businessUserReducer,
    [businessUsersReducerName]: businessUsersReducer,
    [beneficiariesReducerName]: beneficiariesReducer,
    [beneficiaryReducerName]: beneficiaryReducer,

    [cardReducerName]: cardReducer,
    [cardSensitiveDataReducerName]: cardSensitiveDataReducer,
    [cardsReducerName]: cardsReducer,
    [companyReducerName]: companyReducer,
    [countriesReducerName]: countriesReducer,
    [currenciesReducerName]: currencyReducer,

    [exchangeReducerName]: exchangeReducer,

    [kybKycReducerName]: kybKycReducer,
    [localesReducerName]: localesReducer,
    [listReducerName]: listReducer,
    [modalReducerName]: modalReducer,
    [multiStepsFormReducerName]: multiStepsFormReducer,
    [otpReducerName]: otpReducer,
    [productsReducerName]: productsReducer,
    [stakeholdersReducerName]: stakeholdersReducer,
    [scaChallengeReducerName]: scaChallengeReducer,

    [transactionReducerName]: transactionReducer,
    [transactionsReducerName]: transactionsReducer,

    [supportedCurrenciesReducerName]: supportedCurrenciesReducer,

});


export type RootState = ReturnType<typeof rootReducer>;


export { rootReducer };
