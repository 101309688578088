import { createBrowserHistory, i18next } from '@manigo/manigo-commons';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { Dependencies } from 'models/app/store';

import { getAppVersion, debugMode } from 'config/environment';
import initializeSentry from 'config/sentry';

import FileService from 'services/FileService';
import AccountsService from 'services/accounts/AccountsService';
import AuthorisationService from 'services/authorisation/AuthorisationService';
import BeneficiariesService from 'services/beneficiaries/BeneficiariesService';
import BusinessUsersService from 'services/businessUsers/BusinessUsersService';
import CardsService from 'services/cards/CardsService';
import ClientsService from 'services/clients/ClientsService';
import CompaniesService from 'services/companies/CompaniesService';
import CountriesService from 'services/countries/CountriesService';
import CurrenciesService from 'services/currencies/CurrenciesService';
import ExchangeService from 'services/exchange/ExchangeService';
import { createHttpService, HttpService } from 'services/http';
import KybKycService from 'services/kyb-kyc/KybKycService';
import LocationService from 'services/location/LocationService';
import ReportsService from 'services/reports/ReportsService';
import TransactionsService from 'services/transactions/TransactionsService';

import { initApplication } from 'store/application/actions';
import { createStore } from 'store/createStore';

import CustomRouter from 'components/App/CustomRouter.layout';
import Main from 'components/App/Main';

import { browserWindow } from './models/meta/window';


browserWindow.applicationVersionData = () => {
    // eslint-disable-next-line no-console
    console.dir(getAppVersion());
};

const wrapper = document.getElementById('root') as HTMLDivElement;
const root = createRoot(wrapper!);

const http = createHttpService();

const history = createBrowserHistory({ window });
const location = new LocationService(history, window.location);

// error tracker
initializeSentry(history);
// redux-observables (RxJS) middleware dependencies
const dependencies: Dependencies = {
    document,
    history,
    i18n: i18next,
    http,
    location,

    authorisation: new AuthorisationService(),
    accounts: new AccountsService(),
    cards: new CardsService(),
    clients: new ClientsService(),
    companies: new CompaniesService(),
    countries: new CountriesService(),
    currencies: new CurrenciesService(),
    beneficiaries: new BeneficiariesService(),
    businessUsers: new BusinessUsersService(),
    exchanges: new ExchangeService(),
    kybKyc: new KybKycService(),
    file: new FileService(),
    reports: new ReportsService(),
    transactions: new TransactionsService(),

};
// redux store initialisation
const { store } = createStore(dependencies);

export type StoreDispatch = typeof store.dispatch;

store.dispatch(initApplication());
HttpService.configure(store.dispatch, i18next);


if (browserWindow?.Cypress) {
    browserWindow.store = store;
}


const main = debugMode
    ? <React.StrictMode><Main /></React.StrictMode>
    : <Main />;


root.render(
    <>
        <Provider store={store}>
            <CustomRouter history={history}>
                {main}
            </CustomRouter>
        </Provider>
    </>,
);
