import { calculateTimeToSilentTokenRefresh, jwtDecode, getSessionStorageObjectItem, setSessionStorageObjectItem } from '@manigo/manigo-commons';
import {
    Timestamp,
    BusinessUserSpecificStatus,
    CompanyStatus,
    UserPreferences,
    UserPreferencesDecimalSeparators,
    UserPreferencesThousandsSeparators,
    UserPreferencesTimeFormat,
    UserPreferencesDateFormat,
    UserDataFormToken,
    RawJwtToken,
} from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';

import { companyDetailsKey, defaultLocale, userPreferencesKey } from 'config/config';

import { addLoadingState } from 'utils/redux-tools';

import { UPDATE_USER_SUCCESS } from 'store/business-user/action.types';
import { FETCH_COMPANY_DETAILS_SUCCESS } from 'store/company/actions.types';

import {
    CLEAR_CURRENT_USER,
    EXTEND_TOKEN_VALIDITY_SUCCESS, SET_CURRENT_USER,
    SET_CURRENT_USER_SUCCESS,

    UPDATE_CURRENT_USER_PASSWORD,
    UPDATE_CURRENT_USER_PASSWORD_FAILURE,
    UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
    UPDATE_CURRENT_USER_PREFERENCES,
    UPDATE_CURRENT_USER_PREFERENCES_FAILURE,
    UPDATE_CURRENT_USER_PREFERENCES_SUCCESS,
} from './actions.types';
import SET_CURRENT_USER_VARIANT from './epics.helpers';


export const currentUserReducerName = 'currentUser';

export interface CurrentUserStateType {
    readonly jwtToken?: RawJwtToken;
    readonly accessTokenExpirationTimeStamp?: Timestamp;
    readonly refreshTokenExpirationTimeStamp?: Timestamp;
    readonly userData?: UserDataFormToken;
    readonly clientConfig?: any;
    readonly permissions?: any;

    readonly userPreferences: UserPreferences;


    readonly isLoadingUpdateCurrentUser: boolean,
    readonly isLoadingUpdateCurrentUserPassword: boolean,
    readonly isLoadingUpdateCurrentUserPreferences: boolean,
    readonly timeToAccessTokenRefresh?: number;
    readonly lockUserInKybKycOnboarding: boolean,
    readonly variant?: SET_CURRENT_USER_VARIANT,
}

export const currenciesInitialState: CurrentUserStateType = {
    lockUserInKybKycOnboarding: false,
    jwtToken: undefined,
    accessTokenExpirationTimeStamp: undefined,
    refreshTokenExpirationTimeStamp: undefined,

    userData: undefined,
    clientConfig: undefined,
    permissions: undefined,
    userPreferences: {
        timeFormat: UserPreferencesTimeFormat.HOURS_24,
        dateFormat: UserPreferencesDateFormat.YEAR_MONTH_DAY_DASH,
        language: defaultLocale,
        thousandsSeparator: UserPreferencesThousandsSeparators.COMMA,
        decimalSeparator: UserPreferencesDecimalSeparators.DOT,
    },

    isLoadingUpdateCurrentUser: false,
    isLoadingUpdateCurrentUserPassword: false,
    isLoadingUpdateCurrentUserPreferences: false,
    timeToAccessTokenRefresh: undefined,
    variant: undefined,
};


const handleNewToken = (state, data) => {
    const {
        accessToken,
        expirationTimeOfAccessToken,
        expirationTimeOfRefreshToken,
        company,
    } = data;

    const decodedUserData: UserDataFormToken = jwtDecode(accessToken);
    state.jwtToken = accessToken;
    state.accessTokenExpirationTimeStamp = new Date(expirationTimeOfAccessToken).getTime();
    state.refreshTokenExpirationTimeStamp = new Date(expirationTimeOfRefreshToken).getTime();
    state.timeToAccessTokenRefresh = calculateTimeToSilentTokenRefresh(expirationTimeOfAccessToken);
    state.userData = decodedUserData;

    if (decodedUserData?.user?.documentsVerificationStatus !== BusinessUserSpecificStatus.ACTIVE
        || ![CompanyStatus.ACTIVE, CompanyStatus.DORMANT].includes(company?.companyStatus)) {
        state.lockUserInKybKycOnboarding = true;
    }
    if (decodedUserData?.user?.documentsVerificationStatus === BusinessUserSpecificStatus.ACTIVE
        && [CompanyStatus.ACTIVE, CompanyStatus.DORMANT].includes(company?.companyStatus)) {
        state.lockUserInKybKycOnboarding = false;
    }

    // TODO refactor this using redux-persist
    // XXX bit tricky part... you get user preferences on login, you need to store it because of page reload or navigation scenarios
    // ...but you need to update it when successful (self)user edition occurs
    // so although it is a data duplication IMHO it is safer/cleaner to move it to separate state & session object key
    if (decodedUserData?.preferences) {
        state.userPreferences = decodedUserData.preferences;
    } else {
    // getSessionStorageObjectItem()
    }

    setSessionStorageObjectItem(userPreferencesKey, decodedUserData.preferences);
};

const createCurrentUserBuilderCases = (builder) => {
    builder
        .addCase(SET_CURRENT_USER, (state, action) => {
            const variant = action.payload?.variant;
            state.variant = variant;
        })
        .addCase(SET_CURRENT_USER_SUCCESS, (state, action) => {
            handleNewToken(state, action.payload);
            state.permissions = action.payload.permissions;
            state.clientConfig = action.payload.configuration;
        })
        .addCase(EXTEND_TOKEN_VALIDITY_SUCCESS, (state, action) => {
            handleNewToken(state, {
                ...action.payload,
                company: getSessionStorageObjectItem(companyDetailsKey),
            });
        })
        .addCase(UPDATE_USER_SUCCESS, (state, action) => {
            const { responsePayload } = action.payload;

            if (responsePayload.id === state.userData.identifiers.id) {
                state.userData.user.email = responsePayload.email;
                state.userData.user.phoneNumber = responsePayload.phoneNumber;
            }
        })
        .addCase(UPDATE_CURRENT_USER_PREFERENCES, (state) => {
            state.isLoadingUpdateCurrentUserPreferences = true;
        })
        .addCase(UPDATE_CURRENT_USER_PREFERENCES_SUCCESS, (state, action) => {
            setSessionStorageObjectItem(userPreferencesKey, action.payload.userPreferences);
            state.userPreferences = action.payload.userPreferences;
            state.isLoadingUpdateCurrentUserPreferences = false;
        })
        .addCase(UPDATE_CURRENT_USER_PREFERENCES_FAILURE, (state) => {
            state.isLoadingUpdateCurrentUserPreferences = false;
        })
        .addCase(FETCH_COMPANY_DETAILS_SUCCESS, (state, action) => {
            if (state.userData.user.documentsVerificationStatus !== BusinessUserSpecificStatus.ACTIVE
                || ![CompanyStatus.ACTIVE, CompanyStatus.DORMANT].includes(action.payload?.responsePayload?.companyStatus)) {
                state.lockUserInKybKycOnboarding = true;
            }
            if (state.userData.user.documentsVerificationStatus === BusinessUserSpecificStatus.ACTIVE
                && [CompanyStatus.ACTIVE, CompanyStatus.DORMANT].includes(action.payload?.responsePayload?.companyStatus)) {
                state.lockUserInKybKycOnboarding = false;
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => currenciesInitialState);
    addLoadingState([UPDATE_CURRENT_USER_PASSWORD, UPDATE_CURRENT_USER_PASSWORD_SUCCESS, UPDATE_CURRENT_USER_PASSWORD_FAILURE],
        'isLoadingUpdateCurrentUserPassword',
        builder);

};

export default createReducer(currenciesInitialState, createCurrentUserBuilderCases);
