import { globalLocation, setSessionStorageObjectItem } from '@manigo/manigo-commons';
import { createReducer } from '@reduxjs/toolkit';

import { companyDetailsKey } from 'config/config';

import { CLEAR_CURRENT_USER, SET_CURRENT_USER_SUCCESS } from 'store/current-user/actions.types';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'store/standardEntityDetails.helpers';

import {
    CLEAR_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS_FAILURE,
    FETCH_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_FAILURE,
    UPDATE_COMPANY_SUCCESS,
} from './actions.types';


export const companyReducerName = 'company';
export const companyDetailsPerLocationStoreKeyName = 'companyDetailsPerLocation';

const initialState = {
    [companyDetailsPerLocationStoreKeyName]: { [globalLocation]: { queryParams: {} } },
    isLoadingCompanyDetails: false,
    isLoadingCompanyUpdate: false,
};

const createCompanyBuilderCases = (builder) => {
    builder.addCase(SET_CURRENT_USER_SUCCESS, (state, action) => {
        state[companyDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
            entitiesDetailsState: state[companyDetailsPerLocationStoreKeyName],
            action: {
                payload: {
                    responsePayload: action.payload?.company,
                    locationPathname: globalLocation,
                },
            },
        });
        state[companyDetailsPerLocationStoreKeyName][globalLocation].queryParams = { companyId: action.payload?.company?.id };
    })
        .addCase(FETCH_COMPANY_DETAILS, (state, action) => {
            state[companyDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[companyDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_COMPANY_DETAILS_SUCCESS, (state, action) => {
            state[companyDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[companyDetailsPerLocationStoreKeyName],
                action,
            });

            setSessionStorageObjectItem(companyDetailsKey, action.payload.responsePayload);
        })
        .addCase(FETCH_COMPANY_DETAILS_FAILURE, (state, action) => {
            state[companyDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[companyDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(UPDATE_COMPANY, (state) => {
            state.isLoadingCompanyUpdate = true;
        })
        .addCase(UPDATE_COMPANY_SUCCESS, (state, action) => {
            state.isLoadingCompanyUpdate = false;
            // XXX action.payload contains only companyEmail and companyPhoneNumber
            const updatedCompany = {
                ...state[companyDetailsPerLocationStoreKeyName][globalLocation].entityDetails,
                ...action.payload,
            };
            setSessionStorageObjectItem(companyDetailsKey, updatedCompany);
            state[companyDetailsPerLocationStoreKeyName][globalLocation].entityDetails = updatedCompany;
        })
        .addCase(UPDATE_COMPANY_FAILURE, (state) => {
            state.isLoadingCompanyUpdate = false;
        })
        .addCase(CLEAR_COMPANY_DETAILS, () => {
            return initialState;
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return initialState;
        });
};


export default createReducer(initialState, createCompanyBuilderCases);
