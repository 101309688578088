import { ShowToastPayload } from 'models/app/toast';
import { Action } from 'models/meta/action';


export const INIT_APPLICATION = 'INIT_APPLICATION';
export const APPLICATION_READY_UNAUTHORISED_USER = 'APPLICATION_READY_UNAUTHORISED_USER';
export const APPLICATION_READY_AUTHORISED_USER_ONBOARDING = 'APPLICATION_READY_AUTHORISED_USER_ONBOARDING';
export const APPLICATION_READY_AUTHORISED_USER = 'APPLICATION_READY_AUTHORISED_USER';
export const SET_SESSION_UUID = 'SET_SESSION_UUID';

export const GET_JWT_TOKEN_FORM_STORAGE = 'GET_JWT_TOKEN_FORM_STORAGE';
export const REMOVE_JWT_TOKEN_FROM_STORAGE = 'REMOVE_JWT_TOKEN_FROM_STORAGE';
export const SHOW_AUTO_LOGOUT_BANNER = 'SHOW_AUTO_LOGOUT_BANNER';
export const HIDE_AUTO_LOGOUT_BANNER = 'HIDE_AUTO_LOGOUT_BANNER';
export const SET_CLIPBOARD_CONTENT = 'SET_CLIPBOARD_CONTENT';
export const SET_QUERY_PARAMS = 'SET_QUERY_PARAMS';

export const SET_RAED_ONLY_MODE = 'SET_RAED_ONLY_MODE';
export const CLEAR_RAED_ONLY_MODE = 'CLEAR_RAED_ONLY_MODE';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SAVE_FILE = 'SAVE_FILE';


export interface ShowToastType extends Action {
    type: typeof SHOW_TOAST;
    payload: ShowToastPayload
}

export interface InitApplicationType extends Action {
    type: typeof INIT_APPLICATION;
}
export interface ApplicationReadyUnauthorisedUserType extends Action {
    type: typeof APPLICATION_READY_UNAUTHORISED_USER;
}
export interface SetSessionUuidType extends Action {
    type: typeof SET_SESSION_UUID;
    payload
}
export interface ApplicationReadyAuthorisedUserType extends Action {
    type: typeof APPLICATION_READY_AUTHORISED_USER;
}


export type ApplicationActions =
    InitApplicationType |
    ApplicationReadyUnauthorisedUserType |
    // SetSessionUuidType |
    ApplicationReadyAuthorisedUserType;
