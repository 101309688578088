import styled from 'styled-components';


const BrandAnimatedLoadingPlaceholder = styled.div`
  text-align: center;

  img {
    height: auto;
    max-height: 300px;
    min-width: 280px;
    width: 100%;
    max-width: 70%;

    margin: 0 auto;
  }
`;
export default { BrandAnimatedLoadingPlaceholder };
