import { EMPTY, of } from 'rxjs';

import { MultiStepFormStepChangeDirections, MultiStepFormCommonStepsDefinitions } from 'models/app/multiStepsFormModel';

import { setMultiStepsFormStep } from 'store/multi-steps-form/actions';


export function currentStepNumberInAvailableSteps(availableSteps: string[] = [], currentStep = '') {
    return availableSteps?.findIndex((value) => value === currentStep);
}
export function getLastStepExceptSuccessFromAvailableSteps(availableSteps: string[] = []) {
    return availableSteps?.filter((stepName) => stepName !== MultiStepFormCommonStepsDefinitions.SUCCESS)?.slice(-1)?.[0];
}


export const mapCurrentMultiStepStepToNumber = (
    currentMultiStepsFormStep,
    availableSteps,
) => {
    const stepNumber = currentStepNumberInAvailableSteps(availableSteps, currentMultiStepsFormStep);

    const handlers = [
        {
            predicate: () => stepNumber >= 0,
            handler: () => stepNumber,
        },
        {
            predicate: () => true,
            handler: () => -1,
        },
    ];

    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export const createOnRequestSetMultiStepsFormStepActions = ({
    currentStep,
    direction,
    availableSteps = [],
}) => {
    const stepNumber = currentStepNumberInAvailableSteps(availableSteps, currentStep);

    const handlers = [
        {
            predicate: () => stepNumber > 0 && direction === MultiStepFormStepChangeDirections.BACK,
            handler: () => of(setMultiStepsFormStep(availableSteps[stepNumber - 1])),
        },
        {
            predicate: () => direction === MultiStepFormStepChangeDirections.FORWARD,
            handler: () => of(setMultiStepsFormStep(availableSteps[stepNumber + 1])),
        },

        {
            predicate: () => true,
            handler: () => EMPTY,
        },
    ];
    return handlers.filter(({ predicate }) => predicate())[0].handler();
};


export default {
    mapCurrentMultiStepStepToNumber,
    createOnRequestSetMultiStepsFormStepActions,
};
