export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_SUCCESS = 'SET_CURRENT_USER_SUCCESS';
export const SET_CURRENT_USER_FAILURE = 'SET_CURRENT_USER_FAILURE';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const EXTEND_TOKEN_VALIDITY = 'EXTEND_TOKEN_VALIDITY';
export const EXTEND_TOKEN_VALIDITY_SUCCESS = 'EXTEND_TOKEN_VALIDITY_SUCCESS';
export const EXTEND_TOKEN_VALIDITY_FAILURE = 'EXTEND_TOKEN_VALIDITY_FAILURE';
export const USER_ACTIVITY = 'USER_ACTIVITY';
export const CHECK_TOKENS = 'CHECK_TOKENS';
export const EXPIRE_TOKEN = 'EXPIRE_TOKEN';

export const UPDATE_CURRENT_USER_PASSWORD = 'UPDATE_CURRENT_USER_PASSWORD';
export const UPDATE_CURRENT_USER_PASSWORD_SUCCESS = 'UPDATE_CURRENT_USER_PASSWORD_SUCCESS';
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE = 'UPDATE_CURRENT_USER_PASSWORD_FAILURE';
export const UPDATE_CURRENT_USER_PREFERENCES = 'UPDATE_CURRENT_USER_PREFERENCES';
export const UPDATE_CURRENT_USER_PREFERENCES_SUCCESS = 'UPDATE_CURRENT_USER_PREFERENCES_SUCCESS';
export const UPDATE_CURRENT_USER_PREFERENCES_FAILURE = 'UPDATE_CURRENT_USER_PREFERENCES_FAILURE';
