import { ApiError, ApiResponse, convertCamelCaseToSnakeCase, cleanNilValues, SortDirectionsAPI } from '@manigo/manigo-commons';
import { CardDetails, SubmitScaChallengeActionPayload } from '@manigo/manigo-domain-typings';

import { SortDirections } from 'models/app/applicationState';

import { addScaHeaders, withTotalCount } from 'services/http/http.helpers';


import {
    getCardIdDetails,
    updateCardIdDetails,
    unlinkCardId,
    linkCardId,
    getCardIdImage,
    getAllCardsApi,
    processOrderNewCardApiRequest,
    getNotLinkedCardsApi,
    deleteCardId,
    unblockCardPinByCardToken,
    updateCardIdLimits,
    getCardIdStatusHistory,
    resetContactlessCardLimitApi,
    activateCardApi,
    getCardIdSensitiveData,
} from './index';


class CardsService {
    public fetchAllCards(rawQuery) {
        const { sortOrder, search, cardStatus, sortBy, ...rest } = rawQuery;
        const query = {
            ...rest,
            cardStatus: cardStatus?.length > 0 ? cardStatus : undefined,
            ...(search && search?.length > 0 ? { search } : {}),
            sortBy: sortBy ? convertCamelCaseToSnakeCase(sortBy) : undefined,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };

        return getAllCardsApi({ params: { ...cleanNilValues(query) } });
    }

    public fetchCardDetails(cardId) {
        return getCardIdDetails(cardId);
    }

    public updateCardDetails({ cardId, ...requestPayload }) {
        return updateCardIdDetails(cardId, requestPayload);
    }

    public updateCardLimits({ cardId, ...requestPayload }) {
        return updateCardIdLimits(cardId, requestPayload);
    }

    public unlinkCard(cardId) {
        return unlinkCardId(cardId);
    }

    public linkCard(cardId, requestPayload) {
        return linkCardId(cardId, requestPayload);
    }

    public fetchCardProducts(cardId, requestPayload) {
        return linkCardId(cardId, requestPayload);
    }


    public getCardImage(cardId) {
        return getCardIdImage(cardId);
    }

    public unblockCardPin(cardToken) {
        const requestPayload = { token: cardToken };
        return unblockCardPinByCardToken(requestPayload);
    }

    public processOrderNewCard(requestPayload) {
        return processOrderNewCardApiRequest(requestPayload);
    }

    public getNotLinkedCards(rawQuery) {
        const { companyId, clientId, memberId, search, ...rest } = rawQuery;

        const query = {
            ...rest,
            ...withTotalCount,
            ...(companyId > 0 && { companyId }),
            ...(clientId > 0 && { clientId }),
            ...(memberId > 0 && { memberId }),
            ...(search && search.length > 0 && { search }),
        };

        return getNotLinkedCardsApi({ params: query });
    }

    public deleteCard(cardId) {
        return deleteCardId(cardId);
    }

    public getCardStatusHistory(rawQueryParams) {
        const { cardId, sortOrder, ...rest } = rawQueryParams;
        const queryParams = {
            ...rest,
            ...({ sortOrder: `${sortOrder === SortDirections.DESCEND ? SortDirectionsAPI.DESC : SortDirectionsAPI.ASC}` }),
        };
        return getCardIdStatusHistory(cardId, { params: { ...cleanNilValues(queryParams) } });
    }

    public resetContactlessCardLimit(cardId:CardDetails['id']) {
        return resetContactlessCardLimitApi(cardId);
    }

    public activateCard(rawQueryParams:{ cardId:CardDetails['id'], activationToken:CardDetails['token'] }) {
        return activateCardApi(rawQueryParams);
    }

    public getCardSensitiveData(requestPayload: SubmitScaChallengeActionPayload): Promise<ApiResponse | ApiError> {
        const { cardId } = requestPayload.sourceRequestData;
        const config = addScaHeaders(requestPayload, { noErrorHandling: true });
        return getCardIdSensitiveData(cardId, config);
    }
}

export default CardsService;

