import { TransactionDetails, TransactionListItem } from '@manigo/manigo-domain-typings';

import {
    optionalTransactionsScopeFilters,
    TransactionScopeFilter,
    TransactionFilter,
    transactionFilters,
} from 'models/domain/transactions';


export const createScopeFilters = (t, queryParams): TransactionScopeFilter[] => {
    const scopeFilters = transactionFilters.reduce((acc: TransactionFilter[] | any, filterOption: TransactionFilter) => {
        if (filterOption
            && optionalTransactionsScopeFilters.includes(filterOption.name)
            && !queryParams?.showCardVerification
        ) {
            return acc;
        }

        if (filterOption) {
            return [
                ...acc,
                {
                    label: t(`transactions:transactionScopes.${filterOption.transactionScope?.toLowerCase()}.text`) || filterOption.label,
                    value: filterOption.name,
                },
            ];
        }

        return acc;
    }, []);

    return scopeFilters || undefined;
};


export const mapTransactionScopeFilterValueToQuery = (selectedType): {
    transactionType?: TransactionFilter['transactionType'],
    transactionScope?: TransactionFilter['transactionScope']
} => {
    if (selectedType) {
        const selectedOption = transactionFilters.find((filterOption) => (filterOption.name === selectedType));

        if (selectedOption) {
            return {
                transactionType: selectedOption?.transactionType,
                transactionScope: selectedOption?.transactionScope,
            };
        }
    }

    return {};
};


export const isIncomingTransaction = (entityDetailsData: TransactionDetails | TransactionListItem): boolean => {
    const key = `${entityDetailsData.transactionType}_${entityDetailsData.transactionScope}`;
    const mapping = {
        EXT_IBT: true,
        EXT_CT: true,
        EXT_APM: true,
        INT_IP2P: true,
        INT_AC: true,
        INT_EXT: true,
        CT_CR: true,
        FEE_C: true,

        EXT_OBT: false,
        INT_OP2P: false,
        INT_AD: false,
        INT_EXF: false,
        CT_ATM: false,
        CT_POS: false,
        FEE_D: false,
        EXT_CTR: false,
    };

    if (key in mapping) {
        return mapping[key];
    }

    return false;

};


export default { mapTransactionScopeFilterValueToQuery };
