import { createReducer } from '@reduxjs/toolkit';

import { standardPageSize } from 'config/config';

import { addLoadingState } from 'utils/redux-tools';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';
import {
    updateStandardEntityDetails, updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from 'store/standardEntityDetails.helpers';


import {
    CLEAR_BENEFICIARY_DETAILS, DELETE_BENEFICIARY, DELETE_BENEFICIARY_FAILURE, DELETE_BENEFICIARY_SUCCESS,
    FETCH_BENEFICIARY_DETAILS,
    FETCH_BENEFICIARY_DETAILS_FAILURE,
    FETCH_BENEFICIARY_DETAILS_SUCCESS,
    UPDATE_BENEFICIARY, UPDATE_BENEFICIARY_FAILURE,
    UPDATE_BENEFICIARY_SUCCESS,
} from './actions.types';


export const beneficiaryReducerName = 'beneficiary';
export const beneficiaryDetailsPerLocationStoreKeyName = 'beneficiaryDetailsPerLocation';

export const defaultBeneficiaryListQueryParams = {
    limit: standardPageSize,
    offset: 0,
    clientId: null,
    memberId: null,
    companyId: null,
};

const initialBeneficiaryDetailsState = {
    [beneficiaryDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    //
};


const initialState = {
    // details
    ...initialBeneficiaryDetailsState,

    isLoadingDeleteBeneficiary: false,
    isLoadingUpdateBeneficiary: false,
};

const createBeneficiaryBuilderCases = (builder) => {
    builder
        .addCase(FETCH_BENEFICIARY_DETAILS, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_BENEFICIARY_DETAILS_SUCCESS, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_BENEFICIARY_DETAILS_FAILURE, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_BENEFICIARY_DETAILS, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === beneficiaryReducerName
                && action.payload?.fieldName === beneficiaryDetailsPerLocationStoreKeyName
            ) {
                state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[beneficiaryDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(UPDATE_BENEFICIARY, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
            state.isLoadingUpdateBeneficiary = true;
        })
        .addCase(UPDATE_BENEFICIARY_SUCCESS, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
            state.isLoadingUpdateBeneficiary = false;
        })
        .addCase(UPDATE_BENEFICIARY_FAILURE, (state, action) => {
            state[beneficiaryDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[beneficiaryDetailsPerLocationStoreKeyName],
                action,
            });
            state.isLoadingUpdateBeneficiary = false;
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);

    addLoadingState([DELETE_BENEFICIARY, DELETE_BENEFICIARY_SUCCESS, DELETE_BENEFICIARY_FAILURE], 'isLoadingDeleteBeneficiary', builder);
};

export default createReducer(initialState, createBeneficiaryBuilderCases);
