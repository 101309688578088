import { primaryColorSchemeKey } from 'config/config';


export const BASE_COLOR_NUMBER = '500';
export const BASE_COLOR_DARK_NUMBER = '200';
export const COLOR_PREFIX = 'chakra-colors-';

/**
 * @param endpoint - color endpoint, e.g. 500, 200, 100
 */
export const getChakraVarColorWithEndpoint = (endpoint: string, colorSchemeKey = primaryColorSchemeKey) =>
    `var(--${COLOR_PREFIX}${colorSchemeKey}-${endpoint})`;

export const getChakraColor = (color: string, colorSchemeKey = primaryColorSchemeKey) => `${colorSchemeKey}.${color}`;

export const getDefaultLightDarkValues = (colorSchemeKey = primaryColorSchemeKey): [string, string] =>
    [`${colorSchemeKey}.${BASE_COLOR_NUMBER}`, `${colorSchemeKey}.${BASE_COLOR_DARK_NUMBER}`];

/**
 * @param color - primary.500, primary.200, etc.
 */
export const getChakraVarColorByChakraHookReturn = (color: string) =>
    `var(--${COLOR_PREFIX}${color.split('.').length > 1 ? `${color.split('.')[0]}-${color.split('.')[1]}` : color})`;
