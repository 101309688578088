import { WithTranslation } from 'react-i18next';

import { TransferFormsFields, TransferPagesDispatchProps, TransferPagesStateProps } from '../Transfer.types';


export const exchangeFormName = 'exchange';

export enum ExchangePageFormSteps {
    TRANSFER_DETAILS = 'EXCHANGE_TRANSFER_DETAILS',
    REVIEW = 'EXCHANGE_REVIEW',
    SUCCESS = 'EXCHANGE_SUCCESS',
}

export type ExchangePageDataStepFields = TransferFormsFields;

export interface ExchangePageStateProps extends TransferPagesStateProps {
    isLoadingExchange: boolean;
    isLoadingInitExchangeInfo: boolean;
    isLoadingScaChallenge: boolean;
}

export interface ExchangePageDispatchProps extends TransferPagesDispatchProps {
    dispatchClearInitExchangeInfo: () => void;
}

export type ExchangePageProps = ExchangePageStateProps & ExchangePageDispatchProps & WithTranslation;
