import { EnhancedLocation, LocationChangeRequest, LocationPathname } from '@manigo/manigo-commons';
import { AccountDetails } from '@manigo/manigo-domain-typings';
import { WithTranslation } from 'react-i18next';

import { StandardListData } from 'models/app/standardLists';

import { TransferFormsFields } from '../Transfer.types';


export const internalTransferFormName = 'internal-transfer';

export enum InternalTransferFormSteps {
    TRANSFER_DATA = 'INTERNAL_TRANSFER_TRANSFER_DATA',
    REVIEW = 'INTERNAL_TRANSFER_REVIEW',
    SUCCESS = 'INTERNAL_TRANSFER_SUCCESS',
}
export interface InternalTransferDataStepFields extends TransferFormsFields {
    currency?: {
        label: string;
        value: AccountDetails['currency']
    } | null;
    reason?: string | null;
    reference: string | null;
}

export type InternalTransferAllFields = InternalTransferDataStepFields;

export interface InternalTransferPageStateProps {
    enhancedCurrentLocation: EnhancedLocation,
    newEntityLocation?: LocationPathname,
    isLoadingCreatePayment: boolean,
    isLoadingScaChallenge: boolean,
    context: any,
    listData: StandardListData<AccountDetails>
}
export interface InternalTransferPageDispatchProps {
    dispatchRequestNavigation: ({ locationPathname, meta }: LocationChangeRequest) => void,
    dispatchSetQueryParams: ({ queryParams, locationPathname }) => void,
    dispatchSetListData: ({ queryParams, locationPathname }) => void,
}
export type InternalTransferPageProps = InternalTransferPageStateProps & InternalTransferPageDispatchProps & WithTranslation;
