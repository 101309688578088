import { globalLocation } from '@manigo/manigo-commons';
import { createAction } from '@reduxjs/toolkit';

import {
    CLEAR_COMPANY_DETAILS, FETCH_COMPANY_DETAILS,
    FETCH_COMPANY_DETAILS_FAILURE,
    FETCH_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY,
    UPDATE_COMPANY_FAILURE,
    UPDATE_COMPANY_SUCCESS,
} from './actions.types';


export const clearCompanyDetails = createAction(CLEAR_COMPANY_DETAILS);


export const fetchCompanyDetails = createAction(FETCH_COMPANY_DETAILS, ({ queryParams, locationPathname = globalLocation }) => {
    return { payload: { queryParams, locationPathname } };
});
export const fetchCompanyDetailsSuccess = createAction(FETCH_COMPANY_DETAILS_SUCCESS, ({ responsePayload, locationPathname }) => {
    return { payload: { responsePayload, locationPathname } };
});
export const fetchCompanyDetailsFailure = createAction(FETCH_COMPANY_DETAILS_FAILURE, () => ({ payload: { locationPathname: globalLocation } }));


export const updateCompany = createAction(UPDATE_COMPANY, ({ companyId, formData, locationPathname }) => {
    return { payload: { companyId, formData, locationPathname } };
});
export const updateCompanySuccess = createAction(UPDATE_COMPANY_SUCCESS, (responsePayload) => {
    return { payload: responsePayload };
});
export const updateCompanyFailure = createAction(UPDATE_COMPANY_FAILURE);

