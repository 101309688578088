import { CardDetails, NumericId } from '@manigo/manigo-domain-typings';

import { HttpService } from 'services/http';

import { HttpRequestConfig } from '../http/httpService.types';


export const getAllCardsApi = (queryParams) => HttpService.get('/v3/cards', queryParams);

export const getCardIdDetails = (cardId: number) => HttpService.get(`/v3/cards/${cardId}`);

export const updateCardIdDetails = (cardId: number, requestPayload) => HttpService.put(`/v3/cards/${cardId}`, requestPayload);

export const updateCardIdLimits = (cardId, requestPayload) => HttpService.put(`/v2/cards/${cardId}/limits`, requestPayload);

export const processOrderNewCardApiRequest = (requestPayload) => HttpService.post('/v3/cards/order', requestPayload);

export const linkCardId = (cardId: number, requestPayload) => HttpService.post(`/v2/cards/${cardId}/link`, requestPayload);

export const unlinkCardId = (cardId: number) => HttpService.post(`/v2/cards/${cardId}/unlink`, {});

export const fetchCardProducts = (requestPayload) => HttpService.get('/v2/cards/products', requestPayload);


export const unblockCardPinByCardToken = (requestPayload) => HttpService.post('/v2/cards/unblock-pin', requestPayload);

export const getCardIdImage = (cardId: number) => HttpService.get(`/v2/cards/${cardId}/image`);

export const getNotLinkedCardsApi = (queryParams) => HttpService.get('/v3/cards/not-linked', queryParams);

export const deleteCardId = (cardId: number) => HttpService.delete(`/v2/cards/${cardId}`);

export const getCardIdStatusHistory = (cardId: number, queryParams) => HttpService.get(`/v2/cards/${cardId}/status-history`, queryParams);

export const resetContactlessCardLimitApi = (cardId: number) => HttpService.post(`/v2/cards/${cardId}/reset-contactless-limit`);

export const activateCardApi = (requestPayload:{ cardId:CardDetails['id'], activationToken:CardDetails['token'] }) => HttpService.post(
    '/v2/cards/activate',
    requestPayload,
    { noErrorHandling: true },
);


export const getCardIdSensitiveData = (cardId: NumericId, config: HttpRequestConfig) => HttpService.get(`/v3/cards/${cardId}/sensitive`, config);
