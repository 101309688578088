import { createAction } from '@reduxjs/toolkit';

import {
    FETCH_KYB_ACCESS_TOKEN, FETCH_KYB_ACCESS_TOKEN_SUCCESS, FETCH_KYB_ACCESS_TOKEN_FAILURE,
    FETCH_KYC_ACCESS_TOKEN, FETCH_KYC_ACCESS_TOKEN_FAILURE, FETCH_KYC_ACCESS_TOKEN_SUCCESS,
} from './actions.types';


export const fetchKybAccessToken = createAction(FETCH_KYB_ACCESS_TOKEN, (companyExtUuid) => ({ payload: companyExtUuid }));
export const fetchKybAccessTokenSuccess = createAction(
    FETCH_KYB_ACCESS_TOKEN_SUCCESS,
    (accessToken) => ({ payload: accessToken }),
);
export const fetchKybAccessTokenFailure = createAction(FETCH_KYB_ACCESS_TOKEN_FAILURE);


export const fetchKycAccessToken = createAction(FETCH_KYC_ACCESS_TOKEN, (externalUserId) => ({ payload: externalUserId }));
export const fetchKycAccessTokenSuccess = createAction(
    FETCH_KYC_ACCESS_TOKEN_SUCCESS,
    (accessToken) => ({ payload: accessToken }),
);
export const fetchKycAccessTokenFailure = createAction(FETCH_KYC_ACCESS_TOKEN_FAILURE);
