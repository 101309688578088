import { ofType } from 'redux-observable';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { MultiStepFormResetScrollElements, MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { Epic } from 'models/meta/epic';


import {
    REQUEST_SET_MULTI_STEPS_FORM_STEP,
    SET_MULTI_STEPS_FORM_STEP,
} from './actions.types';
import { createOnRequestSetMultiStepsFormStepActions } from './epics.helpers';
import { multiStepsFormReducerName } from './reducer';


export const onRequestSetMultiStepsFormStep: Epic = (action$, state$) => action$.pipe(
    ofType(REQUEST_SET_MULTI_STEPS_FORM_STEP),
    switchMap(({ payload: { currentStep, direction } }) => createOnRequestSetMultiStepsFormStepActions({
        currentStep,
        direction,
        availableSteps: state$.value[multiStepsFormReducerName]?.availableSteps,
    })),
);


export const onMultiStepsFormStepOrSubSepChange: Epic = (action$, _, { document }) => action$.pipe(
    ofType(SET_MULTI_STEPS_FORM_STEP),
    tap(({ payload }) => {
        const domElement = document.getElementsByClassName(MultiStepFormResetScrollElements.mainStepsClassName).item(0);
        if (payload.direction === MultiStepFormStepChangeDirections.FORWARD && domElement) {
            domElement.scrollTop = 0;
        }
    }),
    switchMap(() => EMPTY),
);

export default [
    onRequestSetMultiStepsFormStep,
    onMultiStepsFormStepOrSubSepChange,
];
