import { CardStatus } from '@manigo/manigo-domain-typings';
import { createReducer } from '@reduxjs/toolkit';

import { addLoadingState } from 'utils/redux-tools';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import {
    ACTIVATE_CARD,
    ACTIVATE_CARD_FAILURE,
    ACTIVATE_CARD_SUCCESS,
    CLEAR_CARD_DETAILS,
    DELETE_CARD,
    DELETE_CARD_FAILURE,
    DELETE_CARD_SUCCESS,
    FETCH_CARD_DETAILS,
    FETCH_CARD_DETAILS_FAILURE,
    FETCH_CARD_DETAILS_SUCCESS,
    RESET_CONTACTLESS_CARD_LIMIT,
    RESET_CONTACTLESS_CARD_LIMIT_FAILURE,
    RESET_CONTACTLESS_CARD_LIMIT_SUCCESS,
    UNBLOCK_CARD_PIN,
    UNBLOCK_CARD_PIN_FAILURE,
    UNBLOCK_CARD_PIN_SUCCESS,
    UPDATE_CARD_LIMITS,
    UPDATE_CARD_LIMITS_FAILURE,
    UPDATE_CARD_LIMITS_SUCCESS,
    UPDATE_CARD_SETTINGS,
    UPDATE_CARD_SETTINGS_FAILURE,
    UPDATE_CARD_SETTINGS_SUCCESS,
    UPDATE_CARD_STATUS,
    UPDATE_CARD_STATUS_FAILURE,
    UPDATE_CARD_STATUS_SUCCESS,
} from './actions.types';
import {
    updateStandardEntityDetails,
    updateStandardEntityDetailsDataQueryParams,
    updateStandardEntityDetailsOnCleanup,
    updateStandardEntityDetailsOnFailure,
    updateStandardEntityDetailsOnSuccess,
} from '../standardEntityDetails.helpers';


export const cardReducerName = 'card';
export const cardDetailsPerLocationStoreKeyName = 'cardDetailsPerLocation';


const initialState = {
    [cardDetailsPerLocationStoreKeyName]: {}, // XXX 'pathname': {...standard }
    isLoadingDeleteCard: false,
    isLoadingUpdateCard: false,

    isLoadingUnblockCardPin: false,
    isLoadingSuspendCard: false,
    isLoadingUnblockCard: false,
    isLoadingReportCardAsLostOrStolen: false,
    isLoadingReportCardAsNotDelivered: false,
    isLoadingResetContactlessCardLimit: false,
    isLoadingActivateCard: false,

    isLoadingUpdateCardSettings: false,
    isLoadingUpdateCardLimits: false,
};
const cardUpdateStatusHandlers = (cardStatus): { predicate: () => boolean, handler: () => string, }[] => [{
    predicate: () => cardStatus === CardStatus.ACTIVE,
    handler: () => 'isLoadingUnblockCard',
}, {
    predicate: () => cardStatus === CardStatus.SUSPENDED,
    handler: () => 'isLoadingSuspendCard',
}, {
    predicate: () => cardStatus === CardStatus.LOST || cardStatus === CardStatus.STOLEN,
    handler: () => 'isLoadingReportCardAsLostOrStolen',
}, {
    predicate: () => cardStatus === CardStatus.NOT_DELIVERED,
    handler: () => 'isLoadingReportCardAsNotDelivered',
},
];

const createCardBuilderCases = (builder) => {
    builder
        .addCase(FETCH_CARD_DETAILS, (state, action) => {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetails({
                entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CARD_DETAILS_SUCCESS, (state, action) => {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnSuccess({
                entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CARD_DETAILS_FAILURE, (state, action) => {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnFailure({
                entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(CLEAR_CARD_DETAILS, (state, action) => {
            state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsOnCleanup({
                entitiesDetailsState: state[cardDetailsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === cardReducerName
                && action.payload?.fieldName === cardDetailsPerLocationStoreKeyName
            ) {
                state[cardDetailsPerLocationStoreKeyName] = updateStandardEntityDetailsDataQueryParams({
                    entitiesDetailsState: state?.[cardDetailsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(UPDATE_CARD_STATUS, (state, { payload: { queryParams: { cardStatus } } }) => {
            const handler = cardUpdateStatusHandlers(cardStatus).find(({ predicate }) => predicate());
            if (handler) {
                state[handler.handler()] = true;
            }
        })
        .addCase(UPDATE_CARD_STATUS_SUCCESS, (state, { payload: { cardStatus } }) => {
            const handler = cardUpdateStatusHandlers(cardStatus).find(({ predicate }) => predicate());
            if (handler) {
                state[handler.handler()] = false;
            }
        })
        .addCase(UPDATE_CARD_STATUS_FAILURE, (state, { payload: { cardStatus } }) => {
            const handler = cardUpdateStatusHandlers(cardStatus).find(({ predicate }) => predicate());
            if (handler) {
                state[handler.handler()] = false;
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => initialState);

    addLoadingState([DELETE_CARD, DELETE_CARD_SUCCESS, DELETE_CARD_FAILURE], 'isLoadingDeleteCard', builder);
    addLoadingState([UNBLOCK_CARD_PIN, UNBLOCK_CARD_PIN_SUCCESS, UNBLOCK_CARD_PIN_FAILURE], 'isLoadingUnblockCardPin', builder);
    addLoadingState([RESET_CONTACTLESS_CARD_LIMIT, RESET_CONTACTLESS_CARD_LIMIT_SUCCESS, RESET_CONTACTLESS_CARD_LIMIT_FAILURE], 'isLoadingResetContactlessCardLimit', builder);
    addLoadingState([ACTIVATE_CARD, ACTIVATE_CARD_SUCCESS, ACTIVATE_CARD_FAILURE], 'isLoadingActivateCard', builder);
    addLoadingState([UPDATE_CARD_SETTINGS, UPDATE_CARD_SETTINGS_SUCCESS, UPDATE_CARD_SETTINGS_FAILURE], 'isLoadingUpdateCardSettings', builder);
    addLoadingState([UPDATE_CARD_LIMITS, UPDATE_CARD_LIMITS_SUCCESS, UPDATE_CARD_LIMITS_FAILURE], 'isLoadingUpdateCardLimits', builder);
};

export default createReducer(initialState, createCardBuilderCases);
