import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { MultiStepFormStepChangeDirections } from 'models/app/multiStepsFormModel';
import { ToastType } from 'models/app/toast';
import { Epic } from 'models/meta/epic';

import { createTransactionDetailsPath } from 'config/routes.helpers';

import { showToast } from 'store/application/actions';
import { updateList } from 'store/list/actions';
import { requestSetMultiStepsFormStep, setNewEntityLocation } from 'store/multi-steps-form/actions';
import { clearScaChallenge } from 'store/sca-challange/actions';
import { transactionsReducerName } from 'store/transactions/reducer';

import { AddBankTransferFormSteps } from 'components/pages/authorised/payments/AddBankTransferPage/AddBankTransferPage.types';
import { InternalTransferFormSteps } from 'components/pages/authorised/payments/InternalTransferPage/InternalTransferPage.types';

import { CREATE_PAYMENT, FETCH_TRANSACTION_DETAILS } from './action.types';
import {
    createPaymentSuccess,
    createPaymentFailure,
    fetchTransactionDetailsFailure,
    fetchTransactionDetailsSuccess,
} from './actions';
import { createCreatePaymentErrorMessage } from './epics.helpers';


export const onFetchTransactionDetails: Epic = (action$, state$, { transactions }) => action$.pipe(
    ofType(FETCH_TRANSACTION_DETAILS),
    switchMap(({ payload }) => from(transactions.getTransactionDetails(payload.queryParams.transactionUuid))
        .pipe(
            switchMap((response) => of(fetchTransactionDetailsSuccess({
                responsePayload: response.data,
                locationPathname: payload.locationPathname,
            }))),
            catchError(() => of(fetchTransactionDetailsFailure(payload.locationPathname))),
        )),
);
export const onCreateP2PTransaction: Epic = (action$, state$, {
    transactions,
    i18n,
}) => action$.pipe(
    ofType(CREATE_PAYMENT),
    switchMap(({ payload }) => from(transactions.createNewPayment(payload))
        .pipe(
            switchMap((response) => {
                const isBankTransfer = state$.value?.multiStepsForm?.currentStep === AddBankTransferFormSteps.REVIEW;
                const currentStep = isBankTransfer ? AddBankTransferFormSteps.REVIEW : InternalTransferFormSteps.REVIEW;

                return of(
                    clearScaChallenge(),
                    createPaymentSuccess(response.data),
                    setNewEntityLocation(createTransactionDetailsPath(response?.data?.transactionUuid)),
                    requestSetMultiStepsFormStep(currentStep, MultiStepFormStepChangeDirections.FORWARD),
                    updateList({
                        findByPropName: 'transactionUuid',
                        findByPropValue: response?.data?.transactionUuid,
                        listReducerName: transactionsReducerName,
                    }),
                );
            }),
            catchError((error) => of(
                showToast({
                    type: ToastType.error,
                    message: createCreatePaymentErrorMessage(i18n.t, error),
                }),
                clearScaChallenge(),
                createPaymentFailure(),
            )),
        )),
);


export default [
    onFetchTransactionDetails,
    onCreateP2PTransaction,
];
