import { AesEncryptedText } from '@manigo/manigo-domain-typings';
import CryptoJS from 'crypto-js';

import { PERSONAL_APP_AES_KEY } from 'config/environment';


export const decrypt = (encryptedText: AesEncryptedText): string => {
    // convert to bytes
    const keyByteArray = CryptoJS.enc.Utf8.parse(PERSONAL_APP_AES_KEY);
    const decryptedByteArray = CryptoJS.AES.decrypt(encryptedText, keyByteArray, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.NoPadding });
    // XXX nasty '\x00' case
    // decoded strings contain control characters that are incorrectly rendered by Chrome on Windows (as rectangles)
    // eslint-disable-next-line no-control-regex
    return decryptedByteArray.toString(CryptoJS.enc.Utf8).replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
};
