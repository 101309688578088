import { createReducer } from '@reduxjs/toolkit';

import { SortDirections } from 'models/app/applicationState';

import { withSkipImages } from 'services/http/http.helpers';

import { SET_QUERY_PARAMS } from 'store/application/action.types';
import { CLEAR_CURRENT_USER } from 'store/current-user/actions.types';

import { FETCH_CURRENCIES, FETCH_CURRENCIES_FAILURE, FETCH_CURRENCIES_SUCCESS } from './actions.types';
import { updateStandardListDataQueryParams, updateStandardLists, updateStandardListsOnFailure, updateStandardListsOnSuccess } from '../standardLists.helpers';


export const currenciesReducerName = 'currencies';
export const currenciesListsPerLocationStoreKeyName = 'currenciesListsPerLocation';
export const defaultCurrenciesListQueryParams = {
    limit: 20,
    offset: 0,
    search: '',
    sortBy: 'id',
    sortOrder: SortDirections.ASCEND,
    ...withSkipImages,
};

export const currenciesInitialListState = { [currenciesListsPerLocationStoreKeyName]: {} };

const createCurrenciesBuilderCases = (builder) => {
    builder
        .addCase(FETCH_CURRENCIES, (state, action) => {
            state[currenciesListsPerLocationStoreKeyName] = updateStandardLists({
                listsState: state[currenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CURRENCIES_SUCCESS, (state, action) => {
            state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnSuccess({
                listsState: state[currenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(FETCH_CURRENCIES_FAILURE, (state, action) => {
            state[currenciesListsPerLocationStoreKeyName] = updateStandardListsOnFailure({
                listsState: state[currenciesListsPerLocationStoreKeyName],
                action,
            });
        })
        .addCase(SET_QUERY_PARAMS, (state, action) => {
            if (action.payload?.reducerName === currenciesReducerName
                        && action.payload?.fieldName === currenciesListsPerLocationStoreKeyName
            ) {
                state[currenciesListsPerLocationStoreKeyName] = updateStandardListDataQueryParams({
                    listsState: state?.[currenciesListsPerLocationStoreKeyName],
                    action,
                });
            }
        })
        .addCase(CLEAR_CURRENT_USER, () => {
            return currenciesInitialListState;
        });
};

export default createReducer(currenciesInitialListState, createCurrenciesBuilderCases);
